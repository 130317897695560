let servicesUSMUrl = process.env.REACT_APP_USM_URL;
export let servicesUrl = process.env.REACT_APP_BASE_URL;

const AUTH = {
	FETCH_REFRESH_TOKEN: `${servicesUSMUrl}/account/auth/token/refresh/`,
	FETCH_VERIFY_TOKEN: `${servicesUSMUrl}/account/auth/token/verify/`,
	USER_USM_DETAILS: `${servicesUSMUrl}/api/v1/user-management/users`,
};

const EVENTS = {
	FETCH_LIST_OF_EVENTS: `${servicesUrl}/api/v1/events`,
	LOCATION_AUTO_COMPLETE_PLACE: `${servicesUrl}/api/v1/events/place-search`,
	LOCATION_AUTO_COMPLETE_HOTEL: `${servicesUrl}/api/v1/events/hotel-search`,
	CREATE_EVENT: `${servicesUrl}/api/v1/events`,
	FETCH_ACTIVE_EVENT_BASED_ON_NAME_AND_LOCATION: `${servicesUrl}/api/v1/events`,
	FETCH_PAST_EVENT_BASED_ON_NAME_AND_LOCATION: `${servicesUrl}/api/v1/events`,
	TIMEZONE_BASED_ON_LOCATION: `${servicesUrl}/api/v1/events/place-search`,
	UPDATE_RSVP_STATUS: `${servicesUrl}/api/v1/events`,
	GET_EVENT_DETAILS: `${servicesUrl}/api/v1/events`,
	DELETE_EVENT: `${servicesUrl}/api/v1/events`,
	UPDATE_A_FIELD_FOR_AN_EVENT: `${servicesUrl}/api/v1/events`,
	CREATE_PUBLISH_UPDATE_FOR_AN_EVENT: `${servicesUrl}/api/v1/events`,
	DOWNLOAD_REPORTS: `${servicesUrl}/api/v1/events`,
	PIN_DELETE_EVENT_UPDATE: `${servicesUrl}/api/v1/events`,
	MESSAGE_ALL: `${servicesUrl}/api/v1/events`,
	EVENT_ROLE: `${servicesUrl}/api/v1/events`,
	EVENTS_PERMISSION: `${servicesUrl}/api/v1/events-enabled`,

	// participants
	CURRENT_PARTICIPANTS_STATUS_FOR_EVENT: `${servicesUrl}/api/v1/events`,
	REMOVE_PARTICIPANT_FROM_EVENT: `${servicesUrl}/api/v1/events`,
	RSVP_COUNT_FOR_EVENT: `${servicesUrl}/api/v1/events`,
	// bulk
	GENERATE_BULK_INVITE_TEMPLATE: `${servicesUrl}/api/v1/event`,
	GET_SIGNED_URL_FOR_UPLOAD: `${servicesUrl}/api/v1/event`,
	FETCH_TEMPLATE_USERS_FOR_INVITE: `${servicesUrl}/api/v1/event`,

	// INVITE
	LIST_OF_EMPLOYEES_FOR_CLIENT: `${servicesUrl}/api/v1/events`,
	INVITE_FILTER: `${servicesUSMUrl}/api/v1/user-management`,
	INVITE_EMPLOYEES: `${servicesUrl}/api/v1/events`,

	ADD_REMOVE_GET_ORGANIZERS: `${servicesUrl}/api/v1/events`,

	// upload
	UPLOAD_USER_TEMPLATE: `${servicesUrl}/api/v1/events`,

	// trips
	GET_ACTIVE_USER_TRIPS: `${servicesUrl}/api/v1/events`,
	ADD_LINKED_TRIPS: `${servicesUrl}/api/v1/events`,
	FETCH_LINKED_TRIPS: `${servicesUrl}/api/v1/events`,
	REMOVE_LINKED_TRIPS: `${servicesUrl}/api/v1/events`,
};

export const apiUrls = {
	AUTH,
	EVENTS,
};
