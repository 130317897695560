import * as React from 'react';

export const Sparkles = React.memo(
	({ size = 16, color = '#1B7E49', className, ...rest }) => (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.77033 2.53616L3.50012 5.5L0.536286 6.77021C0.334259 6.85679 0.334259 7.1432 0.536286 7.22978L3.50012 8.5L4.77033 11.4638C4.85691 11.6659 5.14332 11.6659 5.2299 11.4638L6.50012 8.5L9.46395 7.22978C9.66598 7.1432 9.66598 6.85679 9.46395 6.77021L6.50012 5.5L5.2299 2.53616C5.14332 2.33414 4.85691 2.33414 4.77033 2.53616Z"
				fill="#1B7E49"
			/>
			<path
				d="M10.7501 1.75L9.53629 2.27021C9.33426 2.35679 9.33426 2.6432 9.53629 2.72978L10.7501 3.25L11.2703 4.46383C11.3569 4.66585 11.6433 4.66585 11.7299 4.46383L12.2501 3.25L13.4639 2.72978C13.666 2.6432 13.666 2.35679 13.4639 2.27021L12.2501 1.75L11.7299 0.536164C11.6433 0.334137 11.3569 0.334137 11.2703 0.536164L10.7501 1.75Z"
				fill="#1B7E49"
			/>
			<path
				d="M11.2703 9.53616L10.7501 10.75L9.53629 11.2702C9.33426 11.3568 9.33426 11.6432 9.53629 11.7298L10.7501 12.25L11.2703 13.4638C11.3569 13.6659 11.6433 13.6659 11.7299 13.4638L12.2501 12.25L13.4639 11.7298C13.666 11.6432 13.666 11.3568 13.4639 11.2702L12.2501 10.75L11.7299 9.53616C11.6433 9.33414 11.3569 9.33414 11.2703 9.53616Z"
				fill="#1B7E49"
			/>
		</svg>
	)
);

Sparkles.displayName = 'Sparkle';
