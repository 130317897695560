import * as React from 'react';

export const DeleteBin = React.memo(({ size = 24, color = '#6B7280', className, ...rest }) => {
    return (

        <svg width={size}
            height={size}
            viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.00016 10.6667C1.00016 11.4 1.60016 12 2.3335 12H7.66683C8.40016 12 9.00016 11.4 9.00016 10.6667V4C9.00016 3.26667 8.40016 2.66667 7.66683 2.66667H2.3335C1.60016 2.66667 1.00016 3.26667 1.00016 4V10.6667ZM9.00016 0.666667H7.3335L6.86016 0.193333C6.74016 0.0733333 6.56683 0 6.3935 0H3.60683C3.4335 0 3.26016 0.0733333 3.14016 0.193333L2.66683 0.666667H1.00016C0.633496 0.666667 0.333496 0.966667 0.333496 1.33333C0.333496 1.7 0.633496 2 1.00016 2H9.00016C9.36683 2 9.66683 1.7 9.66683 1.33333C9.66683 0.966667 9.36683 0.666667 9.00016 0.666667Z" fill={color} />
        </svg>


    );
})

DeleteBin.displayName = "DeleteBin";