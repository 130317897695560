import { PlusOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { Button, Checkbox, Input } from 'components/atoms';
import { Icon } from 'components/atoms/Icon';
import { Chevron } from 'icons/Chevron';
import { useCallback, useEffect, useRef, useState } from 'react';
import { titleCase } from 'views/TeamEvents/pages/Event/utils';

export const Filter = ({ filterData, dataObject, onApply, onCancel }) => {

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	const [companyData, setCompanyData] = useState(dataObject);
	const [searchableFilterName, setSearchableFilterName] = useState('');

	const [searchableResults, setSearchableResults] = useState([]);
	const [userInputSearchResults, setUserInputSearchResults] = useState([]);

	const [checkboxesState, setCheckboxesState] = useState([]);
	const [showCTA, setShowCTA] = useState(false);
	const [keyNames, setKeyNames] = useState([]);

	const [filteredObject, setFilteredObject] = useState(null);

	const [searchableFilterOpen, setSearchableFilterOpen] = useState(false);

	useEffect(() => {
		if (companyData) {
			Object.keys(companyData).forEach((key) => {
				if (key === searchableFilterName) {
					setSearchableResults(companyData[key]);
					setUserInputSearchResults(companyData[key]);
					setSearchableFilterOpen(true);
				}
			});
		}
	}, [searchableFilterName, companyData]);

	const callFunc = useCallback(() => {
		if (companyData) {
			const keyNames = Object.keys(companyData).filter((key) =>
				Array.isArray(companyData[key])
			);
			setKeyNames(keyNames);
			let obj = {};
			keyNames.forEach((key) => (obj[key] = []));

			setFilteredObject(obj);
		}
	}, [companyData]);

	useEffect(() => {
		callFunc();
	}, [companyData]);

	const handleCheckboxChange = (e, item) => {
		const checkboxes = [...checkboxesState];
		const filteredObjectState = Object.assign({}, filteredObject);

		if (e.target.checked) {
			const o = { ...item, cn: searchableFilterName };
			checkboxes.push(o);

			filteredObjectState[searchableFilterName].push(item.id);
		} else {
			const index = checkboxesState.findIndex(
				(ch) =>
					ch.id === item.id &&
					ch.name === item.name &&
					searchableFilterName === ch.cn
			);
			checkboxes.splice(index, 1);
			const ind = filteredObjectState[searchableFilterName].findIndex(
				(ch) => ch.id === item.id
			);
			filteredObjectState[searchableFilterName].splice(ind, 1);
		}
		setCheckboxesState(checkboxes);
		setFilteredObject(filteredObjectState);

		// TODO:
		// onApply({
		// 	company: companyData,
		// 	filteredObject: filteredObject,
		// });
		if (checkboxes.length > 0) {
			setShowCTA(true);
		} else {
			setShowCTA(false);
		}
	};

	const handleChange = (e) => {
		const userInput = e.target.value;
		const filteredSugestions = searchableResults.filter(
			(result) =>
				result.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
		);
		setUserInputSearchResults(filteredSugestions);
	};

	const resetData = () => {
		setSearchableFilterOpen(false);
		setCheckboxesState([]);
		if (!dataObject) {
			setCompanyData(null);
		}
		callFunc();
		setShowCTA(false);
		setSearchableFilterName('');
	};

	const handleApply = () => {

		onApply({
			company: companyData,
			filteredObject: filteredObject,
		});
		resetData();
		setIsComponentVisible(false)
	};

	const renderContent = () => {
		if (searchableFilterOpen) {
			return (
				<div className="tw-flex tw-flex-col tw-justify-around tw-gap-2">
					<div className="tw-flex tw-items-center">
						<Chevron
							color="#9ca3af"
							className="tw-mr-2 tw-cursor-pointer"
							onClick={() => {
								setSearchableFilterName('');
								setSearchableFilterOpen(false);
							}}
						/>
						{titleCase(searchableFilterName) === "Linked Trips" ? "Invitees who have" : titleCase(searchableFilterName) + " is"}
					</div>
					<Input size="small" onChange={handleChange} />
					<div className="tw-max-h-80 tw-overflow-y-auto">
						{userInputSearchResults.map((item, index) => {
							return (
								<div
									key={index}
								// className="tw-text-[#27272A] hover:tw-bg-[#fff7ed] hover:tw-cursor-pointer tw-h-8 tw-border-[4px]"
								>
									<Checkbox
										className="tw-pt-1 tw-pl-2 tw-text-[#27272A] hover:tw-bg-[#fff7ed] hover:tw-cursor-pointer tw-h-8 tw-border-[4px] tw-w-full"
										onChange={(e) =>
											handleCheckboxChange(e, item)
										}
										checked={
											!!checkboxesState.find(
												(ch) =>
													ch.id === item.id &&
													ch.name === item.name &&
													ch.cn ===
													searchableFilterName
											)
										}
									>
										{titleCase(item.name)}
									</Checkbox>
								</div>
							);
						})}
					</div>
					{showCTA && (
						<div className="tw-flex tw-gap-1">
							<Button
								type="default"
								className="tw-w-1/2"
								onClick={() => {
									resetData();
									onCancel();
									setIsComponentVisible(false)
								}}
							>
								Cancel
							</Button>
							<Button className="tw-w-1/2" onClick={handleApply}>
								Apply
							</Button>
						</div>
					)}
				</div>
			);
		} else {
			if (!companyData) {
				return <>
					<p className='tw-m-0 tw-ml-1 tw-mb-2 tw-text-gray-500 tw-text-font-size-30 tw-select-none'>Choose your company</p>
					{
						filterData?.map((company, index) => {
							return (
								<div
									className="tw-text-[#27272A] hover:tw-bg-[#fff7ed] hover:tw-cursor-pointer tw-h-8 tw-border-[4px] tw-border-solid tw-border-white hover:tw-border-[#fff7ed]"
									onClick={() => {
										setCompanyData(company);
									}}
									index={index}
								>
									{titleCase(company.name)}
								</div>
							);
						})
					}
				</>
			} else {
				return (
					<>
						{keyNames.map((name, index) => (
							<div
								className="tw-text-[#27272A] hover:tw-bg-[#fff7ed] hover:tw-cursor-pointer tw-h-8 tw-border-[4px] tw-border-solid tw-border-white hover:tw-border-[#fff7ed]"
								onClick={() => {
									setSearchableFilterName(name);
								}}
								index={index}
							>
								{titleCase(name)}
							</div>
						))}
					</>
				);
			}
		}
	};
	return (
		<div ref={ref}>
			<Popover content={renderContent} trigger="click" placement="bottomLeft" arrow={false}
				open={isComponentVisible}
				getPopupContainer={trigger => trigger.parentNode}>
				<Button
					type="default"
					onClick={() => {
						setSearchableFilterName('');
						setCheckboxesState([]);
						setSearchableFilterOpen(false);
						setShowCTA(false);
						if (!dataObject) {
							setCompanyData(null);
						}
						setIsComponentVisible(true)
					}}
				>
					<PlusOutlined />
					Add filter
				</Button>
			</Popover>
		</div>
	);
};

function useComponentVisible(initialIsVisible) {
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
	const ref = useRef(null);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setIsComponentVisible(false);
		} else {
			setIsComponentVisible(true);
			
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return { ref, isComponentVisible, setIsComponentVisible };
}