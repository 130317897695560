import * as React from 'react';

export const Notification = React.memo(({ size = 40, color = '#6b7280', className, ...rest }) => {
    return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 40 40"
			{...rest}
		>
			<path
				fill={color}
				d="M19.976 10.247a7.49 7.49 0 017.495 7.25l.005.25v4.097L28.855 25a1.25 1.25 0 01-1.146 1.751l-4.735.002a3 3 0 01-5.995.176l-.005-.179-4.725.001a1.25 1.25 0 01-1.146-1.748l1.372-3.158v-4.098c0-4.155 3.351-7.5 7.5-7.5zm1.499 16.503l-3 .003a1.5 1.5 0 002.994.144l.006-.147zm-1.5-15.003c-3.32 0-6 2.675-6 6v4.41l-1.343 3.094h14.696l-1.352-3.093V17.76l-.004-.225a5.988 5.988 0 00-5.997-5.788z"
			></path>
		</svg>
	);
})

Notification.displayName = "Notification";