import { UserOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationAutoComplete, getLocationAutoCompleteHotel } from 'redux/features/Events/Events.slice';
const renderTitle = (title) => (
	<span>
		{title}
		{/* <a
			style={{
				float: 'right',
			}}
			href="https://www.google.com/search?q=antd"
			target="_blank"
			rel="noopener noreferrer"
		>
      more
		</a> */}
	</span>
);
const renderItem = (title, count, details=0) => ({
	value: title,
	label: (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			{title}
			{/* <span>
				<UserOutlined /> {count}
			</span> */}
		</div>
	),
	details: details
});



const LocationSearch = (props) => {
	
	const {
		selectedOption,
		setSelectedOption,
		value,
		eventDetailsForedit,
		setEventDetailsforEdit,
		locationName,
		setLocationName
		} = props;
	const eventReducer = useSelector(state => state.events)

	const { locationAutoComplete, locationAutoCompleteHotel, isLoading } = eventReducer;
	const [locationOptions, setLocationOptions] = useState([])
	useEffect(()=>{
		setLocationName(eventDetailsForedit ? eventDetailsForedit?.location?.value : "")
		setLocationOptions([])
	},[])
	

	const option2 = [
		{
			label: renderTitle('places'),
			options: [renderItem('AntDesign', 10000), renderItem('AntDesign UI', 10600)],
		},
		{
			label: renderTitle('hotels'),
			options: [renderItem('AntDesign UI FAQ', 60100), renderItem('AntDesign FAQ', 30010)],
		},
		{
			label: renderTitle('others'),
			options: [renderItem('AntDesign design language', 100000)],
		},
	];

	
	useEffect(() => {
		let _options = [];
		if (locationAutoComplete && !isLoading.locationAutoComplete) {

			if (locationAutoComplete["places"]) {
				let _local = locationAutoComplete["places"].map((_data) => {

					return renderItem(_data.value, "10",_data);
				});
				let obj = {
					label: renderTitle("Places"),
					options: _local
				};
				
				_options.push(obj);
			}
			setLocationOptions(_options)
		}else{
			let _local =  [renderItem("Loading...", "10")]
			let obj = {
				label: renderTitle("Places"),
				options: _local
			};
			_options.push(obj);
		}
		if(locationAutoCompleteHotel && !isLoading.locationAutoCompleteHotel){
			if(locationAutoCompleteHotel?.hotels){
				let _local = locationAutoCompleteHotel?.hotels.map((_data)=>{
					return renderItem(_data.value, "10", _data);
				})
				let obj ={
					label: renderTitle("Hotels"),
					options: _local
				}
				_options.push(obj);
				
			}
			setLocationOptions(_options);
		}else{
			let _local =  [renderItem("Loading...", "10")]
			let obj = {
				label: renderTitle("Hotels"),
				options: _local
			};
			_options.push(obj);
		}
		
	}, [locationAutoComplete, locationAutoCompleteHotel, isLoading.locationAutoComplete, isLoading.locationAutoCompleteHotel]);

	

	const optionsGenerator = () => {
		return [];
	}


	const dispatch = useDispatch()
	const debounce = (func) => {
		let timer;
		return function (...args) {
			const context = this;
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				func.apply(context, args);
			}, 500);
		};
	};

	const searchLocation = (value) => {
		dispatch(getLocationAutoComplete(value));
		dispatch(getLocationAutoCompleteHotel(value));
	};
	const optimizedFn = useCallback(debounce(searchLocation), []);

	const handleLocationInput = (e) => {
		// console.log(e);
		// setEventDetailsforEdit({
		// 	...eventDetailsForedit,
		// 	location: e
		// })
	}
	const handleLocationClick = (name, data) => {
		// console.log(name, data);
		if(data.details){
			if(Object.keys(data).length > 0){
				setSelectedOption(data.details);
			}
		}
		setLocationName(name)
	}
	return (
		<AutoComplete
			popupClassName="certain-category-search-dropdown"
			dropdownMatchSelectWidth={500}
			options={locationOptions}
			onSearch={(e) => {
				if (props.type === "edit") {
					handleLocationInput(e);
				}
				if(e.length > 0)
				{
					optimizedFn(e);
				}
				setLocationOptions([])
			}}
			onChange={handleLocationClick}
			className="tw-w-full"
			value={locationName}
			onBlur={()=>selectedOption?.value ?  setLocationName(selectedOption?.value): setLocationName("")}
			onFocus={()=>setLocationName(selectedOption?.value)}
		>
			<Input.Search size="default" placeholder="Search city, hotel or point of interest" className='tw-w-full' />
		</AutoComplete>
	)
};
export default LocationSearch;