import * as React from 'react';

export const Lightning = React.memo(
	({ size = 16, color = '#EC5D25', className, ...rest }) => (
		<svg
			width="10"
			height="16"
			viewBox="0 0 10 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.92717 0.121376L0.227174 9.62138C0.127196 9.78801 0.247224 10 0.441547 10H4.5L3.54852 15.7089C3.52312 15.8613 3.64063 16 3.79511 16H3.85845C3.94627 16 4.02764 15.9539 4.07283 15.8786L9.77283 6.37862C9.8728 6.21199 9.75278 6 9.55845 6H5.5L6.45148 0.2911C6.47688 0.138717 6.35937 0 6.20489 0H6.14155C6.05373 0 5.97236 0.0460746 5.92717 0.121376Z"
				fill={color}
			/>
		</svg>
	)
);

Lightning.displayName = 'Lightning';
