import * as React from 'react';

export const UploadIcon = React.memo(({ size = 24, color = '#6B7280', className, ...rest }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 18H6C3.23858 18 1 15.7614 1 13V12.3308C1 9.93899 2.93899 8 5.33085 8V8C5.74095 8 6.11585 7.7683 6.29925 7.40149L6.4 7.2C7.38059 5.23883 9.38505 4 11.5777 4H12C15.3137 4 18 6.68629 18 10V10H19C21.2091 10 23 11.7909 23 14V14C23 16.2091 21.2091 18 19 18H16" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 13L12 11M12 11L14 13M12 11V20" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
})

UploadIcon.displayName = "UploadIcon";