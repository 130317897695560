import * as React from 'react';

export const Chevron = React.memo(
	({ size = 24, color = 'black', className, ...rest }) => {
		return (
			<svg
				width="7"
				height="12"
				viewBox="0 0 7 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M6.78033 11.7803C7.07322 11.4874 7.07322 11.0126 6.78033 10.7197L2.06066 6L6.78033 1.28033C7.07322 0.987437 7.07322 0.512563 6.78033 0.21967C6.48744 -0.0732234 6.01256 -0.0732234 5.71967 0.21967L0.46967 5.46967C0.329018 5.61032 0.25 5.80109 0.25 6C0.25 6.19891 0.329018 6.38968 0.46967 6.53033L5.71967 11.7803C6.01256 12.0732 6.48744 12.0732 6.78033 11.7803Z"
					fill={color}
				/>
			</svg>
		);
	}
);
