import * as React from 'react';

export const Unpin = React.memo(({ size = 24, color = '#EC5D25', className, ...rest }) => {
    return (

        <span className={className}>
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.56016 9.26727L13.1464 13.8536C13.3417 14.0488 13.6583 14.0488 13.8536 13.8536C14.0488 13.6583 14.0488 13.3417 13.8536 13.1464L0.853553 0.146447C0.658291 -0.0488155 0.341709 -0.0488155 0.146447 0.146446C-0.0488156 0.341709 -0.0488155 0.658291 0.146447 0.853553L4.73223 5.43934L4.6526 5.58876L1.8419 6.52566C1.6775 6.58046 1.5532 6.71648 1.51339 6.88513C1.47357 7.05378 1.52392 7.23102 1.64646 7.35356L3.79291 9.50001L1.14645 12.1465L1 13L1.85356 12.8536L4.50002 10.2071L6.64646 12.3536C6.76899 12.4761 6.94623 12.5264 7.11489 12.4866C7.28354 12.4468 7.41955 12.3225 7.47435 12.1581L8.41143 9.3469L8.56016 9.26727ZM7.82138 8.52849L7.76403 8.5592C7.65137 8.61951 7.56608 8.72066 7.52567 8.84189L6.7815 11.0744L2.92562 7.21851L5.15812 6.47435C5.27966 6.43383 5.38101 6.34822 5.44126 6.23516L5.47143 6.17854L7.82138 8.52849ZM11.7178 6.4426L9.66362 7.54227L10.4024 8.28105L12.1897 7.32422C13.0759 6.84981 13.2538 5.65509 12.5443 4.94304L9.05892 1.44509C8.34701 0.730615 7.14697 0.908276 6.67261 1.79838L5.71556 3.59421L6.45476 4.33341L7.55511 2.26869C7.71323 1.97199 8.11324 1.91277 8.35055 2.15093L11.836 5.64888C12.0725 5.88623 12.0131 6.28446 11.7178 6.4426Z" fill="#212121"/>
        </svg>
        </span>

    );
})

Unpin.displayName = "Unpin";