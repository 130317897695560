import { createSlice } from '@reduxjs/toolkit';
import instance from 'auth/axiosInstance';
import axios from 'axios';
import LocalStorageService from 'services/LocalStorage';
import ToastService from 'services/ToastService';
import { apiUrls } from 'utils/urls/apiUrls';

const eventsSlice = createSlice({
	name: 'events',
	initialState: {
		isLoading: {
			permission: false,
			eventsExist: false,
			locationAutoComplete: false,
			locationAutoCompleteHotel: false,
			eventList: false,
			createEvent: false,
			updateEvent: false,
			searchEvent: false,
			timezone: false,
			RSVP: false,
			individualEventDetails: false,
			deleteEvent: false,
			updateFieldForAnEvent: false,
			postUpdateToAnEvent: {},
			downloadReports: {},
			pinDelete: {},
			messageAll: false,
			userRoleInEvent: false,
			RSVPcount: false,
			removeParticipants: false,
			currentStatusOfParticipants: false,
			bulkInviteTemplate: false,
			uploadBulkInviteTemplate: false,
			listOfEmployeesForClient: false,
			inviteFilter: false,
			manageOrganizersData: {},
			inviteEmployee: false,
			signedUrlForUpload: false,
			uploadToS3: false,
			fetchTemplateUsers: false,
			activeTripsForTheUser: false,
			addLinkedTrips: false,
			linkedTripToTheEvent: false,
			removeLinkedTripFromTheEvent: false,
		},
		permission: null,
		eventsExist: null,
		locationAutoComplete: null,
		locationAutoCompleteHotel: null,
		listOfUpcomingEvents: null,
		listOfPastEvents: null,
		createEventStatus: null,
		updateEvent: false,
		timezone: null,
		RSVPstatus: null,
		individualEventDetails: null,
		deleteEvent: null,
		updateFieldForAnEvent: null,
		postUpdateToAnEvent: null,
		downloadReports: null,
		pinDelete: null,
		messageAll: null,
		userRoleInEvent: null,
		RSVPcount: null,
		removeParticipants: null,
		currentStatusOfParticipants: null,
		bulkInviteTemplate: null,
		uploadBulkInviteTemplate: null,
		listOfEmployeesForClient: null,
		inviteFilter: null,
		manageOrganizersData: null,
		inviteEmployee: null,
		signedUrlForUpload: null,
		uploadToS3: null,
		fetchTemplateUsers: null,
		activeTripsForTheUser: null,
		addLinkedTrips: null,
		linkedTripToTheEvent: null,
		removeLinkedTripFromTheEvent: null,
	},
	reducers: {
		setEventPermission: (state, action) => {
			state.permission = action.payload;
		},
		setEventsExist: (state, action) => {
			state.eventsExist = action.payload;
		},
		getListOfEvents: (state, action) => {
			state.listOfUpcomingEvents = action.payload;
		},
		setListOfEvents: (state, action) => {
			state.listOfUpcomingEvents = action.payload;
		},
		setListOfPastEvents: (state, action) => {
			state.listOfPastEvents = action.payload;
		},
		setLocationAutoComplete: (state, action) => {
			state.locationAutoComplete = action.payload;
		},
		setLocationAutoCompleteHotel: (state, action) => {
			state.locationAutoCompleteHotel = action.payload;
		},
		createEventStatus: (state, action) => {
			state.createEventStatus = action.payload;
		},
		updateEventStatus: (state, action) => {
			state.updateEvent = action.payload;
		},
		setTimezone: (state, action) => {
			state.timezone = action.payload;
		},
		setRSVPstatus: (state, action) => {
			state.RSVPstatus = action.payload;
		},
		setIndividualEventDetails: (state, action) => {
			state.individualEventDetails = action.payload;
		},
		setIndividualEventDelete: (state, action) => {
			state.deleteEvent = action.payload;
		},
		setUpdateFieldForAnEvent: (state, action) => {
			state.updateFieldForAnEvent = action.payload;
		},
		setPublishUpdateForAnEvent: (state, action) => {
			state.postUpdateToAnEvent = action.payload;
		},
		setDownloadReportsStatus: (state, action) => {
			state.downloadReports = action.payload;
		},
		setPinDeleteStatus: (state, action) => {
			state.pinDelete = action.payload;
		},
		setMessageAllStatus: (state, action) => {
			state.messageAll = action.payload;
		},
		setUserRoleInEvent: (state, action) => {
			state.userRoleInEvent = action.payload;
		},
		setRSVPcount: (state, action) => {
			state.RSVPcount = action.payload;
		},
		setRemoveParticipants: (state, action) => {
			state.removeParticipants = action.payload;
		},
		setCurrentStatusOfParticipants: (state, action) => {
			state.currentStatusOfParticipants = action.payload;
		},
		setBulkInviteTemplate: (state, action) => {
			state.bulkInviteTemplate = action.payload;
		},
		setUploadBulkInviteTemplate: (state, action) => {
			state.uploadBulkInviteTemplate = action.payload;
		},
		setListOfEmployeesForClient: (state, action) => {
			state.listOfEmployeesForClient = action.payload;
		},
		setinviteFilter: (state, action) => {
			state.inviteFilter = action.payload;
		},
		setManageOrganizers: (state, action) => {
			state.manageOrganizersData = action.payload;
		},
		setInviteEmployee: (state, action) => {
			state.inviteEmployee = action.payload;
		},
		setSignedUrlForUpload: (state, action) => {
			state.signedUrlForUpload = action.payload;
		},
		setUploadToS3: (state, action) => {
			state.uploadToS3 = action.payload;
		},
		setFetchTemplateUsers: (state, action) => {
			state.fetchTemplateUsers = action.payload;
		},
		setActiveTripsForTheUser: (state, action) => {
			state.activeTripsForTheUser = action.payload;
		},
		setAddLinkedTrips: (state, action) => {
			state.addLinkedTrips = action.payload;
		},
		setLinkedTripToTheEvent: (state, action) => {
			state.linkedTripToTheEvent = action.payload;
		},
		setRemoveLinkedTripFromTheEvent: (state, action) => {
			state.removeLinkedTripFromTheEvent = action.payload;
		},

		// loading reducers
		setLoadingStateForEventsPermission: (state, action) => {
			state.isLoading.permission = action.payload;
		},
		setLoadingStateForEventsExist: (state, action) => {
			state.isLoading.eventsExist = action.payload;
		},
		setLoadingState: (state, action) => {
			state.isLoading.eventList = action.payload;
		},
		loadingStateForLocationAutoComplete: (state, action) => {
			state.isLoading.locationAutoComplete = action.payload;
		},
		loadingStateForLocationAutoCompleteHotel: (state, action) => {
			state.isLoading.locationAutoCompleteHotel = action.payload;
		},
		setLoadingStateCreateEvent: (state, action) => {
			state.isLoading.createEvent = action.payload;
		},
		setLoadingStateUpdateEvent: (state, action) => {
			state.isLoading.updateEvent = action.payload;
		},
		setSearchEventLoadingState: (state, action) => {
			state.isLoading.searchEvent = action.payload;
		},
		loadingStateForTimeZone: (state, action) => {
			state.isLoading.timezone = action.payload;
		},
		loadingStateForRSVPstatus: (state, action) => {
			state.isLoading.RSVP = action.payload;
		},
		loadingStateForEventDetails: (state, action) => {
			state.isLoading.individualEventDetails = action.payload;
		},
		loadingStateForEventDelete: (state, action) => {
			state.isLoading.deleteEvent = action.payload;
		},
		loadingStateForUpdateFieldForAnEvent: (state, action) => {
			state.isLoading.updateFieldForAnEvent = action.payload;
		},
		loadingStateForPublishingAnUpdateToEvent: (state, action) => {
			state.isLoading.postUpdateToAnEvent = action.payload;
		},
		loadingStateForDownloadReports: (state, action) => {
			state.isLoading.downloadReports = action.payload;
		},
		loadingStateForPinDeleteUpdates: (state, action) => {
			state.isLoading.pinDelete = action.payload;
		},
		loadingStateForMessageAll: (state, action) => {
			state.isLoading.messageAll = action.payload;
		},
		loadingStateForUserRoleInEvent: (state, action) => {
			state.isLoading.userRoleInEvent = action.payload;
		},
		loadingStateForRSVPcount: (state, action) => {
			state.isLoading.RSVPcount = action.payload;
		},
		loadingStateForRemoveParticipants: (state, action) => {
			state.isLoading.removeParticipants = action.payload;
		},
		loadingStateForCurrentStatusOfParticipants: (state, action) => {
			state.isLoading.currentStatusOfParticipants = action.payload;
		},
		loadingStateForBulkInviteTemplate: (state, action) => {
			state.isLoading.bulkInviteTemplate = action.payload;
		},
		loadingStateForUploadBulkInviteTemplate: (state, action) => {
			state.isLoading.uploadBulkInviteTemplate = action.payload;
		},
		loadingStateForListOfEmployeesForClient: (state, action) => {
			state.isLoading.listOfEmployeesForClient = action.payload;
		},
		loadingStateForinviteFilter: (state, action) => {
			state.isLoading.inviteFilter = action.payload;
		},
		loadingStateForManageOrganizers: (state, action) => {
			state.isLoading.manageOrganizersData = action.payload;
		},
		loadingStateForInviteEmployee: (state, action) => {
			state.isLoading.inviteEmployee = action.payload;
		},
		loadingStateForSignedUrlForUpload: (state, action) => {
			state.isLoading.signedUrlForUpload = action.payload;
		},
		loadingStateForUploadToS3: (state, action) => {
			state.isLoading.uploadToS3 = action.payload;
		},
		loadingStateForFetchTemplateUsers: (state, action) => {
			state.isLoading.fetchTemplateUsers = action.payload;
		},
		loadingStateForActiveTripsForTheUser: (state, action) => {
			state.isLoading.activeTripsForTheUser = action.payload;
		},
		loadingStateForAddLinkedTrips: (state, action) => {
			state.isLoading.addLinkedTrips = action.payload;
		},
		loadingStateForLinkedTripToTheEvent: (state, action) => {
			state.isLoading.linkedTripToTheEvent = action.payload;
		},
		loadingStateForRemoveLinkedTripFromTheEvent: (state, action) => {
			state.isLoading.removeLinkedTripFromTheEvent = action.payload;
		},
	},
});

// export const eventsExistFunction= (event_id, loading) => async (dispatch) => {
// 	const token = LocalStorageService.getAccessToken();
// 	let url = `${apiUrls.EVENTS.GET_EVENT_DETAILS}/${event_id}`;
// 	try {
// 		if (loading) {
// 			dispatch(setLoadingStateForEventsExist(true));
// 		}
// 		const response = await instance.get(url, {
// 			headers: {
// 				'Content-Type': 'application/json',
// 				Authorization: `Bearer ${token}`,
// 			},
// 		});
// 		dispatch(setLoadingStateForEventsExist(false));
// 		dispatch(setEventsExist(response.data));
// 	} catch (err) {
// 		dispatch(loadingStateForEventDetails(false));
// 		console.log(err);
// 	}
// };

export const getEventListFromAPI =
	(data, type, loading) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		const config = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
		try {
			let url;
			if (type === 'upcoming') {
				url = `${apiUrls.EVENTS.FETCH_LIST_OF_EVENTS}?active`;
			} else if (type === 'past') {
				url = `${apiUrls.EVENTS.FETCH_LIST_OF_EVENTS}?past`;
			} else if (type === 'checkList') {
				url = `${apiUrls.EVENTS.FETCH_LIST_OF_EVENTS}`;
			}

			if (loading) {
				dispatch(setLoadingState(true));
			}
			if (type === 'checkList') {
				dispatch(setLoadingStateForEventsExist(true));
			}
			const response = await instance.get(url, config);
			dispatch(setLoadingState(false));
			dispatch(setLoadingStateForEventsExist(false));
			if (type !== 'checkList') {
				if (type === 'upcoming') {
					dispatch(setListOfEvents(response.data));
				} else {
					dispatch(setListOfPastEvents(response.data));
				}
			} else {
				dispatch(
					setEventsExist(
						response.data.events.length > 0 ? true : false
					)
				);
			}
		} catch (err) {
			dispatch(setLoadingState(false));
			console.log(err);
		}
	};
export const searchEvent = (type, searchInput) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	const config = {
		headers: {
			authorization: `Bearer ${token}`,
		},
	};
	try {
		let url;
		if (type === 'upcoming') {
			url = `${apiUrls.EVENTS.FETCH_LIST_OF_EVENTS}?search=${searchInput}&active`;
		} else {
			url = `${apiUrls.EVENTS.FETCH_LIST_OF_EVENTS}?search=${searchInput}&past`;
		}

		dispatch(setSearchEventLoadingState(true));

		const response = await instance.get(url, config);

		dispatch(setSearchEventLoadingState(false));

		if (type === 'upcoming') {
			dispatch(setListOfEvents(response.data));
		} else {
			dispatch(setListOfPastEvents(response.data));
		}
	} catch (err) {
		dispatch(setSearchEventLoadingState(false));
		console.log(err);
	}
};
export const fetchEventServicePermission = (data) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.EVENTS_PERMISSION}`;
	try {
		dispatch(setLoadingStateForEventsPermission(true));

		const response = await instance.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(setLoadingStateForEventsPermission(false));
		dispatch(setEventPermission(response.data));
	} catch (err) {
		dispatch(setLoadingStateForEventsPermission(false));
		console.log(err);
	}
};

export const createEvent = (data) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.CREATE_EVENT}`;
	try {
		dispatch(setLoadingStateCreateEvent(true));

		const response = await instance.post(url, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(setLoadingStateCreateEvent(false));
		dispatch(createEventStatus(response.data));
	} catch (err) {
		dispatch(setLoadingState(false));
		console.log(err);
	}
};
export const updateEvent = (data, event_id) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.CREATE_EVENT}/${event_id}`;
	try {
		dispatch(setLoadingStateUpdateEvent(true));

		const response = await instance.put(url, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(setLoadingStateUpdateEvent(false));
		dispatch(updateEventStatus(response.data));
	} catch (err) {
		dispatch(setLoadingStateUpdateEvent(false));
		console.log(err);
	}
};
export const getTimeZoneData = (data) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.TIMEZONE_BASED_ON_LOCATION}`;
	try {
		dispatch(loadingStateForTimeZone(true));
		const response = await instance.post(url, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(loadingStateForTimeZone(false));
		dispatch(setTimezone(response.data));
	} catch (err) {
		dispatch(loadingStateForTimeZone(false));
		console.log(err);
	}
};

export const updateRSVPstatus =
	(data, event_id, user_id) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.UPDATE_RSVP_STATUS}/${event_id}/participants/${user_id}`;
		try {
			dispatch(loadingStateForRSVPstatus(true));
			const response = await instance.patch(url, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});

			dispatch(loadingStateForRSVPstatus(false));
			dispatch(
				setRSVPstatus({
					event_id: event_id,
					...response.data,
				})
			);
		} catch (err) {
			dispatch(loadingStateForRSVPstatus(false));
			console.log(err);
		}
	};

export const getEventDetails = (event_id, loading) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.GET_EVENT_DETAILS}/${event_id}`;
	try {
		if (loading) {
			dispatch(loadingStateForEventDetails(true));
		}
		const response = await instance.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(loadingStateForEventDetails(false));
		dispatch(setIndividualEventDetails(response.data));
	} catch (err) {
		dispatch(loadingStateForEventDetails(false));
		console.log(err);
	}
};

export const getLocationAutoComplete = (search_query) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.LOCATION_AUTO_COMPLETE_PLACE}?search=${search_query}`;

	// return
	try {
		dispatch(loadingStateForLocationAutoComplete(true));
		const response = await instance.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(loadingStateForLocationAutoComplete(false));
		dispatch(setLocationAutoComplete(response.data));
	} catch (err) {
		dispatch(loadingStateForLocationAutoComplete(false));
		console.log(err);
	}
};

export const getLocationAutoCompleteHotel =
	(search_query) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.LOCATION_AUTO_COMPLETE_HOTEL}?search=${search_query}`;

		// return
		try {
			dispatch(loadingStateForLocationAutoCompleteHotel(true));
			const response = await instance.get(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			dispatch(loadingStateForLocationAutoCompleteHotel(false));
			dispatch(setLocationAutoCompleteHotel(response.data));
		} catch (err) {
			dispatch(loadingStateForLocationAutoCompleteHotel(false));
			console.log(err);
		}
	};

export const deleteIndividualEvent = (event_id) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.DELETE_EVENT}/${event_id}`;
	try {
		dispatch(loadingStateForEventDelete(true));
		const response = await instance.delete(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(loadingStateForEventDelete(false));

		if (response.status === 204) {
			dispatch(
				setIndividualEventDelete({
					status: response.status,
				})
			);
		} else {
			dispatch(setIndividualEventDelete(response.data));
		}
	} catch (err) {
		dispatch(loadingStateForEventDelete(false));
		console.log(err);
	}
};

export const updateAFieldForAnEvent = (event_id, data) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.UPDATE_A_FIELD_FOR_AN_EVENT}/${event_id}`;
	try {
		dispatch(loadingStateForUpdateFieldForAnEvent(true));
		const response = await instance.patch(url, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(loadingStateForUpdateFieldForAnEvent(false));
		dispatch(setUpdateFieldForAnEvent(response.data));
	} catch (err) {
		dispatch(loadingStateForUpdateFieldForAnEvent(false));
		console.log(err);
	}
};

export const publishAnUpdateForAnEvent =
	(event_id, data, method, skipLoading) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.CREATE_PUBLISH_UPDATE_FOR_AN_EVENT}/${event_id}/update`;

		try {
			let response;

			// return
			if (method === 'GET') {
				if (skipLoading) {
				} else {
					dispatch(
						loadingStateForPublishingAnUpdateToEvent({
							key: 'GETTING_UPDATES',
							payload: true,
						})
					);
				}
				response = await instance.get(url, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForPublishingAnUpdateToEvent({
						key: 'GETTING_UPDATES',
						payload: true,
					})
				);
				dispatch(
					setPublishUpdateForAnEvent({
						key: 'GETTING_UPDATES',
						payload: response.data,
					})
				);
			} else if (method === 'POST') {
				dispatch(
					loadingStateForPublishingAnUpdateToEvent({
						key: 'POSTING_UPDATES',
						payload: true,
					})
				);
				response = await instance.post(url, data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForPublishingAnUpdateToEvent({
						key: 'POSTING_UPDATES',
						payload: false,
					})
				);
				dispatch(
					setPublishUpdateForAnEvent({
						key: 'POSTING_UPDATES',
						payload: response.data,
					})
				);
			} else if (method === 'PATCH') {
				dispatch(loadingStateForPublishingAnUpdateToEvent(true));
				response = await instance.patch(url, data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForPublishingAnUpdateToEvent(response.data)
				);
			} else if (method === 'DELETE') {
				dispatch(loadingStateForUpdateFieldForAnEvent(true));
				response = await instance.delete(url, data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForPublishingAnUpdateToEvent(response.data)
				);
			} else {
			}
			// dispatch(loadingStateForUpdateFieldForAnEvent(false));
		} catch (err) {
			dispatch(loadingStateForPublishingAnUpdateToEvent(false));
			console.log(err);
		}
	};

export const downloadEventReports =
	(event_id, client_id, download_type) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url;
		try {
			let response;
			if (download_type === 'linked-trip-report') {
				url = `${apiUrls.EVENTS.DOWNLOAD_REPORTS}/${event_id}/client/${client_id}/download-linkedtrip-report`;

				dispatch(
					loadingStateForDownloadReports({
						type: download_type,
						payload: true,
					})
				);
				response = await instance.get(url, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForDownloadReports({
						type: download_type,
						payload: false,
					})
				);
				dispatch(setDownloadReportsStatus(response.data));
			} else if (download_type === 'event-transaction-report') {
				url = `${apiUrls.EVENTS.DOWNLOAD_REPORTS}/${event_id}/client/${client_id}/download-mis-report`;

				dispatch(
					loadingStateForDownloadReports({
						type: download_type,
						payload: true,
					})
				);
				response = await instance.get(url, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForDownloadReports({
						type: download_type,
						payload: false,
					})
				);
				dispatch(setDownloadReportsStatus(response.data));
			} else if (download_type === 'rsvp-report') {
				url = `${apiUrls.EVENTS.DOWNLOAD_REPORTS}/${event_id}/client/${client_id}/download-rsvp-report`;
				dispatch(
					loadingStateForDownloadReports({
						type: download_type,
						payload: true,
					})
				);
				response = await instance.get(url, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForDownloadReports({
						type: download_type,
						payload: false,
					})
				);
				dispatch(setDownloadReportsStatus(response.data));
			}
		} catch (err) {
			dispatch(
				loadingStateForDownloadReports({
					type: download_type,
					payload: false,
				})
			);
			console.log(err);
		}
	};

export const pinDeleteEventUpdate =
	(event_id, update_id, type, data) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url;
		try {
			if (type === 'delete') {
				url = `${apiUrls.EVENTS.PIN_DELETE_EVENT_UPDATE}/${event_id}/update/${update_id}`;
				dispatch(
					loadingStateForPinDeleteUpdates({
						type: 'delete',
						payload: true,
					})
				);
				const response = await instance.delete(url, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForPinDeleteUpdates({
						type: 'delete',
						payload: false,
					})
				);
				dispatch(
					setPinDeleteStatus({
						status: response.status,
						type: 'delete',
					})
				);
			} else if (type === 'pin') {
				url = `${apiUrls.EVENTS.PIN_DELETE_EVENT_UPDATE}/${event_id}/update/${update_id}`;
				dispatch(
					loadingStateForPinDeleteUpdates({
						type: 'pin',
						payload: true,
					})
				);
				const response = await instance.patch(url, data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				dispatch(
					loadingStateForPinDeleteUpdates({
						type: 'pin',
						payload: false,
					})
				);
				dispatch(
					setPinDeleteStatus({
						status: response.status,
						type: data.pin === true ? 'pin' : 'unpin',
					})
				);
			}
		} catch (err) {
			dispatch(
				loadingStateForPinDeleteUpdates({
					type: type,
					payload: false,
				})
			);
			console.log(err);
		}
	};

export const sendMessageAll = (event_id, data) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.MESSAGE_ALL}/${event_id}/participants/message`;
	try {
		dispatch(loadingStateForMessageAll(true));
		const response = await instance.post(url, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		// console.log(response);
		dispatch(loadingStateForMessageAll(false));
		dispatch(setMessageAllStatus(response.data));
	} catch (err) {
		dispatch(loadingStateForMessageAll(false));
		console.log(err);
	}
};
export const fetchUsersRoleForEvent = (event_id) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.EVENT_ROLE}/${event_id}/user-role`;
	try {
		dispatch(loadingStateForUserRoleInEvent(true));
		const response = await instance.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		// console.log(response);
		dispatch(loadingStateForUserRoleInEvent(false));
		dispatch(setUserRoleInEvent(response.data));
	} catch (err) {
		dispatch(loadingStateForUserRoleInEvent(false));
		console.log(err);
	}
};
export const getRSVPcount = (event_id, loading) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.RSVP_COUNT_FOR_EVENT}/${event_id}/participants/count`;
	try {
		if (loading) {
			dispatch(loadingStateForRSVPcount(true));
		}
		const response = await instance.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		dispatch(loadingStateForRSVPcount(false));
		dispatch(setRSVPcount(response.data));
	} catch (err) {
		dispatch(loadingStateForRSVPcount(false));
		console.log(err);
	}
};
export const removeParticipantsFromEvent =
	(event_id, user_id) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.REMOVE_PARTICIPANT_FROM_EVENT}/${event_id}/participants/${user_id}`;
		try {
			dispatch(loadingStateForRemoveParticipants(true));
			const response = await instance.delete(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			dispatch(loadingStateForRemoveParticipants(false));
			dispatch(
				setRemoveParticipants({
					key: 'remove_participant',
					status: response.status,
				})
			);
		} catch (err) {
			dispatch(loadingStateForRemoveParticipants(false));
			console.log(err);
		}
	};
export const getCurrentParticipantsForEvent =
	(event_id, data, type, loading) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.CURRENT_PARTICIPANTS_STATUS_FOR_EVENT}/${event_id}/participants`;
		try {
			if (!loading) {
			} else {
				dispatch(loadingStateForCurrentStatusOfParticipants(true));
			}
			const response = await instance.post(url, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForCurrentStatusOfParticipants(false));
			dispatch(
				setCurrentStatusOfParticipants({
					key: type,
					payload: response.data,
					status: response.status,
				})
			);
		} catch (err) {
			dispatch(loadingStateForCurrentStatusOfParticipants(false));
			console.log(err);
		}
	};
export const getBulkInviteTemplate =
	(event_id, client_id) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.GENERATE_BULK_INVITE_TEMPLATE}/${event_id}/client/${client_id}/bulk-user/generate-template`;
		try {
			dispatch(loadingStateForBulkInviteTemplate(true));
			const response = await instance.get(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForBulkInviteTemplate(false));
			dispatch(setBulkInviteTemplate(response.data));
		} catch (err) {
			dispatch(loadingStateForBulkInviteTemplate(false));
			console.log(err);
		}
	};
export const uploadBulkInviteTemplate =
	(event_id, client_id, file_name) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.UPLOAD_USER_TEMPLATE}/${event_id}/client/${client_id}/bulk-user/upload-file?file_name=${file_name}`;
		try {
			dispatch(loadingStateForUploadBulkInviteTemplate(true));
			const response = await instance.get(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForUploadBulkInviteTemplate(false));
			dispatch(setUploadBulkInviteTemplate(response.data));
		} catch (err) {
			dispatch(loadingStateForUploadBulkInviteTemplate(false));
			console.log(err);
		}
	};
export const getListOfEmployeesForClient =
	(event_id, company_id, data) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let postData = {
			client_id: company_id,
			...data,
		};
		let url = `${apiUrls.EVENTS.LIST_OF_EMPLOYEES_FOR_CLIENT}/${event_id}/getemployee`;
		try {
			dispatch(loadingStateForListOfEmployeesForClient(true));
			const response = await instance.post(url, postData, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForListOfEmployeesForClient(false));
			dispatch(setListOfEmployeesForClient(response.data));
		} catch (err) {
			dispatch(loadingStateForListOfEmployeesForClient(false));
			console.log(err);
		}
	};
export const getinviteFilter = (event_id, client_id) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.INVITE_FILTER}/events/${event_id}/client/${client_id}/invite-filter`;
	try {
		dispatch(loadingStateForinviteFilter(true));
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		// console.log(response);
		dispatch(loadingStateForinviteFilter(false));
		dispatch(setinviteFilter(response.data));
	} catch (err) {
		dispatch(loadingStateForinviteFilter(false));
		console.log(err);
	}
};

export const manageOrganizers =
	(event_id, client_id, data, type) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url;
		try {
			let response;

			dispatch(
				loadingStateForManageOrganizers({
					type: type,
					payload: true,
				})
			);
			if (type === 'add_organizers') {
				url = `${apiUrls.EVENTS.EVENT_ROLE}/${event_id}/organizers`;
				response = await instance.post(url, data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
			} else if (type === 'remove_organizers') {
				url = `${apiUrls.EVENTS.EVENT_ROLE}/${event_id}/organizers/${client_id}`; // client_id is user_id
				response = await instance.delete(url, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
			} else if (type === 'get_organizers') {
				url = `${apiUrls.EVENTS.EVENT_ROLE}/${event_id}/organizers`;
				response = await instance.get(url, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
			}
			// if (response.status === 204 ) {
			// 	dispatch(
			// 		setManageOrganizers({
			// 			type: type,
			// 			data: {
			// 				status: 204,
			// 			},
			// 		})
			// 	);
			// } else

			dispatch(
				setManageOrganizers({
					type: type,
					data: response.data,
				})
			);

			dispatch(
				loadingStateForManageOrganizers({
					type: type,
					payload: false,
				})
			);
			// console.log(response);
		} catch (err) {
			dispatch(
				loadingStateForManageOrganizers({
					type: type,
					payload: false,
				})
			);
			ToastService.error(err.response.data.message);
			console.log(err);
		}
	};

export const inviteEmployees =
	(event_id, client_id, data) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.INVITE_EMPLOYEES}/${event_id}/client/${client_id}/employee`;
		try {
			dispatch(loadingStateForInviteEmployee(true));
			const response = await instance.post(url, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForInviteEmployee(false));
			dispatch(setInviteEmployee(response.data));
		} catch (err) {
			dispatch(loadingStateForInviteEmployee(false));
			console.log(err);
		}
	};
export const getSignedUrlForUpload =
	(event_id, client_id) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.GET_SIGNED_URL_FOR_UPLOAD}/${event_id}/client/${client_id}/bulk-user/upload-file`;
		try {
			dispatch(loadingStateForSignedUrlForUpload(true));
			const response = await instance.get(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForSignedUrlForUpload(false));
			dispatch(setSignedUrlForUpload(response.data));
		} catch (err) {
			dispatch(loadingStateForSignedUrlForUpload(false));
			console.log(err);
		}
	};
export const uploadTemplateToS3 = (uri, body) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	// let url = `${apiUrls.EVENTS.GET_SIGNED_URL_FOR_UPLOAD}/${event_id}/client/${client_id}/bulk-user/upload-file`;
	let url = uri;
	try {
		dispatch(loadingStateForUploadToS3(true));
		const response = await instance.post(
			url,
			body
			// 	{
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		Authorization: `Bearer ${token}`,
			// 	},
			// }
		);
		// console.log(response);
		dispatch(loadingStateForUploadToS3(false));
		dispatch(
			setUploadToS3({
				status: response.status,
				key: 'uploadedSignedUrl',
			})
		);
	} catch (err) {
		dispatch(loadingStateForUploadToS3(false));
		console.log(err);
	}
};
export const fetchTemplateUsers =
	(event_id, client_id, filename) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.FETCH_TEMPLATE_USERS_FOR_INVITE}/${event_id}/client/${client_id}/bulk-user/invite?file_name=${filename}`;
		try {
			dispatch(loadingStateForFetchTemplateUsers(true));
			const response = await instance.get(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForFetchTemplateUsers(false));
			dispatch(setFetchTemplateUsers(response.data));
		} catch (err) {
			dispatch(loadingStateForFetchTemplateUsers(false));
			console.log(err);
		}
	};
export const getActiveTripsForTheUser = (event_id) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.GET_ACTIVE_USER_TRIPS}/${event_id}/active-trip`;
	try {
		dispatch(loadingStateForActiveTripsForTheUser(true));
		const response = await instance.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		// console.log(response);
		dispatch(loadingStateForActiveTripsForTheUser(false));
		dispatch(setActiveTripsForTheUser(response.data));
	} catch (err) {
		dispatch(loadingStateForActiveTripsForTheUser(false));
		console.log(err);
	}
};
export const linkedSelectedTrips = (event_id, data) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.EVENTS.ADD_LINKED_TRIPS}/${event_id}/trip`;
	try {
		dispatch(loadingStateForAddLinkedTrips(true));
		const response = await instance.post(url, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		// console.log(response);
		dispatch(loadingStateForAddLinkedTrips(false));
		dispatch(setAddLinkedTrips(response.data));
	} catch (err) {
		dispatch(loadingStateForAddLinkedTrips(false));
		console.log(err);
	}
};
export const fetchLinkedTripsToTheEvent =
	(event_id, data) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.FETCH_LINKED_TRIPS}/${event_id}/trip`;
		try {
			dispatch(loadingStateForLinkedTripToTheEvent(true));
			const response = await instance.get(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForLinkedTripToTheEvent(false));
			dispatch(setLinkedTripToTheEvent(response.data));
		} catch (err) {
			dispatch(loadingStateForLinkedTripToTheEvent(false));
			console.log(err);
		}
	};
export const removeLinkedTripsToTheEvent =
	(event_id, tripId) => async (dispatch) => {
		const token = LocalStorageService.getAccessToken();
		let url = `${apiUrls.EVENTS.REMOVE_LINKED_TRIPS}/${event_id}/trip/${tripId}`;
		try {
			dispatch(loadingStateForRemoveLinkedTripFromTheEvent(true));
			const response = await instance.delete(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			// console.log(response);
			dispatch(loadingStateForRemoveLinkedTripFromTheEvent(false));
			dispatch(
				setRemoveLinkedTripFromTheEvent({
					status: response.status,
					key: 'event_removed',
				})
			);
		} catch (err) {
			dispatch(loadingStateForRemoveLinkedTripFromTheEvent(false));
			console.log(err);
		}
	};

export const {
	setEventPermission,
	setEventsExist,
	getListOfEvents,
	setListOfEvents,
	updateEventStatus,
	setListOfPastEvents,
	setLoadingState,
	setLocationAutoComplete,
	setLocationAutoCompleteHotel,
	createEventStatus,
	setRSVPstatus,
	setSearchEventLoadingState,
	setLoadingStateCreateEvent,
	setIndividualEventDetails,
	setIndividualEventDelete,
	setUpdateFieldForAnEvent,
	setPublishUpdateForAnEvent,
	setDownloadReportsStatus,
	setPinDeleteStatus,
	setMessageAllStatus,
	setUserRoleInEvent,
	setRSVPcount,
	setRemoveParticipants,
	setCurrentStatusOfParticipants,
	setBulkInviteTemplate,
	setUploadBulkInviteTemplate,
	setListOfEmployeesForClient,
	setinviteFilter,
	setManageOrganizers,
	setInviteEmployee,
	setSignedUrlForUpload,
	setUploadToS3,
	setFetchTemplateUsers,
	setActiveTripsForTheUser,
	setAddLinkedTrips,
	setLinkedTripToTheEvent,
	setRemoveLinkedTripFromTheEvent,

	setLoadingStateForEventsPermission,
	setLoadingStateForEventsExist,
	loadingStatesSetter,
	setLoadingStateUpdateEvent,
	setTimezone,
	loadingStateForTimeZone,
	loadingStateForRSVPstatus,
	loadingStateForEventDetails,
	loadingStateForEventDelete,
	loadingStateForUpdateFieldForAnEvent,
	loadingStateForPublishingAnUpdateToEvent,
	loadingStateForLocationAutoComplete,
	loadingStateForLocationAutoCompleteHotel,
	loadingStateForDownloadReports,
	loadingStateForPinDeleteUpdates,
	loadingStateForMessageAll,
	loadingStateForUserRoleInEvent,
	loadingStateForRSVPcount,
	loadingStateForRemoveParticipants,
	loadingStateForCurrentStatusOfParticipants,
	loadingStateForBulkInviteTemplate,
	loadingStateForUploadBulkInviteTemplate,
	loadingStateForListOfEmployeesForClient,
	loadingStateForinviteFilter,
	loadingStateForManageOrganizers,
	loadingStateForInviteEmployee,
	loadingStateForSignedUrlForUpload,
	loadingStateForUploadToS3,
	loadingStateForFetchTemplateUsers,
	loadingStateForActiveTripsForTheUser,
	loadingStateForAddLinkedTrips,
	loadingStateForLinkedTripToTheEvent,
	loadingStateForRemoveLinkedTripFromTheEvent,
} = eventsSlice.actions;
export const eventReducerData = (state) => state.events;
export default eventsSlice.reducer;
