import { Input } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
const { TextArea: AntTextArea } = Input;

const TextAreaComp = (props) => {
	const {
		className = '',
		defaultValue,
		value,
		onChange = () => {},
		placeholder = '',
		type = 'text',
		disabled = false,
		rows = 4,
		resize = 'none',
		...rest
	} = props;
	return (
		<AntTextArea
			disabled={disabled}
			className={clsx(className)}
			onChange={onChange}
			placeholder={placeholder}
			value={value}
			type={type}
			defaultValue={defaultValue}
			rows={rows}
			style={{ resize: resize }}
			role="textarea"
			{...rest}
		/>
	);
};

TextAreaComp.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.any,
	defaultValue: PropTypes.any,
	rows: PropTypes.number,
	resize: PropTypes.string,
};

export const TextArea = React.memo(TextAreaComp);
