import * as React from 'react';

export const Pin = React.memo(({ size = 24, color = '#EC5D25', className, ...rest }) => {
    return (
        <span className={className}>
        <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.05892 0.44535C7.34701 -0.269126 6.14697 -0.0914648 5.67261 0.798637L3.6526 4.58902L0.841896 5.52592C0.6775 5.58072 0.553199 5.71673 0.513385 5.88539C0.473572 6.05404 0.523923 6.23128 0.646457 6.35382L2.79291 8.50027L0.146455 11.1467L0 12.0003L0.853561 11.8538L3.50002 9.20738L5.64646 11.3538C5.76899 11.4764 5.94623 11.5267 6.11489 11.4869C6.28354 11.4471 6.41955 11.3228 6.47435 11.1584L7.41143 8.34715L11.1897 6.32448C12.0759 5.85006 12.2538 4.65535 11.5443 3.9433L8.05892 0.44535ZM6.55511 1.26895C6.71323 0.972248 7.11324 0.913027 7.35055 1.15119L10.836 4.64914C11.0725 4.88648 11.0131 5.28472 10.7178 5.44286L6.76403 7.55946C6.65137 7.61977 6.56608 7.72092 6.52567 7.84215L5.7815 10.0746L1.92562 6.21877L4.15812 5.47461C4.27966 5.43409 4.38101 5.34848 4.44126 5.23542L6.55511 1.26895Z" fill="#212121"/>
        </svg>
        </span>
    );
})

Pin.displayName = "Pin";