import * as React from 'react';

export const MenuExpand = React.memo(({ size = 16, color = '#6b7280', className, ...rest }) => {
    return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="12"
			fill="none"
			viewBox="0 0 21 12"
			{...rest}
		>
			<path
				fill={color}
				fillRule="evenodd"
				d="M0 .75A.75.75 0 01.75 0h13.5a.75.75 0 010 1.5H.75A.75.75 0 010 .75zm17.22 1.97a.75.75 0 011.06 0l2.25 2.25a.75.75 0 010 1.06l-2.25 2.25a.75.75 0 11-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 010-1.06zM0 5.75A.75.75 0 01.75 5h13.5a.75.75 0 010 1.5H.75A.75.75 0 010 5.75zm0 5A.75.75 0 01.75 10h13.5a.75.75 0 010 1.5H.75a.75.75 0 01-.75-.75z"
				clipRule="evenodd"
			></path>
		</svg>
	);
})

MenuExpand.displayName = "MenuExpand";