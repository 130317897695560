import { Suspense, useEffect } from 'react';
import LocalStorageService from '../services/LocalStorage';
import { roleBasedPermission } from 'auth/roleBasedPermissions';

const RouteAllowed = (props) => {
	const { thisUrlCanBeSentViaEmail } = props;
	const role = LocalStorageService.getRole();
	const selectedData = roleBasedPermission[props.route];
	const isAllowed = selectedData && selectedData.indexOf(role) !== -1;

	function logout() {
		LocalStorageService.removeAllToken();
		window.location.href = process.env.REACT_APP_LOGIN_URL;
	}
	if (thisUrlCanBeSentViaEmail) {
		if (role === false) {
			logout();
		}
	}

	const returnData = isAllowed ? (
		props.children
	) : !role ? null : (
		<div className="not-found">
			<h1>404</h1>
			<h3>Page not found</h3>
		</div>
	);
	useEffect(() => {
		props.getAppRoute(props.route);
	}, [props]);

	return <Suspense> {returnData} </Suspense>;
};

export default RouteAllowed;
