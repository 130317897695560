import * as React from 'react';

export const Description = React.memo(({ size = 24, color = '#EC5D25', className, ...rest }) => {
    return (

        <svg width={size} height={size} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 0C0.223858 0 0 0.223858 0 0.5C0 0.776142 0.223858 1 0.5 1H15.5C15.7761 1 16 0.776142 16 0.5C16 0.223858 15.7761 0 15.5 0H0.5ZM0.5 3C0.223858 3 0 3.22386 0 3.5C0 3.77614 0.223858 4 0.5 4H15.5C15.7761 4 16 3.77614 16 3.5C16 3.22386 15.7761 3 15.5 3H0.5ZM0 6.5C0 6.22386 0.223858 6 0.5 6H15.5C15.7761 6 16 6.22386 16 6.5C16 6.77614 15.7761 7 15.5 7H0.5C0.223858 7 0 6.77614 0 6.5ZM0.5 9C0.223858 9 0 9.22386 0 9.5C0 9.77614 0.223858 10 0.5 10H10.5C10.7761 10 11 9.77614 11 9.5C11 9.22386 10.7761 9 10.5 9H0.5Z" fill={color}/>
        </svg>

    );
})

Description.displayName = "Description";