import { Typography } from 'antd';
import clsx from 'clsx';
import { MenuItem } from 'components/atoms';
import { useEffect, useState } from 'react';
import classes from './Navbar.module.scss';
import { v4 as uuid } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';

function NavbarList(props) {
	const { Title } = Typography;
	const [activeMenu, setActiveMenu] = useState(1);
	const navigate = useNavigate();
	const menuList = [
		{
			id: 1,
			label: 'Home',
			link: process.env.REACT_APP_ITILITE_BASE_URL,
			sameSite: false,
		},
		{
			id: 2,
			label: 'Events',
			link: '/team-events',
			sameSite: true,
		},
		// {
		// 	id: 3,
		// 	label: 'Concierge',
		// 	link: '/',
		// 	sameSite: false,
		// },
		// {
		// 	id: 4,
		// 	label: 'Pending approvals',
		// 	link: '/',
		// 	sameSite: false,
		// },
		// {
		// 	id: 5,
		// 	label: 'IL Cash',
		// 	link: '/',
		// 	sameSite: false,
		// },
	];

	useEffect(() => {
		if (window.location.pathname.includes('/team-events')) {
			setActiveMenu(2);
		}
	}, [window.location]);
	const redirectTo = (id, link, sameSite) => {
		setActiveMenu(id);
		if (sameSite) {
			navigate(link);
		} else {
			window.location.href = link;
		}
	};
	return (
		<div className={clsx(classes['il__navbar--list'])}>
			<div className={clsx(classes['il__navbar--product-name'])}>
				<Title level={3}>Travel</Title>
			</div>
			<div className={clsx(classes['il__navbar--product-menu'])}>
				{menuList.map((_m) => (
					<MenuItem
						key={uuid()}
						label={_m.label}
						active={_m.id === activeMenu}
						onClick={() => redirectTo(_m.id, _m.link, _m.sameSite)}
					/>
				))}
			</div>
		</div>
	);
}

export default NavbarList;
