import React, { useCallback, useRef, useState } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Input, Button, Skeleton, Popover, MenuItem } from 'components/atoms';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import avatar from '../../../../../assets/images/teamevents/avatar.png';
import { useDispatch, useSelector } from 'react-redux';
import {
	getBulkInviteTemplate,
	getListOfEmployeesForClient,
	getRSVPcount,
	getSignedUrlForUpload,
	inviteEmployees,
	setBulkInviteTemplate,
	setInviteEmployee,
	uploadTemplateToS3,
} from 'redux/features/Events/Events.slice';
import { useEffect } from 'react';
import ToastService from 'services/ToastService';
import { Filter } from 'components/Filter/Filter';
import { titleCase, titleCaseEveryWord } from '../utils';
import { OutlinedProfileIcon } from 'icons/ProfileIcon';
import { UploadIcon } from 'icons/Upload';
import { InfoIcon } from 'icons/InfoIcon';

const InviteParticipantsModal = (props) => {
	const {
		type,
		eventId,
		totalEmployees,
		setTotalEmployees,
		totalEmployeesOG,
		setTotalEmployeesOG,
		selectedEmployees,
		setSelectedEmployees,
		// handleInviteEmployee,
		setIsModalOpen,
		file,
		setFile,
		usersFromTemplate,
		setUsersFromTemplate,
		inviteFilter,
		activeFilter, setActiveFilter, setSearchParticipants, searchParticipants
	} = props;
	const eventReducer = useSelector((state) => state.events);
	const authReducer = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const {
		bulkInviteTemplate,
		isLoading,
		uploadBulkInviteTemplate,
		listOfEmployeesForClient,
		signedUrlForUpload,
		uploadToS3,
		fetchTemplateUsers,
		inviteEmployee,
		//inviteFilter,
	} = eventReducer;
	const { userDetails } = authReducer;

	let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

	let placeHolderFilter = {
		filter: {
			employee_level: [],
			entity: '',
			business_unit: '',
			department: '',
		},
	}
	const [activeCompany, setActiveCompany] = useState(null)
	const [companyId, setCompanyId] = useState(null)
	const [fileUrl, setFileUrl] = useState(null);
	const [selectedFile, setSelectedFile] = useState([]);
	const [templateUserErrorState, setTemplateUserErrorState] = useState(null)

	const ref = useRef()


	const onDrop = useCallback((acceptedFiles) => {
		// Do something with the files
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	const handleTemplateDownload = () => {
		dispatch(getBulkInviteTemplate(eventId, userDetails.company));
	};

	let x = {
		filter: {
			employee_level: [],
			entity: '',
			business_unit: '',
			department: '',
		},
	};

	useEffect(() => {
		if (listOfEmployeesForClient) {

			let _local = listOfEmployeesForClient.map((data) => {
				return {
					...data,
					checked: false,
				};
			});
			setTotalEmployees(_local);
			setTotalEmployeesOG(_local);
			// setSelectedEmployees([])
		}
	}, [listOfEmployeesForClient]);

	//TODO: DO NOT REMOVE
	// useEffect(() => {
	// 	dispatch(getListOfEmployeesForClient(eventId, userDetails?.company, activeFilter));
	// 	if (type === 'bulk' && signedUrlForUpload === null) {
	// 		dispatch(getSignedUrlForUpload(eventId, userDetails.company));
	// 	}
	// }, [signedUrlForUpload]);

	useEffect(() => {
		if (templateUserErrorState?.status === false) {
			ToastService.error(templateUserErrorState?.message)
			setUsersFromTemplate(null)

		}
	}, [setTemplateUserErrorState])

	useEffect(() => {
		if (fetchTemplateUsers && fetchTemplateUsers.status) {
			setUsersFromTemplate(fetchTemplateUsers.valid_users);
			setTemplateUserErrorState(fetchTemplateUsers)
		}
		else {
			setTemplateUserErrorState(fetchTemplateUsers)
		}
	}, [fetchTemplateUsers]);

	useEffect(() => {
		if (bulkInviteTemplate && bulkInviteTemplate.status) {
			window.open(bulkInviteTemplate.download_link);
			dispatch(setBulkInviteTemplate(null))
		}
	}, [bulkInviteTemplate]);



	// useEffect(() => {
	// 	if (totalEmployees.length) {
	// 		let _selectedArray = totalEmployees.filter(
	// 			(_data) => _data.checked
	// 		);

	// 		setSelectedEmployees(_selectedArray);
	// 	}
	// }, [totalEmployees]);

	const formatTotalEmployeeStructure = (_totalEmpArr) => {

		let tempArr = [..._totalEmpArr]
		let tempArrSelected = [...selectedEmployees]

		for (let i = 0; i < tempArr.length; i++) {
			for (let j = 0; j < tempArrSelected.length; j++) {

				if (tempArrSelected[j].id === tempArr[i].id) {
					tempArr[i].checked = true
				}
			}

		}
		return tempArr
	}

	useEffect(() => {
		if (inviteEmployee) {
			if (inviteEmployee.status === false) {
				ToastService.error(inviteEmployee.message);
			} else {
				ToastService.successNotication(inviteEmployee.message);
				setIsModalOpen(false);
				setSelectedEmployees([])
				dispatch(getRSVPcount(eventId, true))
			}
			dispatch(setInviteEmployee(null));
		}
	}, [inviteEmployee]);


	const handleEmployeeSelect = (e, _data, index) => {
		let _localArray = [...totalEmployees];
		let requiredEntry = { ..._localArray[index] };

		if (e.target.checked) {
			requiredEntry.checked = true;
		} else {
			requiredEntry.checked = false;
		}
		_localArray[index] = requiredEntry;

		let _selectedArray = [..._localArray].filter(
			(_data) => _data.checked
		);

		let newTempArr = [...selectedEmployees].filter((val) => val.id !== _data.id)


		let tempArr1 = [...newTempArr, ..._selectedArray]
		const uniqueArray = tempArr1.filter((obj, index, self) => {
			return index === self.findIndex((t) => (
				t.id === obj.id && t.email === obj.email
			));
		});
		setSelectedEmployees(uniqueArray)

		setTotalEmployees(_localArray);
		// setTotalEmployeesOG(_localArray);
	};

	const removeSelectedEmployee = (_data, clearAll) => {
		if (clearAll) {
			setSelectedEmployees([]);

			let _local = listOfEmployeesForClient.map((data) => {
				return {
					...data,
					checked: false,
				};
			});
			setTotalEmployees(_local);
			setTotalEmployeesOG(_local);
		} else {
			let _localData = [...selectedEmployees];
			let newLocalData = _localData.filter(
				(data) => data.id !== _data.id
			);
			setSelectedEmployees(newLocalData);

			let localTotalEmployees = [...totalEmployees];
			localTotalEmployees.forEach((data) => {
				if (data.id === _data.id) {
					data.checked = false;
				}
			});

			setTotalEmployees(localTotalEmployees);
		}
	};

	const handleLocalSearchOnEmployee = (e) => {
		setSearchParticipants(e.target.value);
		let val = e.target.value;

		let temp1 = [...totalEmployees];
		let temp2 = [...totalEmployeesOG]

		for (let i = 0; i < temp2.length; i++) {
			for (let j = 0; j < temp1.length; j++) {

				if (temp1[j].id === temp2[i].id && temp1[j].checked) {
					temp2[i].checked = true
				}
				else if (temp1[j].id === temp2[i].id && !temp1[j].checked) {
					temp2[i].checked = false
				}
			}

		}



		let tempArr = temp2.filter(
			(_data) =>
				_data.name.toLowerCase().includes(val.toLowerCase()) ||
				_data.email.toLowerCase().includes(val.toLowerCase())
		);
		setTotalEmployees(tempArr);
	};

	const handleSelectAll = () => {
		let OFlocalEmployee = [...totalEmployees];
		let localTotalEmployees = [...totalEmployees].filter((_data) => !_data.invited);
		localTotalEmployees.forEach((data) => {
			if (data.invited === false)
				data.checked = true;
		});

		// return
		setTotalEmployees(OFlocalEmployee);
		setSelectedEmployees(localTotalEmployees);
	};

	const handleFileUpload = async () => {
		const formData = new FormData();
		formData.append('key', signedUrlForUpload?.fields?.key);
		formData.append(
			'x-amz-algorithm',
			signedUrlForUpload?.fields['x-amz-algorithm']
		);
		formData.append(
			'x-amz-credential',
			signedUrlForUpload?.fields['x-amz-credential']
		);
		formData.append('x-amz-date', signedUrlForUpload?.fields['x-amz-date']);
		formData.append('policy', signedUrlForUpload?.fields['policy']);
		formData.append(
			'x-amz-signature',
			signedUrlForUpload?.fields['x-amz-signature']
		);
		formData.append('file', file);

		dispatch(uploadTemplateToS3(signedUrlForUpload.url, formData));
	};

	//Here you will get the data selected by the user.
	const handleFilterApply = ({ company, filteredObject }) => {
		dispatch(getListOfEmployeesForClient(eventId, [company.id], {
			filter: filteredObject
		}));
		setSearchParticipants("")
		setActiveFilter({
			filter: filteredObject
		})
		setActiveCompany(company)
	};


	const onCancel = () => {
		console.log('called'); //any action on click of cancel can be here.
	};



	const mapFilterType = (type) => {

		let localActiveArr = [...activeFilter.filter[type]];
		let localArr = [...activeCompany[type]];

		const localArrWithKeyValues = localArr.reduce((result, item) => {
			result[item.id] = item.name;
			return result;
		}, {});

		let newArr = localActiveArr.map((_data) => localArrWithKeyValues[_data]);

		let arrString = newArr.map((_data) => _data);
		return arrString.map((_data, index) => {
			if (index < arrString.length - 1) {
				return <><b>{_data}</b> or </>;
			} else {
				return <b>{_data}</b>;
			}
		});
	};

	const handleRemoveFilter = (type) => {
		let companyIdArr = inviteFilter?.invite_filter.map((item) => item.id)
		let localFilter = { ...activeFilter.filter };
		localFilter[type] = []
		setActiveFilter({
			filter: localFilter
		})
		//[activeCompany.id]
		dispatch(getListOfEmployeesForClient(eventId, companyIdArr, {
			filter: localFilter
		}));
	}

	const clearAllRender = () => {
		let render = false
		Object.keys(activeFilter.filter).forEach((type) => {
			if (activeFilter.filter[type]?.length > 0) {
				render = true;
			}
		})
		return render
	}

	const handleClearAll = () => {
		let companyIdArr = inviteFilter?.invite_filter.map((item) => item.id)
		setActiveFilter(placeHolderFilter)
		setActiveCompany(null)
		dispatch(getListOfEmployeesForClient(eventId, companyIdArr, placeHolderFilter));
	}

	const titleCaseEachWord = (name) => {
		const words = name.split(" ");

		const capitalizedWords = words.map(word => {
			const firstLetter = word.charAt(0).toUpperCase();
			const restOfWord = word.slice(1);
			return `${firstLetter}${restOfWord}`;
		});

		const capitalizedName = capitalizedWords.join(" ");

		return capitalizedName;
	}

	const general = () => {
		return <div className={`tw-width-[592px] tw-p-4 tw-pr-4 tw-mb-8 tw-rounded-lg tw-border-solid tw-border tw-p-4 ${fetchTemplateUsers?.failure_count === 0 && fetchTemplateUsers?.status ? "tw-border-[#A7F3D0] tw-bg-[#ECFDF5]" : "tw-border-[#FECDD3] tw-bg-[#FFF1F2]"} `}>
			<>
				{
					fetchTemplateUsers?.failure_count === 0 && fetchTemplateUsers.success_count > 0 ? (
						<div className='tw-flex'>
							<InfoIcon color="#10B981" />
							<div className='tw-ml-3'>
								<p className={`tw-mt-0 tw-font-medium`}>{fetchTemplateUsers?.message}</p>
								{fetchTemplateUsers?.success_count}{' '}
								<span className='tw-text-gray-600'>Participants imported successfully</span>
							</div>
						</div>
					) : null
				}
				{
					fetchTemplateUsers?.failure_count > 0 && fetchTemplateUsers.success_count === 0 || fetchTemplateUsers?.failure_count === 0 && fetchTemplateUsers.success_count === 0 && fetchTemplateUsers.status === false ? (
						<div className='tw-flex'>
							<InfoIcon color="#F43F5E" />
							<div className='tw-ml-3'>
								{
									fetchTemplateUsers?.failure_count === 0 && fetchTemplateUsers?.success_count === 0 && fetchTemplateUsers?.status === false ? <p className='tw-mt-0'>Failed</p> : null
								}
								<p className={`tw-mt-0 tw-font-medium`}>{fetchTemplateUsers?.message}</p>
								{fetchTemplateUsers?.failure_count === 0 && fetchTemplateUsers.success_count === 0 && fetchTemplateUsers.status === false ? null :
									<>
										<span className='tw-text-gray-600'>Participants failed to import. View the error report for more information</span>

										<p className='tw-font-medium tw-cursor-pointer tw-mt-4 tw-mb-0'
											onClick={() =>
												window.open(
													fetchTemplateUsers?.error_report_link
												)
											}
										>
											Error report
										</p>
									</>
								}
							</div>
						</div>
					) : null

				}
			</>
		</div>
	}
	const partialSuccess = () => {
		return (
			<div className={`tw-width-[592px] tw-pr-4  tw-mb-8 tw-bg-[#FEFCE8] tw-p-4 tw-rounded-lg tw-border-solid tw-border tw-border-[#FDE047]`}>
				{fetchTemplateUsers?.failure_count > 0 && fetchTemplateUsers.success_count > 0 ? (
					<>
						<div className='tw-flex'>
							{/* <p className='tw tw-m-0'>Partial success</p> */}
							<InfoIcon color="#CA8A04" />
							<div className='tw-ml-4'>

								<p className='tw-mt-0'>{fetchTemplateUsers?.message}</p>
								{fetchTemplateUsers?.success_count}{' '}
								{fetchTemplateUsers?.success_count > 1 ? "Participants" : "Participant"}  imported successfully and{' '}
								{fetchTemplateUsers?.failure_count}{' '}
								failed. Click below error report for
								more details.
								<br />
								<p className='tw-text-blue-500 tw-m-0 tw-cursor-pointer tw-my-2'
									onClick={() =>
										window.open(
											fetchTemplateUsers?.error_report_link
										)
									}
								>
									Error report
								</p>
							</div>
						</div></>
				) : null}

			</div>
		)

	}

	const rejectedFileHandler = (files) => {
		let rejectedExtensions = files.map(i => { let t = i.file.name.split("."); return '.' + t[t.length - 1] })
		let temp = new Set(rejectedExtensions)
		rejectedExtensions = [...temp]
		let msg = rejectedExtensions.join(", ") + `${rejectedExtensions.length > 1 ? " files " : " file"} cannot be uploaded.`
		ToastService.error(msg)
	}
	return (
		<>
			{type === 'individual' ? (
				<div className="tw-flex tw-mx-[-24px]">
					<div
						className="tw-w-1/2 tw-p-[16px]"
						style={{ height: '600px', overflowY: 'scroll', padding: '0' }}
					>
						<div className='tw-p-4'>


							<Input
								size="large"
								placeholder="Search for participant by name or email address"
								className="tw-mb-[15px]"
								prefix={isFirefox ? "" : <SearchOutlined />}
								onChange={(e) => handleLocalSearchOnEmployee(e)}
								onKeyUp={(e) => handleLocalSearchOnEmployee(e)}
								value={searchParticipants}
							/>

							<div className='tw-flex '>
								{
									activeFilter ? Object.keys(activeFilter.filter).map((type) => {
										if (activeFilter.filter[type]?.length > 0) {
											return <div className='tw-mb-2 tw-mt-2'>{titleCase(type)} is {mapFilterType(type)}

												<span className='tw-cursor-pointer tw-ml-2' onClick={() => handleRemoveFilter(type, false)}>{<CloseOutlined />}</span>
											</div>;
										}

									}) : null
								}
								<div className='tw-ml-2 tw-flex tw-align-center tw-justify-between'>

									<Filter
										filterData={inviteFilter?.invite_filter}
										onApply={handleFilterApply}
										onCancel={onCancel}
										setCompanyId={setCompanyId}
									/>
									<>
										{
											clearAllRender() ? <span onClick={handleClearAll} className="tw-ml-2 tw-cursor-pointer tw-text-color-icon-info tw-mb-2 tw-mt-2">Clear all</span> : null
										}
									</>
								</div>

							</div>
						</div>

						<div className='tw-p-4 tw-pt-0'>
							<ul className="tw-list-none tw-p-0">
								{isLoading.listOfEmployeesForClient ? (
									<Skeleton
										width={100}
										paragraph={true}
										active={true}
										height={1}
										size="large"
									/>
								) : (
									<li className="tw-flex tw-items-center tw-justify-between tw-mb-[20px]">
										<div>
											Showing all {totalEmployees.length}
										</div>
										<Button
											type="text"
											disabled={
												totalEmployees.length === 0
											}
											onClick={() => handleSelectAll()}
										>
											Select all
										</Button>
									</li>
								)}
								{isLoading.listOfEmployeesForClient ? (
									<Skeleton
										width={100}
										paragraph={true}
										active={true}
										height={2}
										size="large"
									/>
								) : (
									<>
										{formatTotalEmployeeStructure(totalEmployees).map((_data, i) => {
											return (
												<li
													className={`tw-flex tw-mb-[20px] tw-h-[46px] ${_data.invited ? "tw-opacity-60" : ""}`}
													key={_data.id}
												>
													<label className="tw-flex tw-justify-between tw-cursor-pointer tw-w-full">
														<div className="tw-h-full tw-w-[36px] tw-mr-[10px]  tw-flex tw-items-center tw-justify-center">
															{
																_data.profile_photo.icon_path ? <img
																	src={_data.profile_photo.icon_path ? _data.profile_photo.icon_path : <OutlinedProfileIcon />}
																	alt=""
																	width={30}
																	height={30}
																	className="tw-mr-[-5px] tw-aspect-square tw-rounded-full"

																/> : <OutlinedProfileIcon size={60} />
															}
														</div>
														<div className="tw-flex-grow">
															<p className="tw-m-0">
																{titleCaseEachWord(_data.name)}
															</p>
															<span className="tw-text-gray-500">
																{_data.email}
															</span>
															{_data.invited ? <p className="tw-text-orange-600 tw-m-0 tw-text-font-size-10">
																Already invited
															</p> : null}
														</div>
														<div className="tw-ml-[10px] tw-flex tw-items-center">
															<input
																type="checkbox"
																name=""
																id=""
																checked={
																	_data.checked || _data.invited
																}
																disabled={_data.invited}
																onChange={(e) =>
																	handleEmployeeSelect(
																		e,
																		_data,
																		i
																	)
																}
															/>
														</div>
													</label>
												</li>
											);
										})}
									</>
								)}

								{/* Repition */}
							</ul>
						</div>
					</div>
					<div
						className="tw-w-1/2 tw-p-[16px] tw-bg-[#F9FAFB]"
						style={{ height: '600px', overflowY: 'scroll' }}
					>
						<div className="tw-flex tw-justify-end">
							{selectedEmployees.length !== 0 ? (
								<Button
									type="text"
									onClick={() =>
										removeSelectedEmployee(null, true)
									}
								>
									Clear all
								</Button>
							) : null}
						</div>
						<ul className="tw-list-none tw-p-0">
							{selectedEmployees.map((_data) => {
								return (
									<li
										className="tw-flex tw-mb-[14px] tw-h-[46px]"
										key={_data.id}
									>
										<div className="tw-h-full tw-w-[36px] tw-mr-[10px]  tw-flex tw-items-center tw-justify-center">

											{
												_data.profile_photo.icon_path ? <img
													src={_data.profile_photo.icon_path ? _data.profile_photo.icon_path : <OutlinedProfileIcon />}
													alt=""
													width={30}
													height={30}
													className="tw-mr-[-5px] tw-aspect-square tw-rounded-full"

												/> : <OutlinedProfileIcon size={60} />
											}

										</div>
										<div className="tw-flex-grow">
											<p className="tw-m-0">
												{titleCaseEveryWord(_data.name)}
											</p>
											<span className="tw-text-gray-500">
												{_data.email}
											</span>
										</div>
										<div
											className="tw-ml-[10px] tw-flex tw-items-center tw-cursor-pointer"
											onClick={() =>
												removeSelectedEmployee(
													_data,
													false
												)
											}
										>
											<CloseOutlined />
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			) : type === 'invite-bulk-users' ? (
				isLoading.fetchTemplateUsers ? (
					<Skeleton
						width={100}
						paragraph={true}
						active={true}
						height={1}
						size="large"
					/>
				) : (
					<>
						<ul className="tw-list-none tw-p-0">
							{fetchTemplateUsers?.valid_users?.length > 0 ? <li className="tw-flex tw-items-center tw-justify-between tw-mb-[20px]">
								<div>
									Click 'Invite' to send event invitations to
									below users.
								</div>
								{/* <Button type="text">Select all</Button> */}
							</li> : null}
							{
								fetchTemplateUsers?.failure_count === 0 && fetchTemplateUsers.success_count > 0 ||
									fetchTemplateUsers?.failure_count > 0 && fetchTemplateUsers.success_count === 0 ||
									fetchTemplateUsers?.failure_count === 0 && fetchTemplateUsers.success_count === 0 && fetchTemplateUsers.status === false ?
									<>
										{
											general()
										}
									</> : null}
							{fetchTemplateUsers?.failure_count > 0 && fetchTemplateUsers.success_count > 0 ? <>
								{
									partialSuccess()
								}
							</> : null}


							{/* {usersFromTemplate?.length === 0 ? (
								<h3>No Valid Users</h3>
							) : null} */}
							{usersFromTemplate?.map((_data) => {
								return (
									<li
										key={_data.key}
										className={`tw-flex tw-mb-[24px] tw-h-[46px] ${_data.invited ? "tw-opacity-50" : ""}`}
									>
										<label className="tw-flex tw-justify-between tw-cursor-default tw-w-full">
											<div className="tw-h-full tw-w-[36px] tw-mr-[10px]  tw-flex tw-items-center tw-justify-center">
												{
													_data.profile_picture.icon_path ? <img
														src={_data.profile_picture.icon_path ? _data.profile_picture.icon_path : <OutlinedProfileIcon />}
														alt=""
														width={30}
														height={30}
														className="tw-mr-[-5px] tw-aspect-square tw-rounded-full"

													/> : <OutlinedProfileIcon size={60} />
												}

											</div>
											<div className="tw-flex-grow">
												<p className="tw-m-0">
													{titleCaseEveryWord(_data.name)}
												</p>
												<>
													<span className="tw-text-gray-500">
														{_data.email}
													</span>
													<p className='tw-text-red-600 tw-text-font-size-20 tw-m-0 tw-mb-2'>
														{_data.invited ? "Already invited" : ""}
													</p>
												</>
											</div>
										</label>
									</li>
								);
							})}
						</ul>
					</>
				)
			) : (
				<div className="tw-pt-4 tw-pb-8">
					{/* <p
						className="tw-typography-bodyLarge"
						style={{ fontWeight: '500' }}
					>
						Download the template
					</p>
					<p>
						Use this template with predefined columns and add
						participants you wish to invite
					</p> */}
					<Button
						type="default"
						onClick={() => handleTemplateDownload()}
						disabled={isLoading.bulkInviteTemplate ? true : false}
						title="Use this template with predefined column and add
						participants you wish to invite."
					>
						{isLoading.bulkInviteTemplate
							? 'Downloading template...'
							: 'Download template'}
					</Button>
					<hr className="tw-my-8" />
					{isLoading.signedUrlForUpload ? (
						<Skeleton
							width={100}
							paragraph={true}
							active={true}
							height={1}
							size="large"
						/>
					) : (
						<>
							<div>
								<p
									className="tw-typography-bodyLarge tw-m-0 tw-mb-2"
									style={{ fontWeight: '500' }}
								>
									Upload xlsx
								</p>
								{/* <p className='tw-m-0 tw-text-gray-500 tw-text-font-size-30'>
									Once you’ve filled out the file, upload it here
								</p> */}
							</div>
							<div className=' '>
								<Dropzone
									onDrop={(acceptedFiles) => {
										if (acceptedFiles.length > 0) {
											setFile(acceptedFiles[0])

										}
									}
									}
									accept={{
										// 'image/png': ['.png'],
										// 'text/html': ['.html', '.htm'],
										'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
									}}
									onDropRejected={rejectedFiles => rejectedFileHandler(rejectedFiles)}

									multiple={false}
									noDrag={false}
									disabled={false}
								// noClick={true}
								>
									{({ getRootProps, getInputProps }) => (
										<section className='container tw-flex tw-flex-col tw-items-center tw-border-dashed tw-rounded-lg tw-mt-4 tw-border-gray-300 tw-border-1 tw-width-100'>

											<div {...getRootProps({ className: 'dropzone tw-width-100', style: { width: "100%", padding: "4rem 0", display: "flex", flexDirection: "column", alignItems: "center" } })}>
												<UploadIcon className="tw-mb-2" />
												<input
													{...getInputProps()}
													accept=".xlsx"
													className='tw-width-100'
												/>
												{file === null ? (
													<div onClick={(e) => e.stopPropagation()}
														className='tw-flex tw-items-center tw-flex-col'>
														<div className='tw-y-8' style={{ marginTop: "1rem", marginBottom: "1rem", userSelect: "none" }}>
															Drag and drop your file here</div>

														<span>

															<span className='tw-my-1'>Or</span>
															<label
																{...getRootProps({ className: 'dropzone' })}
																className="dropZone file-upload-label"
																disabled={false}
															>
																{/* {uploadDetails.uploadButtonText !== "" ? uploadDetails.uploadButtonText : "Upload attachments"} */}
																<span className='tw-text-red-500 tw-cursor-pointer tw-ml-2'>Select a file </span>
															</label>
														</span>

													</div>
												) : (
													<div className='tw-flex tw-items-center'>
														<p className='tw-m-0 tw-mr-2 tw-my-4'>
															{file?.path}
														</p>
														{file ? (
															<CloseOutlined
																title='Remove attachment'
																onClick={
																	(e) => {
																		e.stopPropagation();
																		setFile(null);
																	}
																}
															>
																Remove file
															</CloseOutlined>
														) : null}
													</div>
												)}
											</div>

										</section>
									)}
								</Dropzone>
							</div>
							{/* <button
								onClick={handleFileUpload}
								disabled={uploadToS3 || file === null}
							>
								Upload file
							</button> */}
						</>
					)}
				</div>
			)
			}
		</>
	);
};

export default InviteParticipantsModal;
