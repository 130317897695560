import { useCallback, useEffect, useState } from "react";
import { Input } from "components/atoms";
import { Button } from "components/atoms";
import { Icon } from 'components/atoms/Icon';
import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import { AutoComplete, Dropdown, message, Pagination, Skeleton, Popover } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getEventListFromAPI, searchEvent, updateRSVPstatus } from "redux/features/Events/Events.slice";
import { Link } from "react-router-dom";
import moment from "moment";

import avatar from '../../../../../../assets/images/teamevents/avatar.png'

import LocationSearch from "./LocationSearch";
import { OutlinedProfileIcon } from "icons/ProfileIcon";
import { titleCase, titleCaseEveryWord } from "views/TeamEvents/pages/Event/utils";
import { NotAttendingIcon } from "icons/NotAttendingIcon";
import { AttendingIcon } from "icons/AttendingIcon";

const mockVal = (str, repeat = 1) => ({
	value: str.repeat(repeat),
});

const handleButtonClick = (e) => {
	message.info('Click on left button.');
};
const handleMenuClick = (e) => {
	message.info('Click on menu item.');
};
const items = [
	{
		label: 'Edit',
		key: '1',
		icon: <UserOutlined />,
	},
	{
		label: 'Add to calendar',
		key: '2',
		icon: <UserOutlined />,
	},
];
const menuProps = {
	items,
	onClick: handleMenuClick,
};

export const EventCard = (props) => {
	const { cardDetails, EventType } = props;

	const authReducer = useSelector(state => state.auth)
	const { userDetails } = authReducer;
	const [invititationStatus, setInvitationStatus] = useState({

	});
	const dispatch = useDispatch();

	let user_id = JSON.parse(window.localStorage.getItem("userDetails")).id;

	const dateFormatter = () => {

		const obj = {
			start_date_time: cardDetails.start_date_time,
			end_date_time: cardDetails.end_date_time,
			start_date_timezone: cardDetails.start_date_timezone,
			end_date_timezone: cardDetails.end_date_timezone
		};

		const startDateString = moment.utc(obj.start_date_time, moment.defaultFormatUtc).toString();
		const endDateString = moment.utc(obj.end_date_time, moment.defaultFormatUtc).toString();


		const startTimeString = moment.utc(startDateString.split(' ')[4], 'HH:mm:ss').format('hh:mm A');
		const endTimeString = moment.utc(endDateString.split(' ')[4], 'HH:mm:ss').format('hh:mm A');

		const timezoneString = obj.start_date_timezone;

		const correctStartDateString = `${startDateString.split(' ')[2]} ${startDateString.split(' ')[1]} ${startDateString.split(' ')[3]}`
		const correctEndDateString = `${endDateString.split(' ')[2]} ${endDateString.split(' ')[1]} ${endDateString.split(' ')[3]}`
		// const corectTimeZoneString = timezoneString.split(" ")[1];
		let corectTimeZoneString = [];
		let finalTimezone = timezoneString.split(" ");
		if (finalTimezone.length > 2) {
			let arr1 = finalTimezone[0] + " " + finalTimezone[1];
			let arr2 = finalTimezone[2];
			finalTimezone = [];
			finalTimezone.push(arr1)
			finalTimezone.push(arr2)
			corectTimeZoneString = finalTimezone[1];
		} else {
			corectTimeZoneString = finalTimezone[1];
		}
		const GMTTime = corectTimeZoneString.slice(0, 3) + ":" + corectTimeZoneString.slice(3);


		const formattedString = `${correctStartDateString} · ${startTimeString}  -  ${correctEndDateString} · ${endTimeString} ${finalTimezone[0]} (GMT ${GMTTime})`;

		return formattedString;

	};



	const handleRSVP = (e, status) => {

		// e.stopPropogations();
		dispatch(updateRSVPstatus({
			attending: status
		}, cardDetails.id, user_id));
	}

	const displayParticipantPreview = (data, total) => {

		let viewerInvited = false;
		let tempArr = [...data];
		let filterArr = tempArr.filter((_data, i) => {

			if (i < 4 && userDetails.id !== _data.id)
				return _data;
			else if (i <= 4 && userDetails.id === _data.id) {
				viewerInvited = true;
			}
		})
		let arr = filterArr.map((_data, i) => {

			if (i < 4)
				return titleCaseEveryWord(_data.name);
		})

		let string = arr.join(", ");

		if (arr.length < 4) {
			return `${viewerInvited ? "You" : ""} ${arr.length >= 1 && viewerInvited ? (", " + string) : ("" + string)} ${total > 4 ? `and ${total - 4} others` : ""} ${data.length === 1 ? `${viewerInvited ? "are" : ""} attending this event` : data.length !== 0 ? "are attending this event." : ""}`
		}
		else {
			return `${viewerInvited ? "You" : ""} ${arr.length >= 1 && viewerInvited ? (", " + string) : ("" + string)} and ${total - 4} ${data.length === 1 ? `${viewerInvited ? "are" : ""} attending this event` : data.length !== 0 ? "are attending this event." : ""}`
		}
		// if (arr.length < 4)
		// 	return `${viewerInvited ? "You, " : ""} ${string} ${total > 4 ? `and ${total - 4} others` : ""} ${data.length !== 0 ? "attending this event." : ""}`
		// else {

		// 	return `${viewerInvited ? "You, " : ""} ${string} and ${total - 4} ${data.length !== 0 ? "attending this event." : ""}`
		// }
		// You, Ramkumar Sundarakalatharan, Vincent buro, Angelo nick, Chun nuno  and 3333 more attending this event
	}

	const ImagePreview = (src) => {
		if (src.src) {
			return <img src={src.src} alt="" width={30} height={30} className="tw-mr-[-5px] tw-aspect-square tw-rounded-full" />
		}
		else {
			return <OutlinedProfileIcon size={38} className="tw-mr-[-5px] tw-aspect-square tw-rounded-full" />
		}
	}
	const profilePicPreview = (data) => {

		let tempArr = [...data.participant_preview]
		let filterArr = tempArr.filter((_data, i) => {

			if (i < 4)
				return _data
		})
		return filterArr

	}


	const tag = (text, type) => {
		return <span className={`tw-rounded-[6px] ${type === "green" ? "tw-bg-green-200 tw-text-[#1B7E49]" : "tw-bg-red-200 tw-text-red-800"} tw-pt-2 tw-pb-2 tw-pl-2 tw-pr-2 tw-user tw-select-none `}>
			{type === "green" ? <svg className="tw-mr-1" width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9.67569 0.264805C10.0828 0.637997 10.1103 1.27056 9.73712 1.67768L4.23712 7.67768C4.04215 7.89038 3.76481 8.00849 3.47636 8.00168C3.18791 7.99487 2.91646 7.8638 2.73174 7.64214L0.231743 4.64214C-0.121821 4.21787 -0.0644973 3.5873 0.35978 3.23374C0.784057 2.88017 1.41462 2.9375 1.76819 3.36177L3.53583 5.48294L8.26281 0.326234C8.636 -0.0808846 9.26857 -0.108388 9.67569 0.264805Z" fill="#1B7E49" />
			</svg>
				: null}{text}
		</span>
	}

	return <div className="tw-flex tw-flex-col tw-items-left">
		<div className="tw-mb-[40px] tw-border tw-border-solid tw-rounded-[12px] tw-border-[#b6bac3] tw-overflow-auto hover:tw-border-[#EB5C24] hover:tw-shadow-lg">
			<Link to={`/team-events/create-event?id=${cardDetails.id}`} className="tw-text-color-border-dark-hover hover:tw-text-color-border-dark-hover">

				<div className="tw-flex tw-relative sm:tw-flex-nowrap tw-flex-wrap">
					{cardDetails.invitation_status.status.toLowerCase() === "attending" ? <div className="tw-order-3 tw-absolute tw-right-[15px] tw-top-[15px] tw-block tw-w-auto">
						{tag("Attending", "green")}
					</div> : null}
					{cardDetails.invitation_status.status.toLowerCase() === "not attending" ? <div className="tw-order-3 tw-absolute tw-right-[15px] tw-top-[15px] tw-block tw-w-auto">
						{tag("Not attending", "red")}
					</div> : null}
					<div className="tw-overflow-hidden tw-order-1 sm:tw-hidden tw-w-full tw-flex">
						<img className="tw-w-full  " src="/images/teamevents/picture.svg" alt="" />
					</div>
					<div className="tw-overflow-hidden tw-order-1 tw-hidden sm:tw-flex">
						<img className="tw-h-full  " src="/images/teamevents/picture.svg" alt="" />
					</div>
					<div className="tw-p-5 tw-order-2">
						<div className='tw-m-0 tw-text-left tw-text-[black] tw-text-base tw-font-medium'>{cardDetails.name}</div>
						<p className='tw-m-0 tw-typography-body1 tw-text-left tw-mt-1 tw-text-[#6B7280]'>
							<Icon />
							<span className="tw-ml-[5px] tw-text-sm tw-font-normal">{dateFormatter()}</span>
						</p>
						<p className='tw-m-0 tw-mb-[32px] tw-text-left tw-typography-body2 tw-mb-[12px] tw-mt-1 tw-text-[#6B7280]'>
							<EnvironmentOutlined />
							<span className="tw-ml-[5px] tw-text-sm tw-font-normal">{cardDetails?.location.length > 60 ? <Popover content={cardDetails?.location} trigger="hover" placement="bottom" >{cardDetails?.location.slice(0, 59) + "..."}</Popover> : cardDetails?.location} &nbsp;
								<a href={`https://www.google.com/maps/search/?api=1&query=${cardDetails.latitude},${cardDetails.longitude}`} target="_blank" onClick={e =>
									e.stopPropagation()}
									rel="noopener noreferrer" className="tw-text-sm tw-font-normal">View map</a>
							</span>
						</p>
						{EventType === "upcoming" ? <div className="tw-mr-[10px] tw-flex tw-items-center">
							{profilePicPreview(cardDetails).map((_data) => {
								return <ImagePreview src={_data?.profile_picture?.icon_path} />
							})}
							{/* // 3333 */}
							{
								cardDetails.total_participants - 4 > 0 ? <div className="tw-bg-[#F1F3F5] tw-rounded-[40px] tw-border-[1.5px] tw-border-solid tw-border-white tw-px-4 tw-leading-8">{cardDetails.total_participants - 4}</div> : ""
							}
							<div className="tw-flex-grow tw-text-left tw-ml-4 tw-text-xs tw-font-normal">{displayParticipantPreview(cardDetails.participant_preview, cardDetails.total_participants)}</div>
						</div> : null}
					</div>
				</div>
			</Link>
			{cardDetails?.invitation_status?.status?.toLowerCase() === "invited" ? <div className='tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-p-4  tw-border-0 tw-border-t tw-border-solid tw-border-[#b6bac3]'>
				{cardDetails?.invitation_status?.status?.toLowerCase() === "invited" ? <>
					{
						<div className='tw-flex sm:tw-w-1/2  tw-w-full tw-items-center tw-text-xs tw-font-normal'>
							{
								cardDetails?.invitation_status?.invited_by.profile_picture.icon_path ? <img src={cardDetails?.invitation_status?.invited_by.profile_picture.icon_path ? cardDetails?.invitation_status?.invited_by.profile_picture.icon_path : avatar} height={30} width={30} alt="" className="tw-mr-2 tw-rounded-full" /> : <OutlinedProfileIcon size={38} />
							}
							{
								cardDetails?.invitation_status?.invited_by.id === userDetails.id ? "You created this event" : `${titleCaseEveryWord(cardDetails?.invitation_status?.invited_by?.name)} invited you`
							}
							{/* {titleCaseEveryWord(cardDetails?.invitation_status?.invited_by?.name)}  */}
						</div>
					}

					<div className='tw-flex sm:tw-w-1/2 tw-w-full tw-flex-row-reverse'>
						<Button size="large" type='default' className='tw-ml-[10px]' onClick={(e) => handleRSVP(e, false)}><NotAttendingIcon size="17" color="#e95d25" className="tw-pt-1 tw-mr-1"/> Not Attending</Button>
						<Button size="large" type='default' onClick={(e) => handleRSVP(e, true)}><AttendingIcon size="17" color="#e95d25" className="tw-pt-1 tw-mr-1"/>Attending</Button>
					</div>
				</> : null}
			</div> : null}
		</div>
	</div >;
};

const EventDescription = (props) => {

	const { eventBanner, setEventBanner, type: EventType } = props

	let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

	const dispatch = useDispatch();
	const eventData = useSelector(state => state.events);
	const authData = useSelector(state => state.auth);
	const { listOfUpcomingEvents, RSVPstatus, listOfPastEvents, isLoading } = eventData;
	const { userDetails } = authData
	const [searchInput, setSearchInput] = useState("");

	const [paginationData, setPaginationData] = useState({
		data: [],
		totalPage: 0,
		current: 1,
		minIndex: 0,
		maxIndex: 0,
		pageSize: 10,
	});
	useEffect(() => {
		// fetchEventList()
		if (userDetails && listOfUpcomingEvents && listOfPastEvents) {

			dispatch(getEventListFromAPI("null", props.type, false));
		}
		else {
			dispatch(getEventListFromAPI("null", props.type, true));
		}
	}, [props.type, userDetails])

	useEffect(() => {
		if (RSVPstatus?.status) {
			dispatch(getEventListFromAPI(null, props.type, false))
		}
	}, [RSVPstatus])

	useEffect(() => {
		if (listOfUpcomingEvents) {
			setPaginationData({

			})
		}
	}, [listOfUpcomingEvents])

	const handleEventSearch = (e) => {
		setSearchInput(e.target.value);
	}

	useEffect(() => {
		setSearchInput("")
	}, [])

	// useEffect(() => {
	// 	if (type === "upcoming") {

	// 	}
	// 	if (type === "past") {

	// 	}
	// }, [listOfUpcomingEvents, listOfPastEvents])


	const debounce = (func) => {
		let timer;
		return function (...args) {
			const context = this;
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				func.apply(context, args);
			}, 300);
		};
	};

	const searchevent = (value) => {
		dispatch(searchEvent(props.type, value));
	};
	const optimizedFn = useCallback(debounce(searchevent), []);

	const handlePaginationChange = () => { }
	const handleShowSizeChange = (event, pageSize) => {
		console.log(event);
	};

	return (
		<div>
			<Input
				value={searchInput}
				onChange={(e) => {
					handleEventSearch(e, "null", "eventName");
					optimizedFn(e.target.value);
				}}
				prefix={isFirefox ? "" : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8 2.00098C4.68629 2.00098 2 4.68727 2 8.00098C2 11.3147 4.68629 14.001 8 14.001C11.3137 14.001 14 11.3147 14 8.00098C14 4.68727 11.3137 2.00098 8 2.00098ZM0 8.00098C0 3.5827 3.58172 0.000976562 8 0.000976562C12.4183 0.000976562 16 3.5827 16 8.00098C16 9.84969 15.3729 11.5519 14.3199 12.9066L19.7071 18.2939C20.0976 18.6844 20.0976 19.3176 19.7071 19.7081C19.3166 20.0986 18.6834 20.0986 18.2929 19.7081L12.9056 14.3208C11.551 15.3739 9.84871 16.001 8 16.001C3.58172 16.001 0 12.4193 0 8.00098Z" fill="#6B7280" />
				</svg>
				}
				placeholder='Search event name or location'
				name="search-based-on-name-location"
				className="tw-mb-4"
			/>
			{
				isLoading.searchEvent ? <p className="tw-text-gray-700">Loading..</p> : null
			}
			{props.type === "upcoming" ? <>
				{
					isLoading.eventList ? <>
						<Skeleton
							width={100}
							paragraph={true}
							active={true}
							height={1}
							size='large'
						/><Skeleton
							width={100}
							paragraph={true}
							active={true}
							height={1}
							size='large'
						/><Skeleton
							width={100}
							paragraph={true}
							active={true}
							height={1}
							size='large'
						/></> : <>
						{
							Array.isArray(listOfUpcomingEvents?.events) ? listOfUpcomingEvents?.events?.map((_data, i) => {
								return (
									<EventCard EventType={EventType} cardDetails={_data} key={i} />
								);
							}) : null
						}
						{/* <Pagination
							defaultCurrent={1}
							pageSize={paginationData.pageSize}
							current={paginationData.current}
							total={paginationData.data.length}
							onChange={handlePaginationChange}
							style={{ bottom: '0px' }}
							pageSizeOptions={[5, 10, 20, 50, 100]}
							onShowSizeChange={handleShowSizeChange}
						/> */}
						{
							listOfUpcomingEvents?.events?.length === 0 && !isLoading.searchEvent ? <div>
								<img
									className="tw-block tw-max-w-[467px] tw-mb-14 tw-m-auto"
									src="/images/teamevents/banner.svg"
									alt=""
								/>
								<h2 className="tw-text-gray-600">No event found</h2>
							</div> : null
						}
					</>
				}
			</> : <>
				{
					isLoading.eventList ? <>
						<Skeleton
							width={100}
							paragraph={true}
							active={true}
							height={1}
							size='large'
						/><Skeleton
							width={100}
							paragraph={true}
							active={true}
							height={1}
							size='large'
						/><Skeleton
							width={100}
							paragraph={true}
							active={true}
							height={1}
							size='large'
						/>
					</> : <>
						{
							Array.isArray(listOfPastEvents?.events) ? listOfPastEvents?.events?.map((_data, i) => {
								return (
									<EventCard EventType={EventType} cardDetails={_data} key={i} />
								);

							}) : null
						}
						{/* <Pagination
							defaultCurrent={1}
							pageSize={paginationData.pageSize}
							current={paginationData.current}
							total={paginationData.data.length}
							onChange={handlePaginationChange}
							style={{ bottom: '0px' }}
							pageSizeOptions={[5, 10, 20, 50, 100]}
							onShowSizeChange={handleShowSizeChange}
						/> */}
						{
							listOfPastEvents?.events?.length === 0 && !isLoading.searchEvent ? <div>
								<img
									className="tw-block tw-max-w-[467px] tw-mb-14 tw-m-auto"
									src="/images/teamevents/banner.svg"
									alt=""
								/>
								<h2 className="tw-text-gray-600">No event found</h2>
							</div>
								: null
						}

					</>
				}
			</>}
		</div>
	);
};

export { EventDescription, LocationSearch };