import * as React from 'react';

export const Admin = React.memo(({ size = 24, status = 'default', ...rest }) => {
    let fillColor = '#6B7280';
	if (status === 'active') {
		fillColor = '#111827';
	}
	if (status === 'disabled') {
		fillColor = '#B6BAC3';
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 24 24"
			{...rest}
		>
			<path
				fill={fillColor}
				fillRule="evenodd"
				d="M12.494 3.185a.75.75 0 00-.988 0v.001l-.008.006-.03.026c-.027.024-.07.059-.124.104a20.651 20.651 0 01-2.146 1.54C7.81 5.728 6.15 6.501 4.75 6.501a.75.75 0 00-.75.75v.167c0 3.475 0 6.061.977 8.15 1.024 2.19 3.049 3.723 6.716 5.367 0 0 .24.137.409.183-.552-.867-.712-1.317-.92-2.079-2.804-1.382-4.146-2.609-4.846-4.106-.767-1.638-.83-3.703-.836-6.982 1.618-.211 3.257-1.046 4.49-1.813A22.148 22.148 0 0012 4.72a21.663 21.663 0 002.01 1.416c1.233.767 2.872 1.602 4.49 1.813-.002 1.176-.01 2.202-.059 3.12.02-.236-.013.25 0 0 .659.09.902.19 1.48.393-.01.158.013-.152 0 0C20 10.241 20 8.855 20 7.277V7.25a.75.75 0 00-.75-.75c-1.401 0-3.06-.773-4.447-1.637a20.635 20.635 0 01-2.27-1.645l-.031-.026-.007-.006h-.001z"
				clipRule="evenodd"
			></path>
			<path
				fill={fillColor}
				fillRule="evenodd"
				d="M12.837 16.475a2 2 0 001.441-2.496l-.198-.686c.44-.383.94-.693 1.483-.913l.499.525a2 2 0 002.899 0l.493-.518a5.28 5.28 0 011.484.922l-.186.63a2 2 0 001.45 2.51l.539.13a5.722 5.722 0 01.006 1.808l-.584.145a2 2 0 00-1.44 2.496l.197.686c-.439.382-.939.692-1.482.913l-.5-.525a2 2 0 00-2.898 0l-.493.518a5.28 5.28 0 01-1.485-.922l.187-.63a2 2 0 00-1.45-2.511l-.54-.13a5.72 5.72 0 01-.006-1.807l.584-.145zm6.113 1.028c0 .829-.65 1.5-1.45 1.5-.8 0-1.45-.671-1.45-1.5 0-.828.65-1.5 1.45-1.5.8 0 1.45.672 1.45 1.5z"
				clipRule="evenodd"
			></path>
		</svg>
	);
})

Admin.displayName = "Admin"