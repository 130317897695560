import routeURLs from '../utils/urls/routeUrls';
import { appRoutes } from 'auth/appRouteConfig';

function routeDefaultRedirect(route) {
	let returnRoute = '';
	switch (route) {
		case appRoutes.createTrip:
			returnRoute = routeURLs.createTrip.default;
			break;
		case appRoutes.teamEvents:
			returnRoute = routeURLs.teamEvents.default;
			break;
		default:
			returnRoute = null;
			break;
	}
	return returnRoute;
}

export { routeDefaultRedirect };
