import { ParticipantListEdit, EventParticipantsSmall } from "./sections";

const Participants = (props) => {


	return(
		<>
			<EventParticipantsSmall 
				individualEventDetails={props.individualEventDetails} 
				eventId={props.eventId} 
				typeOfParticipantsView={props.typeOfParticipantsView}
				setTypeOfParticipantsView={props.setTypeOfParticipantsView}
				appliedFilter={props.appliedFilter}
				setAppliedFilter={props.setAppliedFilter}
			/>
			<ParticipantListEdit 
				individualEventDetails={props.individualEventDetails} 
				eventId={props.eventId} 
				typeOfParticipantsView={props.typeOfParticipantsView}
				setTypeOfParticipantsView={props.setTypeOfParticipantsView}
				appliedFilter={props.appliedFilter}
				setAppliedFilter={props.setAppliedFilter}
			/>
		</>
	);
};

export default Participants;