import { DatePicker as AntDatePicker } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import classes from './DatePicker.module.scss';
import * as React from 'react';
dayjs.extend(customParseFormat);

const DatePickerComp = (props) => {
	const {
		className = '',
		dateFormat = 'DD MMM YYYY',
		value = '',
		onChange = () => {},
		...rest
	} = props;
	const customFormat = (value) => `${value.format(dateFormat)}`;
	return (
		<AntDatePicker
			className={clsx(classes.datepicker, className)}
			defaultValue={dayjs('18 Oct, 2022', dateFormat)}
			value={value ? dayjs(value, dateFormat) : null}
			format={customFormat}
			onChange={onChange}
			suffixIcon={null}
			dateRender={(current) => {
				return (
					<div className="ant-picker-cell-inner">
						{current.date()}
					</div>
				);
			}}
			{...rest}
		/>
	);
};

DatePickerComp.propTypes = {
	dateFormat: PropTypes.string,
	value: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
};

export const DatePicker = React.memo(DatePickerComp);