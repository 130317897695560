import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './features/Auth/Auth.slice';
import EventsSlice from './features/Events/Events.slice';

const store = configureStore({
	reducer: {
		events: EventsSlice,
		auth: AuthSlice,
	},
});

export { store };
