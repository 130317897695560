import { Button as AntButton } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './Button.module.scss';
import * as React from 'react';

const ButtonComp = (props) => {
	const {
		type = 'primary',
		className = '',
		children,
		onClick = () => {},
		...rest
	} = props;
	return (
		<AntButton
			className={clsx(classes.button, className)}
			onClick={onClick}
			type={type}
			{...rest}
		>
			{children}
		</AntButton>
	);
};

ButtonComp.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export const Button = React.memo(ButtonComp)