import { Tooltip as AntTooltip } from 'antd';
import PropTypes from 'prop-types';
import * as React from 'react';

const TooltipComp = (props) => {
	const {
		children,
		title = 'test',
		placement = 'top',
		color = '#1F2937',
		className,
		...rest
	} = props;
	return (
		<AntTooltip
			className={className}
			color={color}
			title={title}
			placement={placement}
			{...rest}
		>
			{children}
		</AntTooltip>
	);
};

TooltipComp.propTypes = {
	title: PropTypes.string,
	placement: PropTypes.string,
	color: PropTypes.string,
};

export const Tooltip = React.memo(TooltipComp);
