import * as React from 'react';

export const CheckInBaggage = React.memo(({ size = 16, color = '#6b7280', className, ...rest }) => {
    return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 16 16"
			className={className}
			{...rest}
		>
			<path
				fill={color}
				fillRule="evenodd"
				d="M6 4V2C6 1.44772 6.44772 1 7 1H9C9.55228 1 10 1.44772 10 2V4H11.5C12.3284 4 13 4.67157 13 5.5V12.5C13 13.3284 12.3284 14 11.5 14H11V14.5C11 15.0523 10.5523 15.5 10 15.5C9.44771 15.5 9 15.0523 9 14.5V14H7V14.5C7 15.0523 6.55228 15.5 6 15.5C5.44772 15.5 5 15.0523 5 14.5V14H4.5C3.67157 14 3 13.3284 3 12.5V5.5C3 4.67157 3.67157 4 4.5 4H6ZM7 2V4H9V2H7Z"
			></path>
		</svg>
	);
})

CheckInBaggage.displayName = "CheckInBaggage";