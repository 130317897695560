import * as React from 'react';

export const StarRating = React.memo(({ size = 16, color = '#6b7280', className, ...rest }) => (
	<svg
		width="10"
		height="8"
		viewBox="0 0 10 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M2.92417 7.89072C2.54589 8.11891 2.07921 7.78032 2.17877 7.3499L2.65716 5.28178C2.6989 5.10135 2.63748 4.91262 2.49755 4.79131L0.892488 3.39976C0.558723 3.1104 0.736699 2.56205 1.17678 2.52385L3.3206 2.33775C3.49415 2.32269 3.64735 2.21838 3.72497 2.06244L4.55239 0.400218C4.73636 0.0306326 5.26364 0.0306319 5.44761 0.400218L6.27503 2.06244C6.35265 2.21838 6.50585 2.32269 6.6794 2.33775L8.82322 2.52385C9.2633 2.56205 9.44127 3.1104 9.10751 3.39976L7.50245 4.79131C7.36252 4.91263 7.3011 5.10135 7.34284 5.28178L7.82123 7.3499C7.92079 7.78032 7.45411 8.11891 7.07583 7.89072L5.25827 6.7943C5.09942 6.69847 4.90058 6.69847 4.74173 6.7943L2.92417 7.89072Z"
			fill={color}
		/>
	</svg>
));

StarRating.displayName = "StarRating";