import { apiUrls } from 'utils/urls/apiUrls';
import LocalStorageService from '../services/LocalStorage';

const { default: axios } = require('axios');
const moment = require('moment');

async function validateAccessToken() {
	if (localStorage.getItem('token')) {
		let tokenObj = JSON.parse(localStorage.getItem('token'));
		let accessToken = tokenObj.access;
		let exp = tokenObj.acc_exp_in;

		let currTimeUnix = moment().unix(); // secs

		if (currTimeUnix >= exp) {
			let refreshToken = tokenObj.refresh;
			let resfreshTokenExp = tokenObj.ref_exp_in;
			const data = {
				refresh: refreshToken,
			};

			if (currTimeUnix <= resfreshTokenExp) {
				await axios
					.post(apiUrls.AUTH.FETCH_REFRESH_TOKEN, data)
					.then((res) => {
						const _token = res.data.token;
						accessToken = _token.access;
						LocalStorageService.setAccessToken(_token.access);
						LocalStorageService.setRefreshToken(_token.refresh);
						localStorage.setItem('token', JSON.stringify(_token));
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				LocalStorageService.removeAllToken();
				window.location.href = process.env.REACT_APP_LOGIN_URL;
			}
		}
		return accessToken;
	}
	LocalStorageService.removeAllToken();
	window.location.href = process.env.REACT_APP_LOGIN_URL;
}

export { validateAccessToken };
