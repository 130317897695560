import React from 'react';

function TravelIcon({ status = 'default' }) {
	let fillColor = '#6B7280';
	if (status === 'active') {
		fillColor = '#111827';
	}
	if (status === 'disabled') {
		fillColor = '#B6BAC3';
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill={fillColor}
				fillRule="evenodd"
				d="M10.5 3.5V7h3V3.5h-3zM15 7V3a1 1 0 00-1-1h-4a1 1 0 00-1 1v4H8a3 3 0 00-3 3v8c0 1.398.956 2.573 2.25 2.905v.345a.75.75 0 001.5 0V21h6.5v.25a.75.75 0 001.5 0v-.345A3.001 3.001 0 0019 18v-8a3 3 0 00-3-3h-1zM8 8.5A1.5 1.5 0 006.5 10v8A1.5 1.5 0 008 19.5h8a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0016 8.5H8zM9.75 10a.75.75 0 01.75.75v6.5a.75.75 0 01-1.5 0v-6.5a.75.75 0 01.75-.75zm4.5 0a.75.75 0 01.75.75v6.5a.75.75 0 01-1.5 0v-6.5a.75.75 0 01.75-.75z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}
function ExpenseIcon({ status = 'default' }) {
	let fillColor = '#6B7280';
	if (status === 'active') {
		fillColor = '#111827';
	}
	if (status === 'disabled') {
		fillColor = '#B6BAC3';
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill={fillColor}
				fillRule="evenodd"
				d="M4.403 4.085a.75.75 0 01.775.05l1.01.701 1.009-.702a.75.75 0 01.856 0l1.01.702 1.009-.702a.75.75 0 01.856 0l1.01.702 1.009-.702a.75.75 0 01.856 0l1.01.702 1.009-.702A.75.75 0 0117 4.75V14h2.25a.75.75 0 01.75.75v4A2.25 2.25 0 0117.75 21H7a3 3 0 01-3-3V4.75a.75.75 0 01.403-.665zM15.628 19.5H7A1.5 1.5 0 015.5 18V6.185l.26.18c.257.18.598.18.856 0l1.009-.701 1.01.702c.257.179.598.179.856 0l1.009-.702 1.01.702c.257.179.598.179.856 0l1.009-.702 1.01.702c.257.179.598.179.856 0l.259-.18V18.75c0 .263.045.515.128.75zm2.122 0a.75.75 0 00.75-.75V15.5H17v3.25c0 .414.336.75.75.75zM7 9.25a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5h-5.5A.75.75 0 017 9.25zm0 3.5a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5h-5.5a.75.75 0 01-.75-.75zm0 3.5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}

function AdminIcon({ status = 'default' }) {
	let fillColor = '#6B7280';
	if (status === 'active') {
		fillColor = '#111827';
	}
	if (status === 'disabled') {
		fillColor = '#B6BAC3';
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill={fillColor}
				d="M12.012 2.25c.734.008 1.465.093 2.182.253a.75.75 0 01.582.649l.17 1.527a1.384 1.384 0 001.927 1.116l1.401-.615a.75.75 0 01.85.174 9.792 9.792 0 012.204 3.792.75.75 0 01-.271.825l-1.242.916a1.381 1.381 0 000 2.226l1.243.915a.75.75 0 01.272.826 9.797 9.797 0 01-2.204 3.792.75.75 0 01-.848.175l-1.407-.617a1.38 1.38 0 00-1.926 1.114l-.169 1.526a.75.75 0 01-.572.647 9.518 9.518 0 01-4.406 0 .75.75 0 01-.572-.647l-.168-1.524a1.382 1.382 0 00-1.926-1.11l-1.406.616a.75.75 0 01-.849-.175 9.798 9.798 0 01-2.204-3.796.75.75 0 01.272-.826l1.243-.916a1.38 1.38 0 000-2.226l-1.243-.914a.75.75 0 01-.271-.826 9.793 9.793 0 012.204-3.792.75.75 0 01.85-.174l1.4.615a1.387 1.387 0 001.93-1.118l.17-1.526a.75.75 0 01.583-.65c.717-.159 1.45-.243 2.201-.252zm0 1.5a9.135 9.135 0 00-1.354.117l-.109.977A2.886 2.886 0 016.525 7.17l-.898-.394a8.293 8.293 0 00-1.348 2.317l.798.587a2.881 2.881 0 010 4.643l-.799.588c.32.842.776 1.626 1.348 2.322l.905-.397a2.882 2.882 0 014.017 2.318l.11.984c.889.15 1.798.15 2.687 0l.11-.984a2.881 2.881 0 014.018-2.322l.905.396a8.296 8.296 0 001.347-2.318l-.798-.588a2.881 2.881 0 010-4.643l.796-.587a8.293 8.293 0 00-1.348-2.317l-.896.393a2.884 2.884 0 01-4.023-2.324l-.11-.976a8.988 8.988 0 00-1.333-.117zM12 8.25a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zm0 1.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
			></path>
		</svg>
	);
}
function MenuExpand() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="12"
			fill="none"
			viewBox="0 0 21 12"
		>
			<path
				fill="#6B7280"
				fillRule="evenodd"
				d="M0 .75A.75.75 0 01.75 0h13.5a.75.75 0 010 1.5H.75A.75.75 0 010 .75zm17.22 1.97a.75.75 0 011.06 0l2.25 2.25a.75.75 0 010 1.06l-2.25 2.25a.75.75 0 11-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 010-1.06zM0 5.75A.75.75 0 01.75 5h13.5a.75.75 0 010 1.5H.75A.75.75 0 010 5.75zm0 5A.75.75 0 01.75 10h13.5a.75.75 0 010 1.5H.75a.75.75 0 01-.75-.75z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}

function HelpIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<path
				fill="#6B7280"
				d="M20 10c5.523 0 10 4.478 10 10s-4.477 10-10 10-10-4.478-10-10 4.477-10 10-10zm0 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333zM20 23.5a1 1 0 110 2 1 1 0 010-2zm0-8.75a2.75 2.75 0 012.75 2.75c0 1.01-.297 1.574-1.051 2.359l-.169.171c-.622.622-.78.886-.78 1.47a.75.75 0 01-1.5 0c0-1.01.297-1.574 1.051-2.359l.169-.171c.622-.622.78-.886.78-1.47a1.25 1.25 0 00-2.494-.128l-.006.128a.75.75 0 01-1.5 0A2.75 2.75 0 0120 14.75z"
			></path>
		</svg>
	);
}

function NotificationIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<path
				fill="#6B7280"
				d="M19.976 10.247a7.49 7.49 0 017.495 7.25l.005.25v4.097L28.855 25a1.25 1.25 0 01-1.146 1.751l-4.735.002a3 3 0 01-5.995.176l-.005-.179-4.725.001a1.25 1.25 0 01-1.146-1.748l1.372-3.158v-4.098c0-4.155 3.351-7.5 7.5-7.5zm1.499 16.503l-3 .003a1.5 1.5 0 002.994.144l.006-.147zm-1.5-15.003c-3.32 0-6 2.675-6 6v4.41l-1.343 3.094h14.696l-1.352-3.093V17.76l-.004-.225a5.988 5.988 0 00-5.997-5.788z"
			></path>
		</svg>
	);
}
function ProfileIcon({ img, className }) {
	return (
		<img
			src={img}
			alt="Profile Icon"
			className={className}
			height={24}
			width={24}
		/>
	);
}
function HamIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="#000"
				fillRule="evenodd"
				d="M2 6.75A.75.75 0 012.75 6h16.5a.75.75 0 010 1.5H2.75A.75.75 0 012 6.75zm0 5a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75zm0 5a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}
export {
	TravelIcon,
	ExpenseIcon,
	AdminIcon,
	MenuExpand,
	HelpIcon,
	NotificationIcon,
	ProfileIcon,
	HamIcon,
};
