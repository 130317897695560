import * as React from 'react';

export const HandBaggage = React.memo(({ size = 16, color = '#6b7280', className, ...rest }) => {
    return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 16 16"
			className={className}
			{...rest}
		>
			<path
				fill={color}
				fillRule="evenodd"
				d="M6 7V2C6 1.44772 6.44772 1 7 1H9C9.55228 1 10 1.44772 10 2V7H10.5C11.3284 7 12 7.67157 12 8.5V12.5C12 13.1531 11.5826 13.7087 11 13.9146V14.5C11 15.0523 10.5523 15.5 10 15.5C9.44771 15.5 9 15.0523 9 14.5V14H7V14.5C7 15.0523 6.55228 15.5 6 15.5C5.44772 15.5 5 15.0523 5 14.5V13.9146C4.4174 13.7087 4 13.1531 4 12.5V8.5C4 7.67157 4.67157 7 5.5 7H6ZM7 2V7H9V2H7Z"
			></path>
		</svg>
	);
})

HandBaggage.displayName = "HandBaggage";