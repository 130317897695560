import * as React from 'react';

export const BusIcon = React.memo(({ size = 24, color = '#6B7280', className, ...rest }) => {
    return (
        <svg width={size}
            height={size}
            {
            ...rest
            }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M21 8H21.25C21.9404 8 22.5 8.55964 22.5 9.25V10.5C22.5 11.3284 21.8284 12 21 12V8Z" fill="black" />
            <path d="M1.5 9.25C1.5 8.55964 2.05964 8 2.75 8H3V12C2.17157 12 1.5 11.3284 1.5 10.5V9.25Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.5C7.95776 3.5 6.25906 4.4324 5.66593 4.89169C5.58778 4.9522 5.5 5.09643 5.5 5.39531V10.5C5.5 11.3284 6.17157 12 7 12H17C17.8284 12 18.5 11.3284 18.5 10.5V5.39531C18.5 5.09643 18.4122 4.9522 18.3341 4.89169C17.7409 4.4324 16.0422 3.5 12 3.5ZM20 17C20 18.4865 18.9189 19.7205 17.5 19.9585V20.75C17.5 21.4404 16.9404 22 16.25 22C15.5596 22 15 21.4404 15 20.75V20H9V20.75C9 21.4404 8.44036 22 7.75 22C7.05964 22 6.5 21.4404 6.5 20.75V19.9585C5.08114 19.7205 4 18.4865 4 17V5.39531C4 4.85082 4.15887 4.16153 4.74756 3.70569C5.67801 2.9852 7.73366 2 12 2C16.2663 2 18.322 2.9852 19.2524 3.70569C19.8411 4.16153 20 4.85082 20 5.39531V17ZM5.5 13.0987V17C5.5 17.8284 6.17157 18.5 7 18.5H17C17.8284 18.5 18.5 17.8284 18.5 17V13.0987C18.0587 13.3539 17.5464 13.5 17 13.5H7C6.45357 13.5 5.94126 13.3539 5.5 13.0987Z" fill={color} />
            <path d="M7 16C7 15.4477 7.44772 15 8 15C8.55228 15 9 15.4477 9 16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16Z" fill="black" />
            <path d="M15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16Z" fill="black" />
        </svg>




    );
})
BusIcon.displayName = "BusIcon";