import { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import URLs from '../utils/urls/routeUrls';
import 'react-calendar/dist/Calendar.css';
import AuthRoute from './AuthRoutes/Authentication';
import { validateAccessToken } from 'auth/validateToken';
import LocalStorageService from 'services/LocalStorage';
import { apiUrls } from 'utils/urls/apiUrls';
import axios from 'axios';
import { routeDefaultRedirect } from 'auth/routeDefaultRedirects';
import RouteAllowed from 'auth/allowedAppforRoles';
import { appRoutes } from 'auth/appRouteConfig';

import Events from 'views/TeamEvents/pages/Events';
import EventPage from 'views/TeamEvents/pages/Event';
import { useDispatch } from 'react-redux';
import {
	getUserDetailsAuth,
	setLoadingStateAuthenticateUser,
} from 'redux/features/Auth/Auth.slice';

function AppRoutes(props) {
	const dispatch = useDispatch();
	const { getAppRoute } = props;
	const [isInitCheck, setInitCheck] = useState(false);

	useEffect(() => {
		(async () => {
			if (LocalStorageService.getAccessToken()) {
				let accessToken = await validateAccessToken().then((r) => r);

				if (!isInitCheck) {
					dispatch(setLoadingStateAuthenticateUser(true));
					axios
						.post(
							apiUrls.AUTH.FETCH_VERIFY_TOKEN,
							{},
							{
								headers: {
									Authorization: `Bearer ${accessToken}`,
								},
							}
						)
						.then((res) => {
							if (res.status === 200) {
								setInitCheck(true);
								localStorage.setItem(
									'userDetails',
									JSON.stringify(res.data)
								);
								dispatch(getUserDetailsAuth(res.data));
								dispatch(
									setLoadingStateAuthenticateUser(false)
								);
								let app_route = routeDefaultRedirect(
									localStorage.getItem('app')
								);

								return <Navigate to={`/team-events`} />;
							}
						})
						.catch((err) => {
							if (err.response && err.response.status === 401) {
								LocalStorageService.removeAllToken();
								localStorage.removeItem('token');
							}
						});
				}
			}
		})();

		return () => {};
	}, [isInitCheck]);
	return (
		<Suspense fallback={<p>Loading ...</p>}>
			<Routes>
				<Route
					exact
					path="/auth/:token/:role/:approute"
					element={<AuthRoute setInitCheck={setInitCheck} />}
				/>
				<Route path={URLs.home} element={<Home />} />
				<Route
					path={'/test-route'}
					element={
						<RouteAllowed
							route={appRoutes.teamEvents}
							getAppRoute={getAppRoute}
						>
							<div>{'Private Route'}</div>
						</RouteAllowed>
					}
				/>
				<Route
					path={URLs.teamEvents.default}
					// element={<Events />}
					element={
						<RouteAllowed
							route={appRoutes.teamEvents}
							getAppRoute={getAppRoute}
						>
							<div>
								<Events />
							</div>
						</RouteAllowed>
					}
				/>
				<Route
					path={URLs.teamEvents.eventpage}
					// element={<EventPage />}
					element={
						<RouteAllowed
							route={appRoutes.teamEvents}
							getAppRoute={getAppRoute}
							thisUrlCanBeSentViaEmail={true}
						>
							<div>
								<EventPage />
							</div>
						</RouteAllowed>
					}
				/>
			</Routes>
		</Suspense>
	);
}

export default AppRoutes;

// temporary Home component, will be removed once Homepage comes
const Home = () => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate('/team-events');
	}, []);
	return <div></div>;
};
