import * as React from 'react';

export const NotAttendingIcon = React.memo(({ size = 24, color = '#EC5D25', className, ...rest }) => {
    return (
        <span className={className}>
        <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1ZM3.83859 3.96569L3.89645 3.89645C4.07001 3.72288 4.33944 3.7036 4.53431 3.83859L4.60355 3.89645L6 5.293L7.39645 3.89645C7.57001 3.72288 7.83944 3.7036 8.03431 3.83859L8.10355 3.89645C8.27712 4.07001 8.2964 4.33944 8.16141 4.53431L8.10355 4.60355L6.707 6L8.10355 7.39645C8.27712 7.57001 8.2964 7.83944 8.16141 8.03431L8.10355 8.10355C7.92999 8.27712 7.66056 8.2964 7.46569 8.16141L7.39645 8.10355L6 6.707L4.60355 8.10355C4.42999 8.27712 4.16056 8.2964 3.96569 8.16141L3.89645 8.10355C3.72288 7.92999 3.7036 7.66056 3.83859 7.46569L3.89645 7.39645L5.293 6L3.89645 4.60355C3.72288 4.42999 3.7036 4.16056 3.83859 3.96569L3.89645 3.89645L3.83859 3.96569Z" fill={color}/>
        </svg>
        </span>
    );
})

NotAttendingIcon.displayName = "NotAttendingIcon";