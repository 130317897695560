import * as React from 'react';

export const ChevronLeftTopBar = React.memo(() => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="44"
			height="48"
			viewBox="0 0 44 48"
			fill="none"
		>
			<g filter="url(#filter0_dd_2444_138732)">
				<rect x="4" y="4" width="32" height="32" rx="16" fill="white" />
				<path
					d="M22.7071 25.7071C23.0976 25.3166 23.0976 24.6834 22.7071 24.2929L18.4142 20L22.7071 15.7071C23.0976 15.3166 23.0976 14.6834 22.7071 14.2929C22.3166 13.9024 21.6834 13.9024 21.2929 14.2929L16.2929 19.2929C15.9024 19.6834 15.9024 20.3166 16.2929 20.7071L21.2929 25.7071C21.6834 26.0976 22.3166 26.0976 22.7071 25.7071Z"
					fill="#6B7280"
				/>
			</g>
			<defs>
				<filter
					id="filter0_dd_2444_138732"
					x="-4"
					y="0"
					width="48"
					height="48"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1" />
					<feGaussianBlur stdDeviation="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.1 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2444_138732"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="4" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.15 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_dropShadow_2444_138732"
						result="effect2_dropShadow_2444_138732"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect2_dropShadow_2444_138732"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
});

ChevronLeftTopBar.displayName = 'ChevronLeftTopBar';
