import { Skeleton as AntSkeleton } from 'antd';
import PropTypes from 'prop-types';
import classes from './Skeleton.module.scss';
import * as React from 'react';
import clsx from 'clsx';

const SkeletonComp = (props) => {
	const {
		width = 10,
		paragraph = false,
		active = true,
		height = 16,
		size = 'small',
		className,
		...rest
	} = props;
	return (
		<AntSkeleton
			className={clsx(classes.skeleton, className)}
			style={{ width: width + '%', height: height, marginBottom: 5 }}
			paragraph={paragraph}
			size={size}
			active={active}
			{...rest}
		/>
	);
};

SkeletonComp.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	size: PropTypes.string,
	active: PropTypes.bool,
	paragraph: PropTypes.bool,
};

export const Skeleton = React.memo(SkeletonComp);
