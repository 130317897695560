const routeURLs = {
	home: '/',
	createTrip: {
		default: '',
		packaging: '',
		confirmation: '',
	},
	teamEvents: {
		default: '/team-events',
		eventpage: '/team-events/create-event',
	},
};

export default routeURLs;
