import * as React from 'react';

export const Alert = React.memo(({ size = 12, color = '#6b7280', className, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 12 12"
			className={className}
			{...rest}
		>
			<path
				fill={color}
				fillRule="evenodd"
				d="M6 11.1439C3.15968 11.1439 0.857143 8.84132 0.857143 6.001C0.857143 3.16068 3.15968 0.858142 6 0.858142C8.84032 0.858142 11.1429 3.16068 11.1429 6.001C11.1429 8.84132 8.84032 11.1439 6 11.1439ZM6 12.001C9.31371 12.001 12 9.31471 12 6.001C12 2.68729 9.31371 0.000999451 6 0.000999451C2.68629 0.000999451 0 2.68729 0 6.001C0 9.31471 2.68629 12.001 6 12.001Z"
			/>
			<path
				fill={color}
				fillRule="evenodd"
				d="M6.42857 8.14386V4.28671H4.71429V5.14386H5.57143V8.14386H4.28571V9.001H7.71429V8.14386H6.42857Z"
			/>
			<path
				fill={color}
				fillRule="evenodd"
				d="M6 2.14386C5.64496 2.14386 5.35714 2.43167 5.35714 2.78671C5.35714 3.14175 5.64496 3.42957 6 3.42957C6.35504 3.42957 6.64286 3.14175 6.64286 2.78671C6.64286 2.43167 6.35504 2.14386 6 2.14386Z"
			/>
		</svg>
	);
})

Alert.displayName = "Alert";