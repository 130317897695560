import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { EventProgress, EventDescription, EventParticipants, CreateLinkTrip, UpdateSection } from './sections';
const Overview = (props) => {

	const eventReducer = useSelector(state => state.events);
	const { userRoleInEvent } = eventReducer;

	return (
		<>
			{userRoleInEvent ? userRoleInEvent?.role === "organizer" ? <EventProgress
				eventId={props.eventId}
				showModal={props.showModal}
				isEditing={props.isEditing}
				setIsEditing={props.setIsEditing}
				description={props.description}
				setDescription={props.setDescription}
				document={props.document}
				setDocument={props.setDocument}
				handleDescriptionEdit={props.handleDescriptionEdit}
				scrollToCenter={props.scrollToCenter}

			/> : null : <Skeleton />}
			<EventDescription
				setDefaultKey={props.setDefaultKey}
				eventId={props.eventId}
				isEditing={props.isEditing}
				setIsEditing={props.setIsEditing}
				description={props.description}
				setDescription={props.setDescription}
				document={props.document}
				setDocument={props.setDocument}
				handleDescriptionEdit={props.handleDescriptionEdit}
				quillRef={props.quillRef}
				scrollToCenter={props.scrollToCenter}
			/>
			<EventParticipants
				typeOfParticipantsView={props.typeOfParticipantsView}
				setTypeOfParticipantsView={props.setTypeOfParticipantsView}
				tabActiveKey={props.tabActiveKey}
				setTabActiveKey={props.setTabActiveKey}
				setDefaultKey={props.setDefaultKey} eventId={props.eventId} />
			<CreateLinkTrip eventId={props.eventId} eventDetails={props.eventDetails} />
			<UpdateSection eventUpdatesData={props.eventUpdatesData} eventId={props.eventId} />
		</>
	);
};

export default Overview;