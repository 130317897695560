import { Button } from 'components/atoms';
import { Icon } from 'components/atoms/Icon';
import InviteParticipantsModal from './components/InviteParticipantsModal';
import Overview from './components/Overview';
import Participants from './components/Participants';
import Manage from './components/Manage';
import { Tabs } from 'components/atoms';
import { Dropdown, Result, Skeleton } from 'antd';
import { DownOutlined, UserOutlined, DashOutlined, MailOutlined, EnvironmentOutlined, EllipsisOutlined } from '@ant-design/icons';
import CreateEventModal from '../Events/components/CreateEventModal';

import { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventServicePermission, fetchTemplateUsers as fetchTemplateUsersFunction, fetchUsersRoleForEvent, getCurrentParticipantsForEvent, getEventDetails, getinviteFilter, getListOfEmployeesForClient, getRSVPcount, getSignedUrlForUpload, inviteEmployees, loadingStateForUpdateFieldForAnEvent, publishAnUpdateForAnEvent, setRSVPcount, setSignedUrlForUpload, updateEvent as updateEventFunction, updateEventStatus, updateRSVPstatus, uploadTemplateToS3 } from 'redux/features/Events/Events.slice';
import moment from 'moment';
import dayjs from 'dayjs';
import ToastService from 'services/ToastService';
import { FourDoticonForTabs } from 'icons/FourDotIconForTabs';
import { OutlinedProfileIcon } from 'icons/ProfileIcon';
import { titleCase, titleCaseEveryWord } from './utils';
import { getUserUSMDetails } from 'redux/features/Auth/Auth.slice';
import { EditIcon } from 'icons/EditIcon';
import { AttendingIcon } from 'icons/AttendingIcon';
import { NotAttendingIcon } from 'icons/NotAttendingIcon';

const EventPage = (props) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const queryParameters = new URLSearchParams(window.location.search);
	const eventId = queryParameters.get("id");

	const eventReducer = useSelector(state => state.events);
	const authReducer = useSelector(state => state.auth);
	const { individualEventDetails,
		postUpdateToAnEvent,
		isLoading,
		userRoleInEvent,
		RSVPcount,
		fetchTemplateUsers,
		RSVPstatus,
		listOfEmployeesForClient,
		inviteEmployee,
		signedUrlForUpload,
		uploadToS3,
		inviteFilter,
		updateEvent,
		permission

	} = eventReducer;
	const { userDetails, isLoading: isAuthLoading, usmDetails } = authReducer
	let placeHolderFilter = {
		filter: {
			employee_level: [],
			entity: '',
			business_unit: '',
			department: '',
		},
	}

	const quillRef = useRef(null);

	const [access, setAccess] = useState(false)
	const [locationName, setLocationName] = useState()
	const [activeFilter, setActiveFilter] = useState(placeHolderFilter)
	const [eventDetails, setEventDetails] = useState(null)
	const [eventDetailsForedit, setEventDetailsforEdit] = useState({
		eventName: {
			filled: false,
			value: '',
		},
		location: {
			filled: false,
			value: "",
		},
		description: {
			filled: false,
			value: "",
		},
		startDate: {
			filled: false,
			value: '',
		},
		startTime: {
			filled: false,
			value: '',
		},
		endDate: {
			filled: false,
			value: '',
		},
		endTime: {
			filled: false,
			value: '',
		},
		start_date_timezone: {
			filled: false,
			value: '',
		},
		end_date_timezone: {
			filled: false,
			value: '',
		},
		latitude: {
			filled: false,
			value: '',
		},
		longitude: {
			filled: false,
			value: '',
		},
	});
	const [timezoneData, setTimezoneData] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [eventUpdates, setEventUpdates] = useState([]);
	const [modalType, setModalType] = useState("");

	const [totalEmployees, setTotalEmployees] = useState([]);
	const [totalEmployeesOG, setTotalEmployeesOG] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState([]);

	const [usersFromTemplate, setUsersFromTemplate] = useState([])

	const [typeOfParticipantsView, setTypeOfParticipantsView] = useState("INVITED");
	const [file, setFile] = useState(null);

	const [defaultKey, setDefaultKey] = useState("1")

	const [tabActiveKey, setTabActiveKey] = useState('1');

	const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState();
	const [selectedOption, setSelectedOption] = useState([]);

	const [appliedFilter, setAppliedFilter] = useState(null);

	// for event description

	const [isEditing, setIsEditing] = useState(false)
	const [description, setDescription] = useState(false)
	const [document, setDocument] = useState(false)
	const [searchParticipants, setSearchParticipants] = useState("")

	const navigateHome = () => {
		window.location.href = process.env.REACT_APP_ITILITE_BASE_URL;
	};
	useEffect(() => {
		if (permission === null) {
			dispatch(fetchEventServicePermission())
		}
		if (permission?.status) {
			setAccess(true)
		}
		else if (permission?.status === false) {
			setAccess(false)
			ToastService.error(permission?.message)
			setTimeout(() => {
				navigateHome()
			}, [4000])
		}
	}, [permission])


	useEffect(() => {
		if (!individualEventDetails?.description?.length) {
			setDocument(false)
		}

	}, [document, description, individualEventDetails?.description])

	const handleDescriptionEdit = (key) => {

		if (key === 'cancel') {
			if (individualEventDetails.description?.length > 2) {

				let _localData = JSON.parse(
					individualEventDetails?.description
				);

				setDescription({
					data: _localData.data,
					document: _localData.document,
				});
				setDocument(_localData.document);
			}
			setIsEditing(false);
			// setDescription(false)
		} else {
			focusquill();
			scrollToCenter()
			setIsEditing(true);
		}
	};

	const showModal = (type) => {
		let companyIdArr = inviteFilter?.invite_filter.map((item) => item.id)
		dispatch(getListOfEmployeesForClient(eventId, companyIdArr, activeFilter));
		if (type === 'bulk' && signedUrlForUpload === null) {
			dispatch(getSignedUrlForUpload(eventId, userDetails.company));
		}

		if (listOfEmployeesForClient) {
			setTotalEmployees(listOfEmployeesForClient)
		}
		setModalType(type)
		setIsModalOpen(true);
	};

	const handleInviteEmployee = () => {
		// setIsModalOpen(false);
		setSearchParticipants("");
		let _data = selectedEmployees.map((data) => {
			return {
				id: data.id,
				email: data.email
			}
		})
		// return
		dispatch(inviteEmployees(eventId, userDetails.company, {
			employees: _data
		}))
	};

	useEffect(() => {
		setDescription(false)
	}, [])
	const handleCancel = () => {
		setSelectedEmployees([])
		setTotalEmployees([])
		setIsModalOpen(false);
		setFile(null)
		dispatch(setSignedUrlForUpload(null))
		setActiveFilter(placeHolderFilter)
		setUsersFromTemplate([])
		setSearchParticipants("")
	};

	let dummyFilter = {
		"status": "INVITED",
		"filter": {
			"linked_trips": [
				"linked",
				"not linked"
			],
			"trip_status": []
		}
	};

	const scrollToCenter = () => {


		window.document.getElementById('description-container-001').scrollIntoView()
	}

	const onChange = (key) => {
		setTabActiveKey(key)
	};

	const focusquill = () => {
		if (quillRef.current) {
			quillRef.current.focus();
		}
	};

	let tabItemsForOrganizer = [
		{
			key: '1',
			label: `Overview`,
			children: <Overview
				eventId={eventId}
				eventUpdatesData={eventUpdates}
				isLoading={isLoading}
				defaultKey={defaultKey}
				setDefaultKey={setDefaultKey}
				tabActiveKey={tabActiveKey}
				setTabActiveKey={setTabActiveKey}
				eventDetails={eventDetails}
				showModal={showModal}
				isEditing={isEditing}
				setIsEditing={setIsEditing}
				description={description}
				setDescription={setDescription}
				document={document}
				setDocument={setDocument}
				handleDescriptionEdit={handleDescriptionEdit}
				quillRef={quillRef}
				scrollToCenter={scrollToCenter}
				typeOfParticipantsView={typeOfParticipantsView}
				setTypeOfParticipantsView={setTypeOfParticipantsView}

			/>
		},
		{
			key: '2',
			label: `Participants`,
			children: <Participants
				eventId={eventId}
				individualEventDetails={individualEventDetails}
				isLoading={isLoading}
				typeOfParticipantsView={typeOfParticipantsView}
				setTypeOfParticipantsView={setTypeOfParticipantsView}
				tabActiveKey={tabActiveKey}
				setTabActiveKey={setTabActiveKey}
				appliedFilter={appliedFilter}
				setAppliedFilter={setAppliedFilter}

			/>,
		},
		{
			key: '3',
			label: `Manage`,
			children: <Manage
				eventId={eventId}
				isLoading={isLoading} />,
		},
	];
	let tabItemsForParticipants = [
		{
			key: '1',
			label: `Overview`,
			children: <Overview
				eventId={eventId}
				eventUpdatesData={eventUpdates}
				isLoading={isLoading}
				defaultKey={defaultKey}
				setDefaultKey={setDefaultKey}
				tabActiveKey={tabActiveKey}
				setTabActiveKey={setTabActiveKey}
				eventDetails={eventDetails}
				showModal={showModal}
				isEditing={isEditing}
				setIsEditing={setIsEditing}
				description={description}
				setDescription={setDescription}
				document={document}
				setDocument={setDocument}
				handleDescriptionEdit={handleDescriptionEdit}
				quillRef={quillRef}
				scrollToCenter={scrollToCenter}
				typeOfParticipantsView={typeOfParticipantsView}
				setTypeOfParticipantsView={setTypeOfParticipantsView}
			/>
		},
		{
			key: '2',
			label: `Participants`,
			children: <Participants
				eventId={eventId}
				individualEventDetails={individualEventDetails}
				isLoading={isLoading}
				typeOfParticipantsView={typeOfParticipantsView}
				setTypeOfParticipantsView={setTypeOfParticipantsView}
				tabActiveKey={tabActiveKey}
				setTabActiveKey={setTabActiveKey}
			/>,
		},
	];

	useEffect(() => {
		if (userRoleInEvent && userRoleInEvent.status) {
			if (userRoleInEvent?.role.toLowerCase() === "organizer") {

			}
		} else {

		}
	}, [userRoleInEvent])

	useEffect(() => {
		if (usmDetails === null && userDetails) {
			dispatch(getUserUSMDetails(userDetails?.id));
		}
	}, [usmDetails, userDetails])

	useEffect(() => {
		if (eventId && userDetails && access) {
			dispatch(fetchUsersRoleForEvent(eventId));
			dispatch(getRSVPcount(eventId, true));
			dispatch(getEventDetails(eventId, true));
			dispatch(publishAnUpdateForAnEvent(eventId, null, "GET"));
		}
	}, [eventId, userDetails, access])

	useEffect(() => {
		if (userDetails && access) {
			dispatch(getinviteFilter(eventId, userDetails?.company));
		}
	}, [userDetails, access])

	useEffect(() => {
		if (RSVPstatus && RSVPstatus.status) {

			if (eventDetails?.invitation_status?.status === "attending") {
				setEventDetails(
					{
						...eventDetails,
						invitation_status: {
							...eventDetails?.invitation_status,
							status: "not attending"
						}
					}
				)
			}
			else {
				setEventDetails(
					{
						...eventDetails,
						invitation_status: {
							...eventDetails?.invitation_status,
							status: "attending"
						}
					}
				)
			}
			dispatch(getEventDetails(eventId, false));
			dispatch(getRSVPcount(eventId, false));
			let filter;
			if (appliedFilter) {
				filter = {
					status: typeOfParticipantsView,
					filter: appliedFilter,
				};
			}
			else {
				filter = {
					status: typeOfParticipantsView,
					filter: {
						linked_trips: ['linked', 'not linked'],
						trip_status: [],
					},
				};
			}
			dispatch(
				getCurrentParticipantsForEvent(
					eventId,
					filter,
					typeOfParticipantsView,
					true
				)
			);

		}
	}, [RSVPstatus])

	useEffect(() => {
		if (individualEventDetails) {
			setEventDetails(individualEventDetails);
			// const dateString = '2023-02-14 20:01:39';
			const dateFormat = 'YYYY-MM-DD HH:mm:ss';
			const timeFormat = 'HH:mm:ss';
			// const defaultDate = moment(dateString, dateFormat);

			setEventDetailsforEdit({
				...eventDetailsForedit,
				eventName: {
					filled: true,
					value: individualEventDetails.name
				},
				location: {
					filled: false,
					value: individualEventDetails.location,
				},
				description: {
					filled: false,
					value: individualEventDetails.description,
				},
				startDate: {
					filled: false,
					value: individualEventDetails.start_date_time?.split(" ")[0],
					objVal: dayjs(individualEventDetails.start_date_time, dateFormat),
				},
				startTime: {
					filled: false,
					value: individualEventDetails.start_date_time?.split(" ")[1],
					objVal: dayjs(individualEventDetails.start_date_time, dateFormat),
				},
				endDate: {
					filled: false,
					value: individualEventDetails.end_date_time?.split(" ")[0],
					objVal: dayjs(individualEventDetails.end_date_time, dateFormat),
				},
				endTime: {
					filled: false,
					value: individualEventDetails.end_date_time?.split(" ")[1],
					objVal: dayjs(individualEventDetails.end_date_time, dateFormat),
				},
				start_date_timezone: {
					filled: false,
					value: individualEventDetails.start_date_timezone,
				},
				end_date_timezone: {
					filled: false,
					value: individualEventDetails.end_date_timezone,
				},
				latitude: {
					filled: false,
					value: individualEventDetails.latitude,
				},
				longitude: {
					filled: false,
					value: individualEventDetails.longitude,
				},
			})

		}
		setTimezoneData({
			start_date_timezone: individualEventDetails?.start_date_timezone,
			end_date_timezone: individualEventDetails?.end_date_timezone,
			latitude: individualEventDetails?.latitude,
			longitude: individualEventDetails?.longitude
		})
		// setSelectedOption({
		// 	"id": 2,
		// 	"value": "Jammu Tawi Railway Station, Trikuta Nagar, Jammu, Jammu and Kashmir",
		// 	"category": "Locations",
		// 	"place_id": "ChIJU3CmZ2uDHjkRUepTZo2xF9Q"
		// })
		if (postUpdateToAnEvent) {
			if (postUpdateToAnEvent.key && Array.isArray(postUpdateToAnEvent.payload.event_updates)) {
				setEventUpdates(postUpdateToAnEvent.payload.event_updates);
			}
			// else {
			// 	if (postUpdateToAnEvent.payload.message)
			// 		ToastService.error(postUpdateToAnEvent.payload.message)
			// }
		}
	}, [individualEventDetails, postUpdateToAnEvent]);

	useEffect(() => {
		if (inviteEmployee && inviteEmployee.status) {
			setIsModalOpen(false)
			setAppliedFilter(null)
			dispatch(getCurrentParticipantsForEvent(eventId, dummyFilter, typeOfParticipantsView, true))
			// setUsersFromTemplate([])
		}

	}, [inviteEmployee])

	// function for opening modal

	const handleRSVP = (event) => {
		if (event.key === "attending") {
			dispatch(updateRSVPstatus({
				attending: true
			}, eventDetails.id, userDetails?.id));
		}
		else {
			dispatch(updateRSVPstatus({
				attending: false
			}, eventDetails.id, userDetails?.id));

		}
	}

	const items = [
		{
			label: 'Attending',
			key: 'attending',
			icon: <AttendingIcon size="13" color="#212121" className='tw-pt-1' />,
		},
		{
			label: 'Not attending',
			key: 'not attending',
			icon: <NotAttendingIcon size="13" color="#212121" className='tw-pt-1' />,
		},
	];
	const menuProps = {
		items,
		onClick: e => handleRSVP(e)
	}

	const AttendingDropdown = (props) => {

		const items = [
			{
				label: 'Pin',
				key: 'pin',
			},
			{
				label: 'Delete',
				key: 'delete',
			},
		];
		const menuProps = {
			items,
			onClick: () => console.log('attending dropdown'),
		};

		return (<Dropdown menu={menuProps} trigger={['click']} className='tw-relative tw-z-[2]'>
			<Button size="large" type='default'>
				<FourDoticonForTabs />
				Attending
				{/* <DownOutlined /> */}
			</Button>
		</Dropdown>);

	};

	const InviteDropdown = (props) => {

		const items = [
			{
				label: <p className='tw-m-0' onClick={() => showModal("individual")}>Individual invitation</p>,
				key: 'pin',
				// icon: <UserOutlined />,
			},
			{
				label: <p className='tw-m-0' onClick={() => showModal("bulk")}>Bulk invitation (via xlsx file)</p>,
				key: 'delete',
				// icon: <UserOutlined />,
			},
		];
		const menuProps = {
			items,
			onClick: () => console.log('Invite dropdown'),
		};

		return (
			<Dropdown menu={menuProps} trigger={['click']} overlayStyle={{ width: "220px" }} className='tw-relative tw-z-[2] tw-ml-4'
				getPopupContainer={trigger => {
					return trigger.parentNode
				}}
			// style={{ position: 'fixed' }}
			>
				<Button size="large">
					<MailOutlined style={{ fontSize: "14px" }} />
					<span className='tw-text-sm tw-font-medium'> Invite participants</span>
					<DownOutlined style={{ fontSize: "12px" }} />
				</Button>
			</Dropdown>
		);

	};

	useEffect(() => {
		if (uploadToS3 && uploadToS3.status === 204) {
			setModalType("invite-bulk-users")
			setFile(null);
			dispatch(fetchTemplateUsersFunction(eventId, userDetails.company, signedUrlForUpload?.file_name))
		}

	}, [uploadToS3])

	useEffect(() => {
		if (updateEvent?.status) {
			ToastService.successNotication(updateEvent?.message)
			setIsEditDetailsModalOpen(false)
			dispatch(getEventDetails(eventId, true))
		}
		else {
			ToastService.error(updateEvent?.message)

		}
		dispatch(updateEventStatus(null))
	}, [updateEvent])

	const handleTemplateUploadToS3 = async () => {

		const formData = new FormData();
		formData.append('key', signedUrlForUpload?.fields?.key);
		formData.append('x-amz-algorithm', signedUrlForUpload?.fields['x-amz-algorithm']);
		formData.append('x-amz-credential', signedUrlForUpload?.fields['x-amz-credential']);
		formData.append('x-amz-date', signedUrlForUpload?.fields['x-amz-date']);
		formData.append('policy', signedUrlForUpload?.fields['policy']);
		formData.append('x-amz-signature', signedUrlForUpload?.fields['x-amz-signature']);
		formData.append('file', file);
		// return
		dispatch(uploadTemplateToS3(signedUrlForUpload?.url, formData, file.name));

	};

	const handleInviteValidUsersfromTemplate = () => {
		let _data = usersFromTemplate.filter((_data) => !_data.invited).map((data) => {
			return {
				id: data.id,
				email: data.email
			}
		})

		dispatch(inviteEmployees(eventId, userDetails.company, {
			employees: _data
		}));
	};




	const handleEditEventDetails = () => {
		setIsEditDetailsModalOpen(true)
	}
	const handleEditEvent = () => {

		// return

		let body = {

			"name": eventDetailsForedit.eventName.value,
			"description": eventDetailsForedit.description.value,
			"location": selectedOption.value ? selectedOption.value : eventDetailsForedit.location.value,
			"latitude": timezoneData?.latitude,
			"longitude": timezoneData?.longitude,
			"start_date": `${eventDetailsForedit.startDate.value} ${eventDetailsForedit.startTime.value}`,
			"end_date": `${eventDetailsForedit.endDate.value} ${eventDetailsForedit.endTime.value}`,
			"start_date_timezone": timezoneData?.start_date_timezone,
			"end_date_timezone": timezoneData?.end_date_timezone

		}

		dispatch(updateEventFunction(body, eventId));
		// setIsEditDetailsModalOpen(false)

	}
	const handleEventEditCancel = () => {
		setLocationName(eventDetailsForedit?.location.value)

		if (individualEventDetails) {
			setEventDetailsforEdit({
				...eventDetailsForedit,
				eventName: {
					filled: true,
					value: individualEventDetails?.name
				}
			})
		}

		setIsEditDetailsModalOpen(false)
		setTimezoneData(null)
		setSelectedOption([])
	}


	const dateFormatter = (cardDetails) => {

		const obj = {
			start_date_time: cardDetails.start_date_time,
			end_date_time: cardDetails.end_date_time,
			start_date_timezone: cardDetails.start_date_timezone,
			end_date_timezone: cardDetails.end_date_timezone
		};

		const startDateString = moment.utc(obj.start_date_time, moment.defaultFormatUtc).toString();
		const endDateString = moment.utc(obj.end_date_time, moment.defaultFormatUtc).toString();


		const startTimeString = moment.utc(startDateString.split(' ')[4], 'HH:mm:ss').format('hh:mm A');
		const endTimeString = moment.utc(endDateString.split(' ')[4], 'HH:mm:ss').format('hh:mm A');

		const timezoneString = obj.start_date_timezone;

		const correctStartDateString = `${startDateString.split(' ')[2]} ${startDateString.split(' ')[1]} ${startDateString.split(' ')[3]}`
		const correctEndDateString = `${endDateString.split(' ')[2]} ${endDateString.split(' ')[1]} ${endDateString.split(' ')[3]}`
		let corectTimeZoneString = [];
		let finalTimezone = timezoneString.split(" ");
		if (finalTimezone.length > 2) {
			let arr1 = finalTimezone[0] + " " + finalTimezone[1];
			let arr2 = finalTimezone[2];
			finalTimezone = [];
			finalTimezone.push(arr1)
			finalTimezone.push(arr2)
			corectTimeZoneString = finalTimezone[1];
		} else {
			corectTimeZoneString = finalTimezone[1];
		}

		const GMTTime = corectTimeZoneString.slice(0, 3) + ":" + corectTimeZoneString.slice(3);


		const formattedString = `${correctStartDateString} · ${startTimeString}  -  ${correctEndDateString} · ${endTimeString} ${finalTimezone[0]} (GMT ${GMTTime})`;

		if (formattedString)
			return formattedString;
		else return `${eventDetails.start_date_time - eventDetails.end_date_time}`
	};


	const EditEvent = (props) => {

		const items = [
			{
				key: '1',
				label: <span onClick={() => handleEditEventDetails()} className="tw-flex"><EditIcon size="13" color="#6B7280" className="tw-mr-2" /> Edit</span>,
			},
		];

		return (
			<Dropdown trigger={['click']} menu={{ items }} getPopupContainer={trigger => trigger.parentNode}>
				<Button size="large" type="text">
					<EllipsisOutlined style={{ fontSize: "30px", color: "black" }} />
				</Button>
			</Dropdown>
		);
	};

	const displayParticipantPreview = (data, total) => {

		let viewerInvited = false;
		if (data) {
			let tempArr = [...data];
			let filterArr = tempArr.filter((_data, i) => {

				if (i < 4 && userDetails.id !== _data.id)
					return _data;
				else if (i <= 4 && userDetails.id === _data.id) {
					viewerInvited = true;
				}
			})
			let arr = filterArr.map((_data, i) => {

				if (i < 4)
					return titleCaseEveryWord(_data.name);
			})
			let string = arr.join(", ");
			if (arr.length < 4) {
				return `${viewerInvited ? "You" : ""} ${arr.length >= 1 && viewerInvited ? (", " + string) : ("" + string)} ${total > 4 ? `and ${total - 4} others` : ""} ${data.length === 1 ? `${viewerInvited ? "are" : ""} attending this event` : data.length !== 0 ? "are attending this event." : ""}`
			}
			else {
				return `${viewerInvited ? "You" : ""} ${arr.length >= 1 && viewerInvited ? (", " + string) : ("" + string)} and ${total - 4} ${data.length === 1 ? `${viewerInvited ? "are" : ""} attending this event` : data.length !== 0 ? "are attending this event." : ""}`
			}
		}
		// You, Ramkumar Sundarakalatharan, Vincent buro, Angelo nick, Chun nuno  and 3333 more attending this event
	}

	const ImagePreview = (src) => {
		if (src.src) {
			return <img src={src.src} alt="" width={30} height={30} className="tw-mr-[-5px] tw-aspect-square tw-rounded-full" />
		}
		else {
			return <span className='tw-mr-[-5px]'>
				<OutlinedProfileIcon size={38} className="tw-aspect-square tw-rounded-full" />
			</span>
		}
	}
	const profilePicPreview = (data) => {
		if (data.participant_preview) {
			let tempArr = [...data.participant_preview]
			let filterArr = tempArr.filter((_data, i) => {

				if (i < 4)
					return _data
			})
			return filterArr

		} else return

	}

	const getText = () =>{
		if(eventDetails?.message){
			return `${eventDetails?.message.split(",")[0]}, ${eventDetails?.message.split(",")[1]}, you are not on the list of invitees for this event. You can get in touch with the event creator.`
		}
	}

	useEffect(()=>{
		let timer;

		if(eventDetails?.status ===false && eventDetails?.message){
		    timer = setTimeout(() => {
				navigate("/team-events")
			}, [4000])
		}

		return ()=>{
			clearTimeout(timer);
		}
	},[eventDetails])

	return (
		<div>
			{
				eventId && eventDetails?.status && userDetails ? <div className="tw-pt-[120px]" style={{ backgroundImage: "url('/images/teamevents/background.svg')", backgroundRepeat: "no-repeat", backgroundPosition: "center top" }}>
					<div className="tw-max-w-[842px] tw-mx-auto tw-mt-8 sm:tw-px-0 tw-px-2">
						{<div className="tw-bg-white tw-border tw-rounded-lg tw-border-solid tw-border-[#b6bac3] tw-mb-[40px]  tw-relative tw-pt-4 ">
							{isLoading.individualEventDetails ?
								<div className='tw-m-5'>
									<Skeleton
										width={100}
										paragraph={false}
										active={true}
										height={1}
										size="large"
									/>
								</div>
								: <>
									{userRoleInEvent?.role === "organizer" ? <span className='tw-absolute tw-top-2 tw-right-8'>
										{/* // Edit event */}
										<EditEvent />
									</span> : null}

									<h3 className='tw-m-0 tw-mb-2 tw-font-medium tw-text-base tw-px-8'>{eventDetails?.name}</h3>
								</>}
							{isLoading.individualEventDetails ?
								<div className='tw-m-5'>

									<Skeleton
										width={100}
										paragraph={false}
										active={true}
										height={1}
										size="large"
									/>
								</div>
								: <>
									<p className='tw-m-0 tw-typography-body2 tw-mb-2 tw-px-8'><Icon /> <span className="tw-ml-[5px] tw-text-color-gray-600">{dateFormatter(eventDetails)}</span></p>
									<p className='tw-m-0 tw-typography-body2 tw-mb-[12px] tw-px-8'><EnvironmentOutlined /> <span className="tw-ml-[5px] tw-text-color-gray-600">{eventDetails?.location}&nbsp;
										<a href={`https://www.google.com/maps/search/?api=1&query=${eventDetails.latitude},${eventDetails.longitude}`} target="_blank"
											rel="noopener noreferrer" className='tw-ml-2 tw-text-color-text-info'>View map</a></span></p>
									<div className='tw-px-8 tw-flex tw-items-center tw-mb-2'>
										{profilePicPreview(eventDetails)?.map((_data) => {
											return <ImagePreview src={_data?.profile_picture?.icon_path} />
										})}
										{
											eventDetails?.total_participants - 4 > 0 ? <div className="tw-bg-[#F1F3F5] tw-rounded-[40px] tw-border-[1.5px] tw-border-solid tw-border-white tw-px-4 tw-leading-8">{eventDetails.total_participants - 4}</div> : ""
										}
										<div className="tw-flex-grow tw-text-left tw-ml-4 tw-text-gray-900 tw-text-font-size-20">{displayParticipantPreview(eventDetails.participant_preview, eventDetails.total_participants)}</div>

									</div>
									{eventDetails?.invitation_status?.status?.toLowerCase() === "invited" ?
										<div id="participation-floater " className='tw-flex sm:tw-flex-row tw-flex-col sm:tw-space-y-0 tw-space-y-2 tw-items-center tw-px-8 tw-py-4 tw-bg-[#F1F3F5]  tw-border-y-0 tw-border-t tw-border-l-0 tw-border-r-0 tw-rounded-lg tw-border-solid tw-border-[#B6BAC3]'>
											<div className='tw-flex sm:tw-w-1/2 tw-w-full'>Are you attending?</div>
											<div className='tw-flex sm:tw-w-1/2 tw-w-full sm:tw-flex-row tw-flex-col sm:tw-justify-end sm:tw-space-y-0 tw-space-y-2'>
												{/* <AttendingDropdown />
									<Button size="large" type='dashed' className='tw-ml-4'><Icon className='tw-mr-[8px]' />Not Attending</Button> */}
												<Button size="large" type='default' className='tw-ml-[10px] tw-ml-0 tw-flex tw-align-center' onClick={() => handleRSVP({ key: "not attending" })} ><span className="tw-mr-2 tw-mt-1.5"><NotAttendingIcon size="18" /> </span>No, I am not attending</Button>
												<Button size="large" type='default' className='sm:tw-ml-[10px] tw-ml-[0px] tw-flex tw-align-center' onClick={() => handleRSVP({ key: "attending" })} ><span className="tw-mr-2 tw-mt-1.5"><AttendingIcon size="18" /> </span>Yes, I am attending</Button>

											</div>
										</div>

										: null}
								</>}
						</div>}
						{/* Section with the three tabs */}
						<div className='tw-flex tw-flex-row tw-justify-end tw-mr-5 sm:tw-m-0 tw-relative'>
							{/* <AttendingDropdown /> */}
							{(eventDetails?.invitation_status?.status?.toLowerCase() === "attending" || eventDetails?.invitation_status?.status?.toLowerCase() === "not attending")
								? <Dropdown menu={menuProps} trigger={['click']} className='tw-relative tw-z-[2]' getPopupContainer={trigger => trigger.parentNode}>
									<Button size="large" type='default'>
										{eventDetails?.invitation_status?.status.toLowerCase() === "attending" ? <AttendingIcon size="16" className="tw-mt-1.5" /> : <NotAttendingIcon size="16" className="tw-mt-1.5" />}
										{eventDetails?.invitation_status?.status.toLowerCase() === "attending" ? <span className='tw-ml-2 tw-text-sm tw-font-medium'>Attending				<DownOutlined style={{ fontSize: '12px' }} /></span> :
											(eventDetails?.invitation_status?.status.toLowerCase() === "not attending" ? <span className='tw-ml-2 tw-text-sm tw-font-medium'>Not attending 				<DownOutlined style={{ fontSize: '12px' }} /></span> : null)}
									</Button>
								</Dropdown> : null}

							{
								userRoleInEvent?.role === "organizer" ? <InviteDropdown /> : null
							}
						</div>
						{
							userRoleInEvent?.role === "organizer" ?
								<Tabs defaultActiveKey={defaultKey} activeKey={tabActiveKey} items={tabItemsForOrganizer} onChange={onChange} className="sm:tw-mt-[-45px] tw-mt-[20px]" /> : null
						}
						{
							userRoleInEvent?.role !== "organizer" ? <Tabs defaultActiveKey={defaultKey} activeKey={tabActiveKey} items={tabItemsForParticipants} onChange={onChange} className="sm:tw-mt-[-45px] tw-mt-[20px]" /> : null
						}
						<br />
						<Modal title={<span className='tw-text-base tw-font-medium'>{`${modalType === "individual" ? "Invite participants" : "Bulk invitation using xlsx file"}`}</span>}
							open={isModalOpen}
							onOk={modalType === "individual" ? handleInviteEmployee : modalType === "invite-bulk-users" ? handleInviteValidUsersfromTemplate : handleTemplateUploadToS3}
							onCancel={handleCancel}
							okText={modalType === "individual" ? `Invite ${selectedEmployees?.length > 1 ? `${selectedEmployees?.length} persons` : `${selectedEmployees?.length === 1 ? selectedEmployees?.length + " person" : ""} `}  ` : modalType === "invite-bulk-users" ? `Invite  ${usersFromTemplate?.length > 1 ? fetchTemplateUsers?.success_count + " Persons" : `${usersFromTemplate?.length === 1 ? fetchTemplateUsers?.success_count + " person" : ""}`}` : "Upload"}
							width={960}
							// height={"40vh"}
							// bodyStyle={{ height: '40vh' }}
							okButtonProps={{
								fetchTemplateUsers,
								disabled: isLoading.inviteEmployee === true || modalType === "individual" ? selectedEmployees.length === 0 : file ? (isLoading.uploadToS3 ? true : false) : modalType === "invite-bulk-users" ? fetchTemplateUsers?.success_count > 0 ? false : true : true,
								className: 'disabled-modal-button',
								style: {
									backgroundColor: "#EC5D25",
									opacity: (isLoading.inviteEmployee === true || modalType === "individual" ? selectedEmployees.length === 0 : file ? (isLoading.uploadToS3 ? true : false) : modalType === "invite-bulk-users" ? fetchTemplateUsers?.success_count > 0 ? false : true : true) ? 0.5 : 1,
									color: "white"
								}
							}}
						>
							<hr className="tw-border-b-[#ebebeb] tw-border-0 tw-border-b tw-border-solid tw-mb-0 tw-mt-[15px] tw-ml-[-25px] tw-mr-[-25px]" />
							<InviteParticipantsModal
								type={modalType}
								eventId={eventId}
								totalEmployees={totalEmployees}
								setTotalEmployees={setTotalEmployees}
								totalEmployeesOG={totalEmployeesOG}
								setTotalEmployeesOG={setTotalEmployeesOG}
								selectedEmployees={selectedEmployees}
								setSelectedEmployees={setSelectedEmployees}
								handleInviteEmployee={handleInviteEmployee}
								setIsModalOpen={setIsModalOpen}
								file={file}
								setFile={setFile}
								usersFromTemplate={usersFromTemplate}
								setUsersFromTemplate={setUsersFromTemplate}
								inviteFilter={inviteFilter}
								activeFilter={activeFilter}
								setActiveFilter={setActiveFilter}
								searchParticipants={searchParticipants}
								setSearchParticipants={setSearchParticipants}
							/>
							<hr className="tw-border-b-[#ebebeb] tw-border-0 tw-border-b tw-border-solid tw-mt-0 tw-mb-[15px] tw-ml-[-25px] tw-mr-[-25px]" />
						</Modal>
						<Modal
							title={<span className='tw-text-lg tw-font-medium tw-text-[#111827]'>Edit event</span>}
							open={isEditDetailsModalOpen}
							onOk={handleEditEvent}
							onCancel={handleEventEditCancel}
							okText="Edit"
							width={640}
							okButtonProps={{
								disabled: isLoading.timezone || isLoading.locationAutoComplete || (locationName === ""),
								style: {
									backgroundColor: "#EC5D25",
									opacity: (isLoading.timezone || isLoading.locationAutoComplete || (locationName === "")) ? 0.5 : 1,
									color: "white"
								}
							}}
						>
							<CreateEventModal
								eventDetails={eventDetails}
								setEventDetails={setEventDetails}
								handleCreateEvent={handleEditEvent}
								selectedOption={selectedOption}
								setSelectedOption={setSelectedOption}
								timezoneData={timezoneData}
								setTimezoneData={setTimezoneData}
								type="edit"
								setEventDetailsforEdit={setEventDetailsforEdit}
								eventDetailsForedit={eventDetailsForedit}
								setLocationName={setLocationName}
								locationName={locationName}

							/>
						</Modal>
					</div>
				</div> :
				 isLoading.individualEventDetails ? 
				 <div className="tw-max-w-[842px] tw-mx-auto tw-pt-[120px]">
							<div className="tw-bg-white tw-mb-[40px] tw-px-8 tw-py-4 tw-relative">
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={1}
									size="large"
								/>
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={1}
									size="large"
								/>
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={1}
									size="large"
								/>
							</div></div>
				 : eventDetails?.status === false ? 
				<div className='tw-flex tw-ml-[10%] tw-mt-[10%] tw-h-screen'>
					<Result
					// className='tw-flex-row tw-justify-center tw-items-center'
						status="warning"
						title={<span className='tw-text-lg'>{getText()}</span>}
						extra={
						<div className='tw-text-base'>
							For now, we will redirect you to the dashboard
						</div>
						}
					/>
				</div> :
					isAuthLoading ?
						<div className="tw-max-w-[842px] tw-mx-auto tw-pt-[120px]">
							<div className="tw-bg-white tw-mb-[40px] tw-px-8 tw-py-4 tw-relative">
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={1}
									size="large"
								/>
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={1}
									size="large"
								/>
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={1}
									size="large"
								/>
							</div></div>
						:
						(!access ? <div className="tw-max-w-[842px] tw-mx-auto">
							<h2>You are not authorized</h2>
						</div> : "")

			}
		</div>
	);
};

export default EventPage;