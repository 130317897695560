import * as React from 'react';

export const ChevronUp = React.memo(({ size = 16, color = '#6b7280', className, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 16 16"
			className={className}
			{...rest}
		>
			<path
				fill={color}
				fillRule="evenodd"
				d="M3.29302 10.7092C3.68354 11.0997 4.3167 11.0997 4.70723 10.7092L8.00012 7.41627L11.293 10.7092C11.6835 11.0997 12.3167 11.0997 12.7072 10.7092C13.0978 10.3186 13.0978 9.68547 12.7072 9.29495L8.70723 5.29495C8.3167 4.90442 7.68354 4.90442 7.29302 5.29495L3.29302 9.29495C2.90249 9.68547 2.90249 10.3186 3.29302 10.7092Z"
			></path>
		</svg>
	);
})

ChevronUp.displayName = "ChevronUp";