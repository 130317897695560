import { routeDefaultRedirect } from 'auth/routeDefaultRedirects';
import { validateAccessToken } from 'auth/validateToken';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import {
	getUserDetailsAuth,
	setLoadingStateAuthenticateUser,
} from 'redux/features/Auth/Auth.slice';
import LocalStorageService from 'services/LocalStorage';
import { apiUrls } from 'utils/urls/apiUrls';
import routeURLs from 'utils/urls/routeUrls';

function AuthRoute(props) {
	localStorage.clear(); // !clearing old sessions
	const { token, role, approute } = useParams();
	const { isInitCheck, setInitCheck } = props;
	const dispatch = useDispatch();
	if (token && role && approute) {
		/** to get the access token with refresh token */
		const data = {
			refresh: token,
		};

		axios
			.post(apiUrls.AUTH.FETCH_REFRESH_TOKEN, data)
			.then((res) => {
				if (res.status === 200) {
					const _token = res.data.token;
					// Local Storage tokens adding
					LocalStorageService.setAccessToken(_token.access);
					LocalStorageService.setAccessToken(_token.access);
					LocalStorageService.setRefreshToken(_token.refresh);
					LocalStorageService.setRole(role);
					localStorage.setItem('app', approute);
					localStorage.setItem('token', JSON.stringify(_token));
					props.setInitCheck(true);
					// if(res?.data)
					// {
					// 	console.log(res.data)
					// 	window.localStorage.setItem("userAuth_data", JSON.stringify(res.data))

					// }
					// dispatch(getUserDetailsAuth())

					(async () => {
						if (LocalStorageService.getAccessToken()) {
							let accessToken = await validateAccessToken().then(
								(r) => r
							);

							if (!isInitCheck) {
								dispatch(setLoadingStateAuthenticateUser(true));
								axios
									.post(
										apiUrls.AUTH.FETCH_VERIFY_TOKEN,
										{},
										{
											headers: {
												Authorization: `Bearer ${accessToken}`,
											},
										}
									)
									.then((res) => {
										if (res.status === 200) {
											setInitCheck(true);
											localStorage.setItem(
												'userDetails',
												JSON.stringify(res.data)
											);

											dispatch(
												getUserDetailsAuth(res.data)
											);
											dispatch(
												setLoadingStateAuthenticateUser(
													false
												)
											);
											let app_route =
												routeDefaultRedirect(
													localStorage.getItem('app')
												);

											return <Navigate to={app_route} />;
										}
									})
									.catch((err) => {
										if (
											err.response &&
											err.response.status === 401
										) {
											LocalStorageService.removeAllToken();
											localStorage.removeItem('token');
										}
									});
							}
						}
					})();
				}
			})
			.catch((error) => {
				console.log(error.response);
				if (error.response?.status === 401) {
					LocalStorageService.removeAllToken();
				}
			});
		// return
		return <Navigate to={{ pathname: `/${approute}` }} />;
	} else {
		LocalStorageService.removeAllToken();
		return <Navigate to={{ pathname: routeURLs.home }} />;
	}
}

export default AuthRoute;
