export const styles = {
	colorPrimaryBorderHover: '#EB5C24',
	colorPrimaryHover: '#EB5C24',
	colorPrimary: '#EB5C24',
	colorPrimaryActive: '#EB5C24',
	colorPrimaryTextHover: '#EB5C24',
};

export const bluePrimary = '#383DA9';
export const colorPrimary = '#EB5C24';
export const bodyTextSecondary = '#636D85';
