import NavbarList from './NavbarList';
import NavbarStrip from './NavbarStrip';
import clsx from 'clsx';
import classes from './Navbar.module.scss';
import { useEffect, useState } from 'react';

function Navbar({ secondaryMenu, responsiveHam, isHamMenu, setHamMenu }) {
	const [showSecondaryMenu, setSecondaryMenu] = useState(false);
	useEffect(() => {
		setSecondaryMenu(secondaryMenu);
	}, [secondaryMenu]);
	const showNavStrip = () => {
		if (!isHamMenu) return true;
		if (isHamMenu && showSecondaryMenu) return true;
		return false;
	};
	return (
		showNavStrip() && (
			<div className={clsx(classes['il__navbar--container'])}>
				<NavbarStrip hamMenu={isHamMenu} setHamMenu={setHamMenu} />
				{showSecondaryMenu && (
					<NavbarList showMenu={showSecondaryMenu} />
				)}
			</div>
		)
	);
}

export default Navbar;
