import * as React from 'react';

export const TrainIcon = React.memo(({ size = 24, color = '#6B7280', className, ...rest }) => {
    return (
        <svg
            width={size}
            height={size}
            {
            ...rest
            }
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00001 15C7.00001 14.4477 7.44772 14 8.00001 14C8.55229 14 9.00001 14.4477 9.00001 15C9.00001 15.5523 8.55229 16 8.00001 16C7.44772 16 7.00001 15.5523 7.00001 15Z" fill="black" />
            <path d="M15 15C15 14.4477 15.4477 14 16 14C16.5523 14 17 14.4477 17 15C17 15.5523 16.5523 16 16 16C15.4477 16 15 15.5523 15 15Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.74998 3.5C6.50735 3.5 5.50001 4.50735 5.50001 5.74997V10C5.50001 10.8284 6.17158 11.5 7.00001 11.5H17C17.8284 11.5 18.5 10.8284 18.5 10V5.74997C18.5 4.50735 17.4927 3.5 16.25 3.5H7.74998ZM20 5.74997C20 3.67892 18.3211 2 16.25 2H7.74998C5.67892 2 4.00001 3.67892 4.00001 5.74997V16C4.00001 17.6267 5.29483 18.9511 6.91008 18.9986L4.34951 20.6159C3.9993 20.837 3.8947 21.3003 4.11589 21.6505C4.33708 22.0007 4.80029 22.1053 5.1505 21.8841L9.71702 19H14.283L18.8495 21.8841C19.1997 22.1053 19.6629 22.0007 19.8841 21.6505C20.1053 21.3003 20.0007 20.837 19.6505 20.6159L17.0899 18.9986C18.7052 18.9511 20 17.6267 20 16V5.74997ZM18.5 12.5987C18.0587 12.8539 17.5464 13 17 13H7.00001C6.45357 13 5.94127 12.8539 5.50001 12.5987V16C5.50001 16.8284 6.17158 17.5 7.00001 17.5H17C17.8284 17.5 18.5 16.8284 18.5 16V12.5987ZM10 5.75C10 5.33579 10.3358 5 10.75 5H13.25C13.6642 5 14 5.33579 14 5.75C14 6.16421 13.6642 6.5 13.25 6.5H10.75C10.3358 6.5 10 6.16421 10 5.75Z" fill={color} />
        </svg>



    );
})

TrainIcon.displayName = "TrainIcon";