import Navbar from '../../compounds/Navbar';
import classes from './AppLayout.module.scss';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Space, Spin } from 'antd';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import { IconButton } from 'components/atoms';
import { HamIcon } from 'components/compounds/Navbar/_icons';

function AppLayout(props) {
	const { isloading, showSecondaryMenu = true, children } = props;

	const [responsiveHam, setResponsiveHam] = useState(false);
	const [showHamMenu, setHamMenu] = useState(false);
	const windowSize = useWindowSize();

	useEffect(() => {
		if (windowSize.width < 1024) {
			setResponsiveHam(true);
		} else {
			setResponsiveHam(false);
		}
	}, [windowSize]);
	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				responsiveHam,
			});
		}
		return child;
	});

	return (
		<div className={clsx(classes['il__main--wrapper'])}>
			{isloading ? (
				<div className={clsx(classes['il__body--wrapper'])}>
					<Space direction="horizontal" style={{ width: '100%' }}>
						<Space>
							<Spin tip="Loading" size="large">
								<div className="content" />
							</Spin>
						</Space>
					</Space>
				</div>
			) : (
				<>
					<div
						className={clsx(
							classes['il__navbar--wrapper'],
							showHamMenu
								? classes['il__navbar--mobile_show']
								: classes['il__navbar--mobile_hide']
						)}
					>
						<Navbar
							secondaryMenu={showSecondaryMenu}
							responsiveHam={responsiveHam}
							isHamMenu={showHamMenu}
							setHamMenu={setHamMenu}
						/>
					</div>

					<div className={clsx(classes['il__body--wrapper'])}>
						{responsiveHam && showSecondaryMenu && (
							<div
								className={clsx(
									classes['il__body--mobile_ham'],
									'tw-absolute lg:tw-none tw-mb-5 tw-mt-2 tw-cursor-pointer tw-w-[64px]  tw-flex tw-justify-center tw-pt-[16px]'
								)}
								onClick={() => {
									setHamMenu(!showHamMenu);
								}}
							>
								<IconButton icon={<HamIcon />} />
							</div>
						)}

						{childrenWithProps}
					</div>
				</>
			)}
		</div>
	);
}

export default AppLayout;
