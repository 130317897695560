import { useState, useEffect, useRef } from 'react';
import { Button, IconButton, Input, Skeleton } from 'components/atoms';
import { Icon } from 'components/atoms/Icon';
import { Dropdown, Menu, Modal, Pagination, Table, Progress } from 'antd';
import {
	DownOutlined,
	UserOutlined,
	SearchOutlined,
	DashOutlined,
	UpOutlined,
	CloseOutlined,
	CompressOutlined,
	CaretRightOutlined,
	CloseCircleOutlined,
	PushpinOutlined,
	EllipsisOutlined,
	DeleteOutlined
} from '@ant-design/icons';
import avatar from '../../../../../../assets/images/teamevents/avatar.png';
import LinkTripTable from './LinkTripTable';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteIndividualEvent,
	downloadEventReports,
	fetchLinkedTripsToTheEvent,
	getActiveTripsForTheUser,
	getCurrentParticipantsForEvent,
	getEventDetails,
	getListOfEmployeesForClient,
	getRSVPcount,
	linkedSelectedTrips,
	manageOrganizers,
	pinDeleteEventUpdate,
	publishAnUpdateForAnEvent,
	removeLinkedTripsToTheEvent,
	removeParticipantsFromEvent,
	sendMessageAll,
	setAddLinkedTrips,
	setDownloadReportsStatus,
	setIndividualEventDelete,
	setLinkedTripToTheEvent,
	setManageOrganizers,
	setMessageAllStatus,
	setPublishUpdateForAnEvent,
	updateAFieldForAnEvent,
	updateFieldForAnEvent,
	updateRSVPstatus,
} from 'redux/features/Events/Events.slice';
import { useNavigate } from 'react-router-dom';
import ToastService from 'services/ToastService';
import { Filter } from 'components/Filter/Filter';
import { participantStatusFilter, titleCase, titleCaseEveryWord } from '../../utils';
import { FilterNew } from 'components/Filter/Filter';
import { FourDoticonForTabs } from 'icons/FourDotIconForTabs';
import { DeleteBin } from 'icons/Delete';
import { OutlinedProfileIcon } from 'icons/ProfileIcon';
import moment from 'moment';
import { TrainIcon } from 'icons/Train';
import { BusIcon } from 'icons/Bus';
import dayjs from 'dayjs';
import { CabIcon } from 'icons/Cab';
import { Description } from 'icons/Description';
import { CreateTrip } from 'icons/CreateTrip';
import { Updates } from 'icons/Updates';
import { Participants } from 'icons/Participants';
import { Pin } from 'icons/Pin';
import { DeleteIcon } from 'icons/DeleteIcon';
import { Unpin } from 'icons/Unpin';

const EventDescription = (props) => {
	const { eventId,
		isEditing,
		setIsEditing,
		description,
		setDescription,
		document,
		setDocument,
		handleDescriptionEdit,
		quillRef

	} = props;

	const dispatch = useDispatch();

	const refQuill = useRef(null)

	const eventReducer = useSelector((state) => state.events);

	const { individualEventDetails, isLoading, updateFieldForAnEvent, userRoleInEvent } = eventReducer;

	// const [isEditing, setIsEditing] = useState(false);
	// const [description, setDescription] = useState(null);
	// const [document, setDocument] = useState(null);
	const [viewOnly, setviewOnly] = useState(true);
	const [onlyText, setOnlyText] = useState("")
	const [editState, setEditState] = useState(individualEventDetails?.description.length)

	useEffect(() => {
		setEditState(individualEventDetails?.description.length)
	}, [individualEventDetails?.description.length])

	const handleTextEditorChange = (content, delta, source, editor) => {
		setEditState(true)
		setDescription({
			data: editor.getContents(),
			document: content,
		});
		setOnlyText(editor.getText())
		// setDocument(content);
	};

	function isJson(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	useEffect(() => {
		if (individualEventDetails) {
			if (individualEventDetails.description?.length > 2) {
				if (isJson(individualEventDetails.description)) {
					let _localData = JSON.parse(
						individualEventDetails.description
					);
					if (setDescription) {
						setDescription({
							data: _localData.data,
							document: _localData.document,
						});
						setDocument(_localData.document);
						setviewOnly(true)

					}
				}
			}
		}
	}, [individualEventDetails]);

	useEffect(() => {

		if (updateFieldForAnEvent && updateFieldForAnEvent.status === 1) {
			setIsEditing(false);
			// setDescription
			dispatch(getEventDetails(eventId, true))
		}
	}, [updateFieldForAnEvent])

	const renderDescriptionView = () => {
		if (description && document) {
			return <div dangerouslySetInnerHTML={{ __html: document }} />;
		}
	};

	const handleSaveDescription = (key) => {

		if ((onlyText).trim().length < 1) {
			ToastService.error("Event description cannot be empty.")
		}
		else {

			dispatch(
				updateAFieldForAnEvent(eventId, {
					[key]: JSON.stringify(description),
				})
			);
		}
	};

	useEffect(() => {
		if (isEditing) {
			if (refQuill.current) {
				refQuill.current.focus();
			}
		}
	}, [isEditing])


	return (
		<>
			<div className="tw-mb-8 tw-border-[rgba(182,186,195,1)] tw-rounded-lg tw-border-solid tw-border" id="description-container-001" >
				<div className="tw-flex-row tw-justify-between tw-items-center tw-px-6 tw-py-4 tw-flex">
					<div className='tw-flex tw-items-center'>
						<span className='tw-mt-1 tw-mr-2'>
							<Description size="18" color="#6B7280" />
						</span>
						<div className="tw-m-0 tw-text-base tw-font-medium">Description</div>
					</div>
					{userRoleInEvent?.role === "organizer" ? <Button type="text" onClick={() => handleDescriptionEdit()}>
						{isEditing ? null : <>
							{description?.data?.length === 0
								? 'Add'
								: description
									? 'Edit'
									: 'Add'}
						</>}
					</Button> : null}
				</div>
				{
					individualEventDetails?.description?.length > 1 ?
						null : isEditing ?
							null : userRoleInEvent?.role === "organizer" ? <p className='tw-px-6 tw-text-color-gray-400'>Add a description so that participants know what to expect and when to travel</p> : <p className='tw-px-6 tw-text-color-gray-400'>No description added.</p>
				}
				{isLoading.individualEventDetails ? <div className='tw-m-5'>
					<Skeleton
						width={100}
						paragraph={true}
						active={true}
						height={1}
						size="large"
					/>
				</div>
					: (isEditing || viewOnly ? <div className='tw-mb-3 '>

						{!isEditing ? (
							<div className="tw-justify-between tw-px-6 tw-py-1 tw-break-all">
								{viewOnly ? renderDescriptionView() : null}
							</div>
						) : (
							<div className="tw-p-4 tw-px-6 tw-py-4">
								<ReactQuill
									style={{ height: "150px" }}
									ref={refQuill}
									className="tw-rounded-[5px]"
									value={editState ? description?.data : null}
									onChange={handleTextEditorChange}
								/>
								<br />
								<div className="tw-flex tw-mt-8 tw-justify-end">
									<Button
										type="default"
										className="tw-mr-4 tw-w-[96px]"
										onClick={() => handleDescriptionEdit('cancel')}
										disabled={
											isLoading.updateFieldForAnEvent
												? true
												: false
										}
									>
										Cancel
									</Button>
									<Button
										className="tw-w-[96px]"
										onClick={() =>
											handleSaveDescription('description')
										}
										disabled={
											isLoading.updateFieldForAnEvent
												? true
												: false
										}
									>
										Save
									</Button>
								</div>
							</div>
						)}
					</div> : null)}
			</div>
		</>
	);
};

const EventParticipants = (props) => {
	const { eventId, eventDetails, setTypeOfParticipantsView } = props
	const eventReducer = useSelector((state) => state.events);
	const authReducer = useSelector((state) => state.auth);
	const { RSVPcount, isLoading } = eventReducer;
	const { userDetails, manageOrganizersData } = eventReducer;

	const dispatch = useDispatch()
	const [localRSVPcount, setLocalRSVPcount] = useState(null);
	const [listOfOrganizers, setListOfOrganizers] = useState([])

	useEffect(() => {
		if (RSVPcount) {
			setLocalRSVPcount(RSVPcount);
		}
	}, [RSVPcount]);

	useEffect(() => {
		dispatch(manageOrganizers(
			eventId,
			userDetails?.company,
			null,
			'get_organizers'
		))
	}, [userDetails])

	useEffect(() => {
		if (manageOrganizersData && manageOrganizersData.type === "get_organizers") {

			if (Array.isArray(manageOrganizersData.data))
				setListOfOrganizers(manageOrganizersData.data)
		}
	}, [manageOrganizersData])
	let participantsArr = [
		{
			name: 'Attending',
			key: 'attending',
		},
		{
			name: 'Not attending',
			key: 'not_attending',
		},
		{
			name: 'Not responded',
			key: 'not_responded',
		},
		{
			name: 'Invited',
			key: 'invited',
		},
	];

	const displayOrganizers = (list) => {
		let org = [];
		org = list.map((_data) => titleCaseEveryWord(_data.organizer_name))
		return org.join()

	}

	const handleFilter = (e, key) => {
		let arr = ["ATTENDING", "NOT ATTENDING", "NOT RESPONDED", "INVITED"]
		setTypeOfParticipantsView(arr[key]);
	}

	return (
		<div className="tw-mb-8 tw-border-[rgba(182,186,195,1)] tw-rounded-lg tw-border-solid tw-border tw-cursor-pointer" onClick={(e) => {
			e.stopPropagation();
			props.setTabActiveKey('2')
		}}>
			<div className="tw-flex-row tw-flex tw-justify-between tw-items-center tw-px-6 tw-pt-4 tw-flex">
				<div className='tw-flex tw-items-center tw-pb-0'>
					<span className='tw-mt-1 tw-mr-2'>
						<Participants size="22" color="#6B7280" />
					</span>
					<div className="tw-m-0 tw-text-base tw-font-medium">Participants & Organizers</div>
				</div>

				<Button
					type="text"
					onClick={() => {
						// props.setDefaultKey('2');
						props.setTabActiveKey('2')
					}}
				>
					See all
				</Button>
			</div>
			<div className="tw-justify-between tw-px-6 tw-py-4 tw-flex tw-flex-row sm:tw-flex-nowrap tw-flex-wrap">
				{participantsArr.map((data, i) => {
					return (
						<div className="tw-border sm:tw-min-w-[23%] tw-min-w-[47%] sm:tw-mt-0 tw-mt-4 tw-text-center tw-p-6 tw-rounded-lg tw-border-solid tw-border-[#e5e9ed]" onClick={(e) => handleFilter(e, i)}>
							{isLoading?.RSVPcount || !RSVPcount ? (
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={16}
									size="small"
								/>
							) : localRSVPcount ? (
								<>
									<h3 className="tw-typography-body2Bold tw-my-2">
										{localRSVPcount[data.key] ||
											localRSVPcount[data.key] === 0
											? localRSVPcount[data.key]
											: null}
									</h3>
									<p className="tw-typography-body2 tw-text-gray-500">
										{data.name}
									</p>
								</>
							) : null}
						</div>
					);
				})}
			</div>
			{listOfOrganizers.length ? <div className='tw-px-6 tw-pb-4 tw-pt-0'>
				{/* <ul style={{ paddingInlineStart: "1rem" }}> */}
				<p className='tw-m-0'>Event organized by {displayOrganizers(listOfOrganizers)}</p>
				{/* </ul> */}
			</div> : null}
		</div>
	);
};

const CreateLinkTrip = (props) => {
	const { eventId, eventDetails } = props;
	const dispatch = useDispatch();
	const eventReducer = useSelector((state) => state.events);

	const {
		isLoading,
		addLinkedTrips,
		linkedTripToTheEvent,
		removeLinkedTripFromTheEvent,
	} = eventReducer;
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [localActiveTripsForUser, setLocalActiveTripsForUser] = useState([]);

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedTrips, setSelectedTrips] = useState([]);

	const [fetchedTrips, setFetchedTrips] = useState([]);

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);

		setSelectedRowKeys([])
		setSelectedTrips([])
	};

	useEffect(() => {
		if (addLinkedTrips && addLinkedTrips.status) {
			ToastService.successNotication(addLinkedTrips.message);
			setIsModalOpen(false);
			dispatch(setAddLinkedTrips(null));
			setSelectedRowKeys([]);
			setSelectedTrips([]);
			dispatch(fetchLinkedTripsToTheEvent(eventId));
		}
		else if (addLinkedTrips && !addLinkedTrips.status) {
			ToastService.error(addLinkedTrips.message)
			dispatch(setAddLinkedTrips(null));


		}
	}, [addLinkedTrips]);

	const handleTripLinkCreateDropdown = (e) => {
		// return
		if (e.key === "link") {
			showModal();
			dispatch(getActiveTripsForTheUser(eventId))
		}
		else {
			window.location.href = `${process.env.REACT_APP_ITILITE_BASE_URL}/create-new-trip?event_id=${eventId}&event_name=${eventDetails.name}`
		}
	}

	// https://stage.iltech.in/mercury/app/create-new-trip?event_id={event_id}&event_name={event_name}
	const items = [
		{
			label: <p className='tw-m-0'>Create new trip</p>,
			key: 'create',
			// icon: <UserOutlined />,
		},
		{
			label: <p className='tw-m-0'>Link existing trip</p>,
			key: 'link',
			// icon: <UserOutlined />,
		},
	];
	const menuProps = {
		items,
		onClick: (e) => handleTripLinkCreateDropdown(e),
	};



	const handleLinkTrips = () => {
		let tempData = selectedTrips.map((_data) => _data.tripId);
		dispatch(
			linkedSelectedTrips(eventId, {
				trip_id: tempData,
			})
		);
	};

	useEffect(() => {
		dispatch(fetchLinkedTripsToTheEvent(eventId));
	}, [removeLinkedTripFromTheEvent]);
	useEffect(() => {
		if (linkedTripToTheEvent) {
			setFetchedTrips(linkedTripToTheEvent.trips);
		}
	}, [linkedTripToTheEvent]);

	const modalStyle = {
		maxHeight: 'calc(100vh - 100px)', // adjust 100px as needed for your header and footer heights
		overflowY: 'auto',
	  };

	return (
		<div className="tw-mb-8 tw-border-[rgba(182,186,195,1)] tw-rounded-lg tw-border-solid tw-border">
			<div className="tw-flex-row tw-flex tw-justify-between tw-items-center tw-px-6 tw-pt-4">
				<div className='tw-flex tw-items-center'>
					<span className='tw-mt-1 tw-mr-2'>
						<CreateTrip size="22" color="#6B7280" />
					</span>
					<div className="tw-m-0 tw-text-base tw-font-medium">Linked trips</div>

				</div>
				{/* <Button type="primary">Create or link trip</Button> */}
				<Dropdown menu={menuProps} trigger={['click']}>
					<Button>
						Create or link trip
						<DownOutlined />
					</Button>
				</Dropdown>

				<Modal
					title={<span className='tw-text-lg tw-font-medium tw-text-[#111827]'>Link existing trip</span>}
					open={isModalOpen}
					onOk={handleLinkTrips}
					okText="Save"
					onCancel={handleCancel}
					width={960}
					// style={modalStyle}
					okButtonProps={{
						disabled:
							selectedRowKeys.length === 0 ||
							isLoading.addLinkedTrips,
						style: {
							backgroundColor: "#EC5D25",
							opacity: (selectedRowKeys.length === 0 || isLoading.addLinkedTrips) ? 0.5 : 1,
							color: "white"
						}
					}}
				>

					<p className='tw-text-gray-500 tw-text-font-size-30 tw-mt-2'>You can only link upcoming trips to the event. Users who are not added to your team on ITILITE cannot be added to event trips.</p>
					<LinkTripTable
						eventId={eventId}
						localActiveTripsForUser={localActiveTripsForUser}
						setLocalActiveTripsForUser={setLocalActiveTripsForUser}
						selectedRowKeys={selectedRowKeys}
						setSelectedRowKeys={setSelectedRowKeys}
						selectedTrips={selectedTrips}
						setSelectedTrips={setSelectedTrips}
					/>
				</Modal>
			</div>
			<div className="tw-justify-between tw-px-6 tw-pt-4 tw-flex-row">
				{isLoading.linkedTripToTheEvent ? (
					<Skeleton
						width={100}
						paragraph={true}
						active={true}
						height={1}
						size="large"
					/>
				) : (
					<>
						{fetchedTrips.length !== 0 ? <LinkedTripTable
							fetchedTrips={fetchedTrips}
							setFetchedTrips={setFetchedTrips}
							eventId={eventId}
						/> : <p className="tw-text-color-gray-400 tw-m-0 tw-mb-4">Create or link a trip, so that other attendees and organisers can view your travel plans</p>
						}
					</>
				)}
			</div>
		</div>
	);
};

const LinkedTripTable = (props) => {
	const { eventId, fetchedTrips, setFetchedTrips } = props;
	const dispatch = useDispatch();
	const eventReducer = useSelector((state) => state.events);

	const {
		isLoading,
		activeTripsForTheUser,
		linkedTripToTheEvent,
		removeLinkedTripFromTheEvent,
	} = eventReducer;

	const [localTripsForUser, setLocalTripsForUser] = useState([]);
	const [loading, setLoading] = useState(false);

	const items = [
		{
			key: '1',
			label: (
				<span onClick={(key) => console.log(key)}>
					Remove from event
				</span>
			),
		},
	];

	useEffect(() => {
		if (
			removeLinkedTripFromTheEvent &&
			removeLinkedTripFromTheEvent.status === 204
		) {
			// ToastService.successNotication("Trip removed successfully")
			dispatch(setLinkedTripToTheEvent(null));
		}
	}, [removeLinkedTripFromTheEvent]);

	const handleRemoveTripFromEvent = (e) => {
		dispatch(removeLinkedTripsToTheEvent(eventId, e.tripId));
	};

	const airplaneIcon = () => <>
		<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.5114 5L11.5052 5C12.0427 5 12.5662 5.17336 12.9976 5.49405C13.3318 5.74256 13.3342 6.24257 13.002 6.49388C12.5679 6.82229 12.0385 7 11.4941 7L8.5114 7C8.39995 7 8.29587 7.0557 8.23405 7.14843L5.09863 11.8516C5.03681 11.9443 4.93273 12 4.82128 12H4.12007C3.89255 12 3.7319 11.7771 3.80385 11.5613L5.32426 7L1.99093 6.65792L1.09137 8.48084C1.03522 8.59463 0.919341 8.66667 0.792455 8.66667H0.666341C0.482246 8.66667 0.333008 8.51743 0.333008 8.33333L0.333008 3.66667C0.333008 3.48257 0.482247 3.33333 0.666341 3.33333H0.793259C0.919726 3.33333 1.0353 3.4049 1.09166 3.51812L1.99093 5.32459L5.32426 5L3.80385 0.438743C3.7319 0.222899 3.89255 -9.94519e-09 4.12007 0L4.82128 3.06507e-08C4.93273 3.55224e-08 5.03681 0.0557 5.09863 0.148433L8.23405 4.85157C8.29587 4.9443 8.39995 5 8.5114 5Z" fill="#6B7280" />
		</svg>{" "}
	</>

	const hotel = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.666992 12.667V2.91699H1.66699V9.48366H7.55033V4.33366H12.917C13.5837 4.33366 14.1531 4.56977 14.6253 5.04199C15.0975 5.51421 15.3337 6.08366 15.3337 6.75033V12.667H14.3337V10.4837H1.66699V12.667H0.666992ZM4.50033 8.51699C4.00033 8.51699 3.58088 8.34755 3.24199 8.00866C2.9031 7.66977 2.73366 7.25033 2.73366 6.75033C2.73366 6.25033 2.9031 5.83088 3.24199 5.49199C3.58088 5.1531 4.00033 4.98366 4.50033 4.98366C5.00033 4.98366 5.41977 5.1531 5.75866 5.49199C6.09755 5.83088 6.26699 6.25033 6.26699 6.75033C6.26699 7.25033 6.09755 7.66977 5.75866 8.00866C5.41977 8.34755 5.00033 8.51699 4.50033 8.51699ZM8.55033 9.48366H14.3337V6.75033C14.3337 6.36144 14.1948 6.0281 13.917 5.75033C13.6392 5.47255 13.3059 5.33366 12.917 5.33366H8.55033V9.48366ZM4.50033 7.51699C4.71144 7.51699 4.89199 7.44199 5.04199 7.29199C5.19199 7.14199 5.26699 6.96144 5.26699 6.75033C5.26699 6.53921 5.19199 6.35866 5.04199 6.20866C4.89199 6.05866 4.71144 5.98366 4.50033 5.98366C4.28921 5.98366 4.10866 6.05866 3.95866 6.20866C3.80866 6.35866 3.73366 6.53921 3.73366 6.75033C3.73366 6.96144 3.80866 7.14199 3.95866 7.29199C4.10866 7.44199 4.28921 7.51699 4.50033 7.51699Z" fill="black" />
	</svg>

	const iconsGenerator = (type) => {
		switch (type) {
			case "flight":
				return airplaneIcon();
			case "train":
				return <TrainIcon size={20} />;
			case "bus":
				return <BusIcon size={20} />;
			case "cab":
				return <CabIcon size={20} className="tw-mt-1" />;
			case "accomodation":
				return hotel();
			default:
				return "";
		}
	}

	const columns = [
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Trip ID</span>),
			dataIndex: 'tripId',
			width: 120
		},
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Travel details</span>),
			dataIndex: 'travelDetails',
			render: (text, record) => (
				<div type="primary" className=''>

					{
						Array.isArray(record.icon) ?
							<span className='tw-inline-flex tw-items-center'>{record.icon?.map((_icon) => {
								return iconsGenerator(_icon)
							})}</span> : record.icon === "accomodation" ? hotel() : ""
					}
					{" "} {text}
				</div>
			),
		},
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Travel Date</span>),
			dataIndex: 'travelDate',
			width: 130
		},
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Status</span>),
			dataIndex: 'status',
			width: 170
		},
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Action</span>),
			dataIndex: '',
			key: 'x',
			render: (text, record) => (
				<Dropdown
					trigger={'click'}
					getPopupContainer={trigger => trigger.parentNode}
					className="tw-cursor-pointer"
					overlay={
						<Menu style={{ width: "120px" }}>
							<Menu.Item>
								<span
									onClick={() =>
										handleRemoveTripFromEvent(record)
									}
								>
									Remove trip
								</span>
							</Menu.Item>
						</Menu>
					}
				>
					<span>
						<svg
							width="18"
							height="5"
							viewBox="0 0 18 5"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M2 2.00195H2.01M9 2.00195H9.01M16 2.00195H16.01M3 2.00195C3 2.26717 2.89464 2.52152 2.70711 2.70906C2.51957 2.8966 2.26522 3.00195 2 3.00195C1.73478 3.00195 1.48043 2.8966 1.29289 2.70906C1.10536 2.52152 1 2.26717 1 2.00195C1 1.73674 1.10536 1.48238 1.29289 1.29485C1.48043 1.10731 1.73478 1.00195 2 1.00195C2.26522 1.00195 2.51957 1.10731 2.70711 1.29485C2.89464 1.48238 3 1.73674 3 2.00195ZM10 2.00195C10 2.26717 9.89464 2.52152 9.70711 2.70906C9.51957 2.8966 9.26522 3.00195 9 3.00195C8.73478 3.00195 8.48043 2.8966 8.29289 2.70906C8.10536 2.52152 8 2.26717 8 2.00195C8 1.73674 8.10536 1.48238 8.29289 1.29485C8.48043 1.10731 8.73478 1.00195 9 1.00195C9.26522 1.00195 9.51957 1.10731 9.70711 1.29485C9.89464 1.48238 10 1.73674 10 2.00195ZM17 2.00195C17 2.26717 16.8946 2.52152 16.7071 2.70906C16.5196 2.8966 16.2652 3.00195 16 3.00195C15.7348 3.00195 15.4804 2.8966 15.2929 2.70906C15.1054 2.52152 15 2.26717 15 2.00195C15 1.73674 15.1054 1.48238 15.2929 1.29485C15.4804 1.10731 15.7348 1.00195 16 1.00195C16.2652 1.00195 16.5196 1.10731 16.7071 1.29485C16.8946 1.48238 17 1.73674 17 2.00195Z"
								stroke="#111827"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
				</Dropdown>
			),
		},
	];


	const addIcons = (travelData, acc=[]) => {
		let iconsArray = travelData.map((_data) => {

			if (_data.mode?.toLowerCase())
				return _data.mode?.toLowerCase()
			else return ""
		})

		for(let i=0; i<acc.length; i++){
			iconsArray.push("accomodation");
		}
		return iconsArray
	}

	const travelDetailsFormatter = (mode, data) => {
		// if (mode.toLowerCase() === "cab") {
		// 	return `${data?.source} - ${data.destination}`
		// }
		// else {
			return `${data?.travel_city_from} - ${data?.travel_city_to}`
		// }
	}

	function formatDate(dateString) {
		const date = dayjs(dateString);
		return date.format('DD MMM YYYY');
	}


	const travelDateFormatter = (mode, data) => {
		// if (mode.toLowerCase() === "cab") {
		// 	return `${formatDate(data.start_date_time)} - ${formatDate(data.end_date_time)}`
		// }
		// else {
			return data?.from_date && data?.to_date ?
				`${data.from_date} - ${data?.to_date}` :
				`${data.from_date}`
		// }
	}

	useEffect(() => {
		if (linkedTripToTheEvent && linkedTripToTheEvent.trips) {
			let tempArr = linkedTripToTheEvent?.trips?.map((_data, index) => {

				if (_data.travel.length > 0) {
					return {
						key: index + 1,
						tripId: _data.trip_id,
						travelDetails: travelDetailsFormatter(_data.travel[0]?.mode, _data),
						travelDate: travelDateFormatter(_data.travel[0]?.mode, _data),
						status: _data.trip_status,
						voucher: '',
						icon: addIcons(_data.travel, _data?.accommodation)
					};
				}
				else {
					return {
						key: index + 1,
						tripId: _data.trip_id,
						travelDetails: _data.accomodation_location
							? `${_data.accomodation_location} `
							: '',
						travelDate: _data.from_date &&  _data.to_date
							? _data.from_date + "-" + _data.to_date
							: '',
						status: _data.trip_status,
						voucher: '',
						icon: "accomodation"
					};
				}
			});
			setLocalTripsForUser(tempArr);
		}
	}, [linkedTripToTheEvent]);

	return (
		<div>
			{/* <Input
				// value=''
				placeholder='Search event name or location'
				prefix={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8 2.00098C4.68629 2.00098 2 4.68727 2 8.00098C2 11.3147 4.68629 14.001 8 14.001C11.3137 14.001 14 11.3147 14 8.00098C14 4.68727 11.3137 2.00098 8 2.00098ZM0 8.00098C0 3.5827 3.58172 0.000976562 8 0.000976562C12.4183 0.000976562 16 3.5827 16 8.00098C16 9.84969 15.3729 11.5519 14.3199 12.9066L19.7071 18.2939C20.0976 18.6844 20.0976 19.3176 19.7071 19.7081C19.3166 20.0986 18.6834 20.0986 18.2929 19.7081L12.9056 14.3208C11.551 15.3739 9.84871 16.001 8 16.001C3.58172 16.001 0 12.4193 0 8.00098Z" fill="#6B7280" />
				</svg>
				}
				name="Trip search"
				className="tw-mb-4"
			// disabled = {false}
			/> */}
			{/* <Table columns={columns} dataSource={localActiveTripsForUser} /> */}
			<Table
				// rowSelection={rowSelection}
				columns={columns}
				dataSource={localTripsForUser}
			/>
		</div>
	);
};
export default LinkTripTable;

const UpdateSectionCard = (props) => {
	const { _data, handlePinDelete, userRoleInEvent } = props;

	const items = [
		{
			label: _data.pin ? 'Unpin' : 'Pin',
			key: _data.pin ? 'Unpin' : 'Pin',
			icon: _data.pin ? <Unpin size="14" color="#6B7280"/> : <Pin  size="12" color="#6B7280" />,
		},
		{
			label: 'Delete',
			key: 'delete',
			icon: <DeleteIcon size="13" color="#6B7280" />,
		},
	];
	const menuProps = {
		items,
		onClick: (e) => handlePinDelete(e, _data.id, _data.pin),
	};

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const formatDate = (data) => {

		const dateTimeString = data;
		const date = moment.utc(dateTimeString).local();

		const time = date.format("hh:mm A");
		const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

		const dayName = dayNames[date.day()];
		const dateNumber = date.date();
		const monthName = monthNames[date.month()];

		// const formattedDate = `${dayName}, ${dateNumber} ${monthName}`;
		const unpinFormattedDate = `${dateNumber} ${monthName}, ${time}`;

		return unpinFormattedDate;
	}

	return (
		<>
			<div className="tw-flex tw-flex-row tw-mb-4">
				<div className="tw-mr-2">
					{
						_data?.profile_photo?.icon_path ? <img
							src={_data.profile_photo.icon_path ? _data.profile_photo.icon_path : <OutlinedProfileIcon />}
							alt=""
							width={30}
							height={30}
							className="tw-mr-[-5px] tw-aspect-square tw-rounded-full"

						/> : <OutlinedProfileIcon size={38} />
					}
				</div>      
				<div className="tw-w-full">
					<div className="tw-flex tw-flex-row tw-items-center">
						<span className="tw-m-0 tw-mr-4 tw-text-sm tw-font-medium">
							{titleCaseEveryWord(_data.created_by?.name)}
						</span>
						<p className="tw-m-0 tw-mr-4 tw-text-gray-500 tw-text-xs tw-font-normal">{formatDate(_data.created_on)}</p>
						{_data.pin ? (
							<div className='tw-flex tw-items-center tw-mt-[5px]'>
								{/* <PushpinOutlined style={{ color: "red" }} twoToneColor="red" rotate={-44} /> */}
								<Icon

									element={<svg width="10" height="13" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M7.66683 5.00065V1.66732H8.3335C8.70016 1.66732 9.00016 1.36732 9.00016 1.00065C9.00016 0.633984 8.70016 0.333984 8.3335 0.333984H1.66683C1.30016 0.333984 1.00016 0.633984 1.00016 1.00065C1.00016 1.36732 1.30016 1.66732 1.66683 1.66732H2.3335V5.00065C2.3335 6.10732 1.44016 7.00065 0.333496 7.00065V8.33398H4.3135V13.0007L4.98016 13.6673L5.64683 13.0007V8.33398H9.66683V7.00065C8.56016 7.00065 7.66683 6.10732 7.66683 5.00065Z" fill="#EC5D25" />
									</svg>
									}
								/>
								<p
									type="text"
									className="tw-m-0  tw-flex tw-self-baseline tw-h-[22px] tw-ml-1 tw-text-red-500 tw-mt-[3px] tw-ml-1 tw-text-xs tw-font-normal"
								>
									Pinned
								</p>
							</div>
						) : null}
					</div>
					{isJsonString(_data.description) &&
						JSON.parse(_data.description)?.document ? (
						<div
							className={`tw-text-xs tw-font-normal tw-text-[#111827] tw-text-font-size-20 tw-break-all`}
							
							dangerouslySetInnerHTML={{
								__html: JSON.parse(_data.description)?.document,
							}}
						></div>
					) : null}
				</div>
				{userRoleInEvent?.role === "organizer" ? <div className='tw-ml-6'>
					<Dropdown
						menu={menuProps}
						id={_data.id}
						trigger={['click']}
						getPopupContainer={trigger => trigger.parentNode}
					>
						<Button type="text">
							<EllipsisOutlined style={{ fontSize: "24px", color: "black" }} />
						</Button>
					</Dropdown>
				</div> : null}
			</div>
			{_data.pin ? <hr className="tw-border-0 tw-border-b-[#ebebeb] tw-border-b tw-border-solid tw-w-full tw-mt-0 tw-mb-4" /> : null}
		</>
	);
};

const UpdateSection = (props) => {
	const { eventUpdatesData, eventId } = props;
	const dispatch = useDispatch();
	const eventReducer = useSelector((state) => state.events);
	const { postUpdateToAnEvent, pinDelete, userRoleInEvent } = eventReducer;
	const [value, setValue] = useState('');

	const [description, setDescription] = useState(null);
	const [updateChecks, setUpdateChecks] = useState({
		pin: false,
		publish_update: false,
	});
	const [document, setDocument] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [sortedEventData, setSortedEventData] = useState([]);


	const [onlyText, setOnlyText] = useState("")

	useEffect(() => {
		if (
			postUpdateToAnEvent?.key &&
			postUpdateToAnEvent.key === 'POSTING_UPDATES'
		) {
			if (postUpdateToAnEvent.payload?.status) {
				setIsModalOpen(false);
				setUpdateChecks({
					pin: false,
					publish_update: false,
				});
				setDescription(null);
				if (postUpdateToAnEvent.payload?.message) {
					ToastService.successNotication(postUpdateToAnEvent.payload?.message)
				}
				dispatch(setPublishUpdateForAnEvent(null))
				dispatch(publishAnUpdateForAnEvent(eventId, null, 'GET', true));
			}
			else {
				if (postUpdateToAnEvent.payload?.message) {
					ToastService.error(postUpdateToAnEvent.payload?.message)
				}
			}
		}
	}, [postUpdateToAnEvent]);

	useEffect(() => {
		if (
			pinDelete &&
			(pinDelete.status === 204 || pinDelete.status === 200)
		) {
			if(pinDelete.type === 'delete') 
			ToastService.successNotication("Message deleted successfully")
			else if( pinDelete.type === 'pin')
			ToastService.successNotication("Message pinned successfully")
			else if( pinDelete.type === 'unpin')
			ToastService.successNotication("Message unpinned successfully")
			
			dispatch(publishAnUpdateForAnEvent(eventId, null, 'GET', true));
		}
	}, [pinDelete]);

	useEffect(() => {
		if (eventUpdatesData) {

			let pinned = eventUpdatesData.filter((_data) => _data.pin);
			let unpinned = eventUpdatesData.filter((_data) => !_data.pin);
			let sortedArr = [...pinned, ...unpinned];
			setSortedEventData(sortedArr);
		}
	}, [eventUpdatesData]);

	const handlePinDelete = (e, id) => {
		if (e.key.toLowerCase() === 'pin') {
			dispatch(
				pinDeleteEventUpdate(eventId, id, 'pin', {
					pin: true,
				})
			);
		} else if (e.key.toLowerCase() === 'unpin') {
			dispatch(
				pinDeleteEventUpdate(eventId, id, 'pin', {
					pin: false,
				})
			);
		} else if (e.key === 'delete') {
			dispatch(pinDeleteEventUpdate(eventId, id, 'delete'));
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleEditUpdateDescription = (content, delta, source, editor) => {
		setDescription({
			data: editor.getContents(),
			document: content,
			text: editor.getText(),

		});
	};
	const saveUpdateDescription = () => {

		let _description = { ...description };


		if ((_description.text).trim().length < 1) {
			ToastService.error("Event update cannot be empty.")
		}
		else {
			delete _description['text'];
			dispatch(
				publishAnUpdateForAnEvent(
					eventId,
					{
						description: JSON.stringify(_description),
						pin: updateChecks.pin,
						publish_update: updateChecks.publish_update,
					},
					'POST'
				)
			);
		}

	};

	const handleCheckbox = (e) => {
		const { name } = e.target;
		setUpdateChecks({
			...updateChecks,
			[name]: e.target.checked,
		});
	};
	return (
		<div className="tw-mb-8 tw-border-[rgba(182,186,195,1)] tw-rounded-lg tw-border-solid tw-border">
			<div className="tw-flex-row tw-flex tw-justify-between tw-items-center tw-px-6 tw-py-4">
				<div className='tw-flex tw-items-center'>
					<span className='tw-mt-1 tw-mr-2'>
						<Updates size="18" color="#6B7280" />
					</span>
					<div className="tw-m-0 tw-text-base tw-font-medium">Updates</div>

				</div>
				{userRoleInEvent?.role === "organizer" ? <Button type="text" onClick={showModal}>
					New update
				</Button> : null}

				<Modal
					title="Post an update"
					open={isModalOpen}
					onOk={saveUpdateDescription}
					height={500}
					okText="Post"
					onCancel={handleCancel}
					okButtonProps={{
						disabled:
							description?.text?.length < 2 ||
								description === null
								? true
								: false,
						style: {
							backgroundColor: "#EC5D25",
							opacity: (description?.text?.length < 2 || description === null ? true : false) ? 0.5 : 1,
							color: "white"
						}
					}}
				>
					<ReactQuill
						style={{ height: "150px" }}
						theme="snow"
						value={description?.data}
						onChange={handleEditUpdateDescription}
						className="tw-mt-8"

					/>
					<label className="tw-flex tw-justify-between tw-cursor-pointer tw-w-full tw-mt-16	">
						<div className="tw-mr-[10px] tw-flex tw-items-center">
							<input
								type="checkbox"
								name="pin"
								id=""
								checked={updateChecks.pin}
								onChange={handleCheckbox}
							/>
						</div>
						<div className="tw-flex-grow">
							<p className="tw-m-0">Pin this update to the top</p>
						</div>
					</label>
					<label className="tw-flex tw-justify-between tw-cursor-pointer tw-w-full tw-mt-4 tw-items-start">
						<div className="tw-mr-[10px] tw-flex tw-items-center">
							<input
								type="checkbox"
								name="publish_update"
								id=""
								checked={updateChecks.publish_update}
								onChange={handleCheckbox}
							/>
						</div>
						<div className="tw-flex-grow">
							<p className="tw-m-0">
								Send this update to all invitees over email as well
							</p>
						</div>
					</label>
				</Modal>
			</div>
			<div className="tw-justify-between tw-px-6 tw-pt-4 tw-flex tw-flex-col">
				{sortedEventData.length !== 0 ? sortedEventData?.map((_data) => {
					return (
						<UpdateSectionCard
							_data={_data}
							handlePinDelete={handlePinDelete}
							key={_data.id}
							userRoleInEvent={userRoleInEvent}
						/>
					);
				}) : userRoleInEvent?.role === "organizer" ? <div className="tw-text-color-gray-400 tw-mb-4">Share the latest details and updates with participants</div> : <div className="tw-text-color-gray-400 tw-mb-4">No updates added.</div>}
			</div>
		</div>
	);
};

// Participants

const EventParticipantsSmall = (props) => {
	const { eventId, typeOfParticipantsView, setTypeOfParticipantsView,
		appliedFilter,
		setAppliedFilter,
	} = props;
	const eventReducer = useSelector((state) => state.events);
	const dispatch = useDispatch();

	const { RSVPcount } = eventReducer;

	useEffect(() => {
		if (RSVPcount === null) {
			dispatch(getRSVPcount(eventId, true));
		}
	}, [RSVPcount]);

	const handleTypeOfParticipantViewFromOverview = (type) => {
		let filter;
		if (appliedFilter) {
			filter = {
				status: type,
				filter: appliedFilter,
			};
		}
		else {
			filter = {
				status: type,
				filter: {
					linked_trips: ['linked', 'not linked'],
					trip_status: [],
				},
			};
		}
		dispatch(
			getCurrentParticipantsForEvent(
				eventId,
				filter,
				type,
				true
			)
		);

		setTypeOfParticipantsView(type);
	};

	const handleFilterOverview = () => {
		handleTypeOfParticipantViewFromOverview(typeOfParticipantsView);
	}


	const handleTypeOfParticipantView = (type) => {

		let filter;
		if (appliedFilter) {
			filter = {
				status: type.target.value,
				filter: appliedFilter,
			};
		}
		else {
			filter = {
				status: type.target.value,
				filter: {
					linked_trips: ['linked', 'not linked'],
					trip_status: [],
				},
			};
		}
		dispatch(
			getCurrentParticipantsForEvent(
				eventId,
				filter,
				type.target.value,
				true
			)
		);

		setTypeOfParticipantsView(type.target.value);
	};

	useEffect(() => {
		handleFilterOverview();
	}, [typeOfParticipantsView])

	return (
		<div className="tw-mb-8 tw-border-[rgba(182,186,195,1)] tw-p-[0px]  ">
			<div className="tw-justify-between tw-flex tw-flex-row sm:tw-flex-nowrap tw-flex-wrap sm:tw-px-0 tw-px-4">
				<label className={`tw-border sm:tw-w-[20%]  tw-w-[45%] tw-text-center tw-p-6 tw-rounded-lg tw-border-solid ${typeOfParticipantsView === "ATTENDING" ? "tw-border-[#B6BAC3] tw-border-2 tw-opacity-100 tw-bg-gray-100" : "tw-border-[#d3d5d9] tw-opacity-50"} tw-cursor-pointer`}>
					{/* <h3>3333</h3> */}
					<h3>{RSVPcount?.attending}</h3>
					<p className='tw-text-gray-500'>Attending</p>
					<input
						className='tw-hidden'
						type="radio"
						value="ATTENDING"
						checked={typeOfParticipantsView === "ATTENDING"}
						name="data-col"
						onChange={(e) => handleTypeOfParticipantView(e)}
						id="id_1"
					/>
				</label>
				<label className={`tw-border sm:tw-w-[20%] tw-w-[45%] tw-text-center tw-p-6 tw-rounded-lg tw-border-solid ${typeOfParticipantsView === "NOT ATTENDING" ? "tw-border-[#B6BAC3] tw-border-2 tw-opacity-100 tw-bg-gray-100" : "tw-border-[#d3d5d9] tw-opacity-50"} tw-cursor-pointer`}>
					<h3>{RSVPcount?.not_attending}</h3>
					<p className='tw-text-gray-500'>Not attending</p>
					<input
						className='tw-hidden'
						type="radio"
						value="NOT ATTENDING"
						checked={typeOfParticipantsView === "NOT ATTENDING"}
						name="data-col"
						id="id_2"
						onChange={(e) => handleTypeOfParticipantView(e)}
					/>
				</label>
				<label className={`tw-border sm:tw-w-[20%] tw-w-[45%] sm:tw-mt-0 tw-mt-4 tw-text-center tw-p-6 tw-rounded-lg tw-border-solid ${typeOfParticipantsView === "NOT RESPONDED" ? "tw-border-[#B6BAC3] tw-border-2 tw-opacity-100 tw-bg-gray-100" : "tw-border-[#d3d5d9] tw-opacity-50"} tw-cursor-pointer`}>
					<h3>{RSVPcount?.not_responded}</h3>
					<p className='tw-text-gray-500'>Not responded</p>
					<input
						type="radio"
						className='tw-hidden'
						value="NOT RESPONDED"
						name="data-col"
						checked={typeOfParticipantsView === "NOT RESPONDED"}
						id="id_3"
						onChange={(e) => handleTypeOfParticipantView(e)}
					/>
				</label>
				<label className={`tw-border sm:tw-w-[20%] tw-w-[45%] sm:tw-mt-0 tw-mt-4 tw-text-center tw-p-6 tw-rounded-lg tw-border-solid ${typeOfParticipantsView === "INVITED" ? "tw-border-[#B6BAC3] tw-border-2 tw-opacity-100 tw-bg-gray-100" : "tw-border-[#d3d5d9] tw-opacity-50"} tw-cursor-pointer`}>
					<h3>{RSVPcount?.invited}</h3>
					<p className='tw-text-gray-500'>All invitees</p>
					<input
						type="radio"
						value="INVITED"
						className='tw-hidden'
						checked={typeOfParticipantsView === "INVITED"}
						name="data-col"
						id="id_4"
						onChange={(e) => handleTypeOfParticipantView(e)}
					/>
				</label>
			</div>
		</div>
	);
};

const ParticipantCard = (props) => {
	const { _data, handleParticipants } = props;

	const eventReducer = useSelector(state => state.events);
	const authReducer = useSelector(state => state.auth);
	const { userDetails } = authReducer;
	const { userRoleInEvent } = eventReducer;
	const [showTripDetail, setShowTripDetail] = useState(false);
	const [tripData, setTripData] = useState([]);

	let items = []
	if (_data.is_organizer) {
		items = [
			{
				label: _data.is_organizer ? 'Remove organizer' : 'Add as an organizer',
				key: _data.is_organizer ? 'remove_organizers' : 'make_organizer',
				icon: <UserOutlined />,
			},
			// {
			// 	label: 'Remove participant',
			// 	key: 'remove_participant',
			// 	icon: <UserOutlined />,
			// },
		];

	}
	else {
		items = [
			{
				label: _data.is_organizer ? 'Remove organizer' : 'Add as an organizer',
				key: _data.is_organizer ? 'remove_organizers' : 'make_organizer',
				icon: <UserOutlined />,
			},
			{
				label: 'Remove participant',
				key: 'remove_participant',
				icon: <UserOutlined />,
			},
		];
	}

	if (_data.is_organizer) {

	}
	const menuProps = {
		items,
		onClick: (e) => console.log(e),
	};
	const menuPropsForDashed = {
		items,
		onClick: (e) => handleParticipants(_data, e),
	};
	const menuPropsForForOrganizer = {
		items,
		onClick: (e) => handleParticipants(_data, e),
	};

	const addIcons = (travelData, acc=[]) => {
		let iconsArray = travelData.map((_data) => {

			if (_data.mode?.toLowerCase())
				return _data.mode?.toLowerCase()
			else return ""
		})

		for(let i=0; i<acc.length; i++){
			iconsArray.push("accomodation");
		}

		return iconsArray
	}

	useEffect(() => {
		if (_data.trips) {
			let tempArr = [..._data.trips]
			let newArr = tempArr.map((data, i) => {
				if (data.travel?.length) {
					return {
						trip_id: data.trip_id,
						trip_status: data.trip_status,
						travelDetails: `${data.travel_city_from} - ${data.travel_city_to}`,
						travelDate: data?.from_date && data?.to_date ?
									`${data.from_date} - ${data.to_date}` :
									`${data.from_date}`,
						icon: addIcons(data.travel, data?.accommodation)
					}
				}
				else if (data.accommodation?.length) {
					return {
						trip_id: data.trip_id,
						trip_status: data.trip_status,
						travelDetails: `${data.accomodation_location}`,
						travelDate: data?.from_date && data?.to_date ?
									`${data.from_date} - ${data.to_date}` :
									`${data.from_date}`,
						icon: `${data.accommodation[0]?.location ? "accomodation" : ""}`
					}
				}
			})
			setTripData(newArr)

		}
	}, [_data.trips])

	const airplaneIcon = () => <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.5114 5L11.5052 5C12.0427 5 12.5662 5.17336 12.9976 5.49405C13.3318 5.74256 13.3342 6.24257 13.002 6.49388C12.5679 6.82229 12.0385 7 11.4941 7L8.5114 7C8.39995 7 8.29587 7.0557 8.23405 7.14843L5.09863 11.8516C5.03681 11.9443 4.93273 12 4.82128 12H4.12007C3.89255 12 3.7319 11.7771 3.80385 11.5613L5.32426 7L1.99093 6.65792L1.09137 8.48084C1.03522 8.59463 0.919341 8.66667 0.792455 8.66667H0.666341C0.482246 8.66667 0.333008 8.51743 0.333008 8.33333L0.333008 3.66667C0.333008 3.48257 0.482247 3.33333 0.666341 3.33333H0.793259C0.919726 3.33333 1.0353 3.4049 1.09166 3.51812L1.99093 5.32459L5.32426 5L3.80385 0.438743C3.7319 0.222899 3.89255 -9.94519e-09 4.12007 0L4.82128 3.06507e-08C4.93273 3.55224e-08 5.03681 0.0557 5.09863 0.148433L8.23405 4.85157C8.29587 4.9443 8.39995 5 8.5114 5Z" fill="#6B7280" />
	</svg>

	const hotel = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.666992 12.667V2.91699H1.66699V9.48366H7.55033V4.33366H12.917C13.5837 4.33366 14.1531 4.56977 14.6253 5.04199C15.0975 5.51421 15.3337 6.08366 15.3337 6.75033V12.667H14.3337V10.4837H1.66699V12.667H0.666992ZM4.50033 8.51699C4.00033 8.51699 3.58088 8.34755 3.24199 8.00866C2.9031 7.66977 2.73366 7.25033 2.73366 6.75033C2.73366 6.25033 2.9031 5.83088 3.24199 5.49199C3.58088 5.1531 4.00033 4.98366 4.50033 4.98366C5.00033 4.98366 5.41977 5.1531 5.75866 5.49199C6.09755 5.83088 6.26699 6.25033 6.26699 6.75033C6.26699 7.25033 6.09755 7.66977 5.75866 8.00866C5.41977 8.34755 5.00033 8.51699 4.50033 8.51699ZM8.55033 9.48366H14.3337V6.75033C14.3337 6.36144 14.1948 6.0281 13.917 5.75033C13.6392 5.47255 13.3059 5.33366 12.917 5.33366H8.55033V9.48366ZM4.50033 7.51699C4.71144 7.51699 4.89199 7.44199 5.04199 7.29199C5.19199 7.14199 5.26699 6.96144 5.26699 6.75033C5.26699 6.53921 5.19199 6.35866 5.04199 6.20866C4.89199 6.05866 4.71144 5.98366 4.50033 5.98366C4.28921 5.98366 4.10866 6.05866 3.95866 6.20866C3.80866 6.35866 3.73366 6.53921 3.73366 6.75033C3.73366 6.96144 3.80866 7.14199 3.95866 7.29199C4.10866 7.44199 4.28921 7.51699 4.50033 7.51699Z" fill="black" />
	</svg>

	const iconsGenerator = (type) => {
		switch (type) {
			case "flight":
				return airplaneIcon();
			case "train":
				return <TrainIcon size={20} />;
			case "bus":
				return <BusIcon size={20} />;
			case "cab":
				return <CabIcon size={20} className="tw-mt-1" />;
			case "accomodation":
				return hotel();
			default:
				return "";
		}
	}


	const columns = [
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Trip ID</span>),
			dataIndex: 'trip_id',
		},
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Travel details</span>),
			dataIndex: 'travelDetails',
			render: (text, record) => (
				<span type="primary">
					{
						Array.isArray(record.icon) ?
							record.icon?.map((_icon) => {
								return iconsGenerator(_icon)
							}) : record.icon === "accomodation" ? hotel() : ""
					}
					{" "}{text}
					{/* {text} {record.icon === "flight" ? airplaneIcon() : hotel()} */}
				</span>
			),

		},
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Travel Date</span>),
			dataIndex: 'travelDate',
		},
		{
			title: () => (<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Status</span>),
			dataIndex: 'trip_status',
		},
		// {
		// 	title: "Vouchers",
		// 	dataIndex: "vouchers"
		// }
	];

	return (

		<li className="">
			<div className='tw-flex tw-mb-[20px] tw-h-[46px]'>
				<label className="tw-flex tw-justify-between tw-cursor-pointer tw-w-full">
					<div className="tw-h-full tw-w-[36px] tw-mr-[10px]  tw-flex tw-items-center tw-justify-center">
						{
							_data.user_details.profile_picture.icon_path ? <img
								src={_data.user_details.profile_picture.icon_path ? _data.user_details.profile_picture.icon_path : <OutlinedProfileIcon />}
								alt=""
								width={30}
								height={30}
								className="tw-mr-[-5px] tw-aspect-square tw-rounded-full"

							/> : <OutlinedProfileIcon size={60} />
						}
						{/* <img src={avatar} alt="" /> */}
						{/* <img src={_data.profile_photo.icon_path ? _data.profile_photo.icon_path  :  avatar} alt="" /> */}
					</div>
					<div className="tw-flex-grow">
						<p className="tw-m-0">{titleCaseEveryWord(_data.user_details.name)} {_data.is_organizer ? <span className='tw-rounded tw-bg-orange-200 tw-px-1 tw-text-xs tw-text-orange-700'>Organizer</span> : null}</p>
						<span className="tw-text-gray-500">
							{_data.user_details.email}
						</span>
					</div>
					{
						_data.trips.length ? showTripDetail ? <span onClick={() => setShowTripDetail(state => !state)}>Trip details<UpOutlined className="tw-ml-4" /></span> : <span onClick={() => setShowTripDetail(state => !state)} >Trip details <DownOutlined className="tw-ml-4" /></span> : null
					}

					<div className="tw-ml-[10px] tw-flex tw-items-center">
						{/* <Dropdown menu={menuProps} trigger={['click']}>
				<Button type="text">
				Trip details
					<DownOutlined />
					</Button>
				</Dropdown> */}
					</div>
				</label>
				{userRoleInEvent?.role === "organizer" ? <>
					<Dropdown menu={menuPropsForDashed} overlayStyle={{width: "200px"}} trigger={['click']} getPopupContainer={trigger => trigger.parentNode}
					>
						<Button type="text">
							<EllipsisOutlined style={{ fontSize: "24px", color: "black" }} className="tw-ml-4" />
						</Button>
					</Dropdown>
				</> : null}

			</div>
			{
				showTripDetail ? <Table
					// rowSelection={rowSelection}
					columns={columns}
					dataSource={tripData}
				/> : null
			}
		</li>
	);
};

const ParticipantListEdit = (props) => {
	const dispatch = useDispatch();

	let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

	const {
		eventId,
		individualEventDetails,
		typeOfParticipantsView,
		setTypeOfParticipantsView, appliedFilter, setAppliedFilter
	} = props;
	const eventReducer = useSelector((state) => state.events);
	const authReducer = useSelector((state) => state.auth);

	const {
		messageAll,
		isLoading,
		listOfEmployeesForClient,
		currentStatusOfParticipants,
		manageOrganizersData,
		removeParticipants,
		userRoleInEvent
	} = eventReducer;
	const { userDetails } = authReducer;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [paginationData, setPaginationData] = useState({
		data: [],
		totalPage: 0,
		current: 1,
		minIndex: 0,
		maxIndex: 0,
		pageSize: 10,
	});

	const [message, setMessage] = useState('');
	const [messageData, setMessageData] = useState('');
	const [employeeList, setEmployeeList] = useState({
		attending: [],
		not_attending: [],
		not_responsed: [],
		invited: [],
	});



	let dummyFilter = {
		status: typeOfParticipantsView,
		filter: {
			linked_trips: ['linked', 'not linked'],
			trip_status: [],
		},
	};

	useEffect(() => {
		if (appliedFilter) {
			dispatch(
				getCurrentParticipantsForEvent(eventId, appliedFilter, typeOfParticipantsView, true)
			);
		}
		else {
			dispatch(
				getCurrentParticipantsForEvent(eventId, dummyFilter, typeOfParticipantsView, true)
			);
		}
	}, []);

	// TODO: REVISIT
	useEffect(() => {
		if (
			currentStatusOfParticipants &&
			currentStatusOfParticipants.status === 200
		) {
			setEmployeeList({
				...employeeList,
				[currentStatusOfParticipants.key]:
					currentStatusOfParticipants?.payload?.participants,
			});

			setPaginationData({
				current: 1,
				pageSize: 10,
				// ...paginationData,
				data: currentStatusOfParticipants?.payload?.participants,
				totalPage:
					currentStatusOfParticipants?.payload?.participants?.length /
					paginationData.pageSize,
				// data: currentStatusOfParticipants?.payload?.participants,
				// totalPage: currentStatusOfParticipants?.payload?.participants.length / pageSize,
				minIndex: 0,
				maxIndex: paginationData.pageSize,
			});
		}
	}, [currentStatusOfParticipants]);

	useEffect(() => {
		if (messageAll) {
			if (messageAll.status) {
				setIsModalOpen(false);
				setMessageData('');
				setMessage('');
				ToastService.successNotication(messageAll.message);
				dispatch(setMessageAllStatus(null));
			}
			else {
				ToastService.error(messageAll.message);
				dispatch(setMessageAllStatus(null));

			}
		}
	}, [messageAll]);

	useEffect(() => {
		if (manageOrganizersData) {
			if (
				manageOrganizersData.type === 'add_organizers' &&
				manageOrganizersData.data.status
			) {
				ToastService.successNotication(
					manageOrganizersData.data.message
				);
				if (appliedFilter) {
					dispatch(
						getCurrentParticipantsForEvent(eventId, appliedFilter, typeOfParticipantsView, false)
					);
				}
				else {
					dispatch(
						getCurrentParticipantsForEvent(eventId, dummyFilter, typeOfParticipantsView, false)
					);
				}
			}
			else if (manageOrganizersData.type === 'remove_organizers' &&
				manageOrganizersData.data.status
			) {
				ToastService.successNotication(
					manageOrganizersData.data.message
				);

				if (appliedFilter) {
					dispatch(
						getCurrentParticipantsForEvent(eventId, appliedFilter, typeOfParticipantsView, false)
					);
				}
				else {
					dispatch(
						getCurrentParticipantsForEvent(eventId, dummyFilter, typeOfParticipantsView, false)
					);
				}
			}

			else if (
				manageOrganizersData.type === 'add_organizers' &&
				!manageOrganizersData.data.status
			) {
				ToastService.error(manageOrganizersData.data.message);
			}
			dispatch(setManageOrganizers(null));
		}
		if (
			removeParticipants &&
			removeParticipants.key === 'remove_participant' &&
			removeParticipants.status === 204
		) {
			ToastService.successNotication('Participant removed successfully');
			dispatch(
				getCurrentParticipantsForEvent(
					eventId,
					dummyFilter,
					typeOfParticipantsView,
					false
				)
			);
			dispatch(setManageOrganizers(null));
			dispatch(getRSVPcount(eventId, false));
		}
	}, [manageOrganizersData, removeParticipants]);

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleSendMessage = () => {
		dispatch(
			sendMessageAll(eventId, {
				status: typeOfParticipantsView,
				message_to_all_participants:
					messageData === 'messagetoAllAttendingParticipants'
						? true
						: false,
				message: message,
			})
		);
	};
	const cancelSendingMessage = () => {
		setIsModalOpen(false);
		setMessageData('');
		setMessage('');
	};

	const handleParticipants = (data, e) => {

		if (e.key === 'make_organizer') {
			dispatch(
				manageOrganizers(
					eventId,
					data.id,
					{
						organizer: [
							{
								id: data.id,
								email: data.user_details.email,
							},
						],
					},
					'add_organizers'
				)
			);
		} else if (e.key === 'remove_organizers') {
			dispatch(
				manageOrganizers(
					eventId,
					data.id,
					null,
					'remove_organizers'
				)
			);
		} else if (e.key === 'remove_participant') {
			dispatch(removeParticipantsFromEvent(eventId, data.id));
		}
	};

	const items = [
		{
			label: 'Add as an organizer',
			key: 'make_organizer',
			icon: <UserOutlined />,
		},
		{
			label: 'Remove participant',
			key: 'remove_participant',
			icon: <UserOutlined />,
		},
	];
	// const menuProps = {
	// 	items,
	// 	onClick: (e) => console.log(e),
	// };
	// const menuPropsForDashed = {
	// 	items,
	// 	onClick: (e) => handleParticipants(e),
	// };

	const handleTextChange = (e) => {
		setMessage(e.target.value);
	};
	const handleMessageDataChange = (e) => {
		setMessageData(e.target.value);
	};

	const handlePaginationChange = (page, _pagesize) => {
		setPaginationData({
			...paginationData,
			current: page,
			minIndex: (page - 1) * _pagesize,
			maxIndex: page * _pagesize,
			pageSize: _pagesize,
		});
	};

	const handleShowSizeChange = (event, pageSize) => {
		console.log(event);
	};

	const handleParticipantsSearch = (e) => {
		let val = e.target.value;
		let tempArr = employeeList[typeOfParticipantsView].filter(
			(_data) =>
				_data.user_details?.name
					.toLowerCase()
					.includes(val.toLowerCase()) ||
				_data.user_details?.email
					.toLowerCase()
					.includes(val.toLowerCase())
		);
		setPaginationData({
			...paginationData,
			data: tempArr,
			totalPage: tempArr.length / paginationData.pageSize,
			// data: currentStatusOfParticipants?.payload?.participants,
			// totalPage: currentStatusOfParticipants?.payload?.participants.length / pageSize,
			minIndex: 0,
			maxIndex: paginationData.pageSize,
		});
	};

	const onApply = ({ filteredObject }) => {
		setAppliedFilter(filteredObject);
		dispatch(
			getCurrentParticipantsForEvent(eventId, {
				filter: filteredObject,
				status: typeOfParticipantsView
			}, typeOfParticipantsView, true)
		);
	};
	const handleRemoveFilter = (type, clearAll) => {

		// return
		let filter = { ...appliedFilter };
		if (type === "linked_trips") {
			filter.linked_trips = []
			dispatch(
				getCurrentParticipantsForEvent(eventId, {
					filter: filter,
					status: typeOfParticipantsView
				}, typeOfParticipantsView, true)
			);
			setAppliedFilter(filter)
		}
		else if (type === "trip_status") {
			filter.trip_status = []
			dispatch(
				getCurrentParticipantsForEvent(eventId, {
					filter: filter,
					status: typeOfParticipantsView
				}, typeOfParticipantsView, true)
			);
			setAppliedFilter(filter)
		}
		else {
			dispatch(
				getCurrentParticipantsForEvent(eventId, {
					filter: dummyFilter,
					status: typeOfParticipantsView
				}, typeOfParticipantsView, true)
			);
			setAppliedFilter(null)
		}
	};

	const onCancel = () => {
		setAppliedFilter(null)
	};

	const mapBookingStatus = (_applied_filter) => {
		let bookingStatus = [..._applied_filter?.trip_status]
		let bookingStatusDataWithNames = [...participantStatusFilter.trip_status]

		const bookingStatusDataObject = bookingStatusDataWithNames.reduce((result, item) => {
			result[item.id] = item.name;
			return result;
		}, {});

		let newArr = bookingStatus.map((_data) => bookingStatusDataObject[_data]);
		let arrString = newArr.map((_data) => _data);
		return arrString.map((_data, index) => {
			if (index < arrString.length - 1) {
				return <><b>{_data}</b> or </>;
			} else {
				return <b>{_data}</b>;
			}
		})
	}

	const checkDisabled = (a, b, c) => {
		if (a === 0 || b === 0 || c)
			return true
		else return false;
	}

	const getText = (type, category=1) =>{
		if(type === "invited"){
			return "Email all invitees"
		}
		else if(type === "not responded"){
			if(category == 1) return "Email all invitees who have not yet RSVPed"
			if(category == 2) return "Email all invitees who have not yet RSVPed and have not yet booked a trip for this event"
		}
		else if(type === "not attending"){
			return "Email all invitees who have RSVPed \"No\""
		}
		else if(type === "attending"){
			if(category == 1) return "Email all invitees who have RSVPed \"Yes\""
			if(category == 2) return "Email all invitees who have RSVPed \"Yes\" but have not yet booked a trip for this event"
		}
	}

	return (
		<div className="tw-flex">
			<div className="tw-w-full">
				<Input
					size="large"
					placeholder="Search by name or email address"
					className="tw-mb-[15px]"
					prefix={isFirefox ? "" : <SearchOutlined />}
					onChange={(e) => handleParticipantsSearch(e)}
				/>
				<div className='tw-flex tw-flex-col tw-items-start'>
					<div className='tw-flex tw-items-center'>
						{appliedFilter?.linked_trips?.length !== 0 && appliedFilter ?
							<div className='tw-flex'>
								<div className='tw-ml-2'>
									Trip link status is {appliedFilter?.linked_trips?.includes("linked") ?
										appliedFilter.linked_trips.includes("not linked") ? <><Pill text="Linked" /> or <Pill text="Not linked" /></> :
											<Pill text="Linked" /> : <Pill text="Not Linked" />}
								</div>
								<span className='tw-cursor-pointer tw-ml-2' onClick={() => handleRemoveFilter("linked_trips", false)}>{<CloseOutlined />}</span>
							</div> : null}


						{appliedFilter?.trip_status?.length !== 0 && appliedFilter ? <div className='tw-flex'><div className='tw-ml-2'>
							Trip booking status {mapBookingStatus(appliedFilter)}
						</div><span className='tw-cursor-pointer tw-ml-2' onClick={() => handleRemoveFilter("trip_status", false)}>{<CloseOutlined />}</span></div> : null}
						{
							appliedFilter?.linked_trips?.length || appliedFilter?.trip_status?.length ? <div className='tw-cursor-pointer tw-text-color-icon-info tw-mb-2 tw-mt-2 tw-ml-2' onClick={() => handleRemoveFilter(null, true)}>Clear all</div> : null
						}
					</div>
					<Filter
						dataObject={participantStatusFilter}
						onApply={onApply}
						onCancel={onCancel}
					/>
				</div>
				<div>
					<ul className="tw-list-none tw-p-0">
						<li className="tw-flex tw-items-center tw-justify-between tw-mb-[20px]">
							<div className="tw-opacity-0">Showing all 500</div>
							{userRoleInEvent?.role === "organizer" ? <Button type="text" onClick={showModal}>
								Send an email
							</Button> : null}

							<Modal
								title={<span className='tw-text-base tw-font-medium tw-text-[#111827]'>Send an email</span>}
								open={isModalOpen}
								onOk={handleSendMessage}
								okText={"Send"}
								onCancel={cancelSendingMessage}
								okButtonProps={{
									disabled: checkDisabled(messageData.length, message.length, isLoading.messageAll),
									style: {
										backgroundColor: "#EC5D25",
										opacity: checkDisabled(messageData.length, message.length, isLoading.messageAll) ? 0.5 : 1,
										color: "white"
									}
								}}
							// okButtonProps={{ disabled: true,  }}
							>
								{/* <ReactQuill theme="snow" value='' className="tw-mt-8" /> */}
								<textarea
									onChange={handleTextChange}
									value={message}
									row="10"
									className='tw-w-full tw-h-32 tw-rounded-md tw-mt-4'
								/>
								{/* <p className="tw-mb-6 tw-mt-2 tw-typography-caption1">
									This message shall be sent by email
								</p> */}
								<label className="tw-flex tw-justify-between tw-cursor-pointer tw-w-full tw-mt-4 tw-items-start">
									<div className="tw-mr-[5px] tw-flex tw-items-center">
										<input
											type="radio"
											value="messagetoAllAttendingParticipants"
											name="messageData"
											checked={messageData === "messagetoAllAttendingParticipants"}
											id=""
											onChange={handleMessageDataChange}
										/>
									</div>
									<div className="tw-flex-grow">
										<p className="tw-m-0">
										{getText(typeOfParticipantsView.toLowerCase(), 1)}
										</p>
									</div>
								</label>
								{/* {typeOfParticipantsView !== "NOT ATTENDING" && typeOfParticipantsView !== "INVITED" ? <label className="tw-flex tw-justify-between tw-cursor-pointer tw-w-full tw-mt-4 tw-items-start">
									<div className="tw-mr-[5px] tw-flex tw-items-center">
										<input
											type="radio"
											value="messageAttendingParticipantsWithoutTrip"
											name="messageData"
											checked={messageData === "messageAttendingParticipantsWithoutTrip"}
											id=""
											onChange={handleMessageDataChange}
										/>
									</div>
									<div className="tw-flex-grow">
										<p className="tw-m-0">
										{getText(typeOfParticipantsView.toLowerCase(), 2)}	
										</p>
									</div>
								</label> : null} */}
							</Modal>
						</li>
						{isLoading.currentStatusOfParticipants ? (
							<>
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={1}
									size="large"
								/>
								<Skeleton
									width={100}
									paragraph={true}
									active={true}
									height={1}
									size="large"
								/>{' '}
							</>
						) : (
							<>
								{paginationData.data?.map(
									(_data, index) =>
										index >= paginationData.minIndex &&
										index < paginationData.maxIndex && (
											<ParticipantCard
												_data={_data}
												handleParticipants={
													handleParticipants
												}
												typeOfParticipantsView={
													typeOfParticipantsView
												}
											/>
										)
								)}
							</>
						)}
					</ul>
					{paginationData.data?.length !== 0 && !isLoading.currentStatusOfParticipants ? <Pagination
						defaultCurrent={1}
						pageSize={paginationData.pageSize}
						current={paginationData.current}
						total={paginationData?.data?.length}
						onChange={handlePaginationChange}
						style={{ bottom: '0px' }}
						pageSizeOptions={[5, 10, 20, 50, 100]}
						onShowSizeChange={handleShowSizeChange}
					/> : isLoading.currentStatusOfParticipants ? null : <h2>No data found</h2>}
				</div>
			</div>
		</div>
	);
};

// Manage

const Pill = (props) => {

	const { text } = props;

	return <b>
		{text}
	</b>
}

const Reports = (props) => {
	const { eventId } = props;
	const dispatch = useDispatch();
	const eventReducer = useSelector((state) => state.events);
	const { downloadReports, isLoading } = eventReducer;

	let userDetails = JSON.parse(window.localStorage.getItem('userDetails'));
	let client_id;
	if (userDetails) {
		client_id = userDetails.company;
	}
	useEffect(() => {
		if (downloadReports) {
			if (downloadReports.status) {
				window.open(downloadReports.download_link);
				ToastService.successNotication(downloadReports.message);
				dispatch(setDownloadReportsStatus(null));
			}
			if (!downloadReports.status && downloadReports.message) {
				// window.open(downloadReports.download_link);
				ToastService.error(downloadReports.message);
			}
			dispatch(setDownloadReportsStatus(null));
		}
	}, [downloadReports]);

	const handleReportsDownload = (type) => {
		switch (type) {
			case 'linked-trip-report':
				dispatch(downloadEventReports(eventId, client_id, type));
				break;
			case 'event-transaction-report':
				dispatch(downloadEventReports(eventId, client_id, type));
				break;
			case 'rsvp-report':
				dispatch(downloadEventReports(eventId, client_id, type));
				break;
			default:
				break;
		}
	};

	return (
		<div className="tw-mb-8 tw-border-[rgba(182,186,195,1)] tw-rounded-lg tw-border-solid tw-border">
			<div className="tw-flex-row tw-flex tw-justify-between tw-items-center tw-px-6 tw-pt-4 tw-flex">
				<div className="tw-m-0 tw-text-base tw-font-medium">Reports</div>
			</div>
			<div className="tw-justify-between tw-px-6 tw-py-4 tw-flex tw-flex-row">
				<div className="tw-border tw-w-[32%] tw-text-center tw-p-6 tw-rounded-lg tw-border-solid tw-border-[#e5e9ed] tw-flex tw-justify-between tw-flex-col">
					<div>
						<div className="tw-text-left tw-mb-2 tw-text-sm tw-font-medium">
							Linked trips
						</div>
						<p className="tw-text-left tw-mb-2">
							A detailed view of the trips that are linked to this event.
						</p>
					</div>
					<div>
						<Button
							type="text"
							onClick={() =>
								handleReportsDownload('linked-trip-report')
							}
							disabled={
								isLoading.downloadReports.type ===
								'linked-trip-report' &&
								isLoading.downloadReports.payload
							}
						>
							{isLoading.downloadReports.type ===
								'linked-trip-report' &&
								isLoading.downloadReports.payload
								? 'Downloading...'
								: 'Download'}
						</Button>
					</div>
				</div>
				<div className="tw-border tw-w-[32%] tw-text-center tw-p-6 tw-rounded-lg tw-border-solid tw-border-[#e5e9ed] tw-flex tw-justify-between tw-flex-col" >
					<div>
						<div className="tw-text-left tw-mb-2 tw-text-sm tw-font-medium">
							Event transactions
						</div>
						<p className="tw-text-left tw-mb-2">
							Transaction details of all trips linked to this event.
						</p>
					</div>
					<div>
						<Button
							type="text"
							onClick={() =>
								handleReportsDownload('event-transaction-report')
							}
							disabled={
								isLoading.downloadReports.type ===
								'event-transaction-report' &&
								isLoading.downloadReports.payload
							}
						>
							{isLoading.downloadReports.type ===
								'event-transaction-report' &&
								isLoading.downloadReports.payload
								? 'Downloading...'
								: 'Download'}
						</Button>
					</div>
				</div>
				<div className="tw-border tw-w-[32%] tw-text-center tw-p-6 tw-rounded-lg tw-border-solid tw-border-[#e5e9ed] tw-flex tw-justify-between tw-flex-col">
					<div>
						<div className="tw-text-left tw-mb-2 tw-text-sm tw-font-medium">RSVP</div>
						<p className="tw-text-left tw-mb-2">
							A report of the current RSVP status of the participants invited to this event.
						</p>
					</div>
					<div>
						<Button
							type="text"
							onClick={() => handleReportsDownload('rsvp-report')}
							disabled={
								isLoading.downloadReports.type === 'rsvp-report' &&
								isLoading.downloadReports.payload
							}
						>
							{isLoading.downloadReports.type === 'rsvp-report' &&
								isLoading.downloadReports.payload
								? 'Downloading...'
								: 'Download'}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const DeleteEvent = (props) => {
	const { eventId } = props;

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const eventReducer = useSelector((state) => state.events);
	const { deleteEvent } = eventReducer;
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleEventDelete = () => {
		// setIsModalOpen(false);
		dispatch(deleteIndividualEvent(eventId));
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (deleteEvent) {
			if (deleteEvent.status === 204) {
				setIsModalOpen(false);
				navigate('/team-events');
				ToastService.successNotication("Event deleted successfully.")
				dispatch(setIndividualEventDelete(null))
			}
		}
	}, [deleteEvent]);

	return (
		<div className="tw-mb-8 tw-border-[rgba(182,186,195,1)] tw-rounded-lg tw-border-solid tw-border">
			<div className="tw-flex-row tw-flex tw-justify-between tw-items-center tw-px-6 tw-py-4 tw-flex">
				<div className='tw-flex tw-items-center'>
					<DeleteBin size={13} />
					<div className="tw-m-0 tw-ml-1 tw-text-base tw-font-medium" type="primary" danger>Delete event</div>
				</div>
				<Button onClick={showModal}>Delete event</Button>
			</div>
			<Modal
				title="Delete event"
				open={isModalOpen}
				onOk={handleEventDelete}
				okText="Confirm"
				onCancel={handleCancel}
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '70vh'
				}}
			>
				<p>
					This will delete the event for the organisers and the participants
				</p>
			</Modal>
		</div >
	);
};



const EventProgress = (props) => {

	let placeHolderForProgress = {
		progress: "15",
		id: "",
		closedReminderCards: false,
		description: false,
		invited: false
	}
	const { eventId, showModal, handleDescriptionEdit, setDocument } = props;
	const eventReducer = useSelector(state => state.events);
	const { individualEventDetails, RSVPcount, isLoading } = eventReducer;
	const [eventProgress, setEventProgress] = useState(placeHolderForProgress);

	const closedEventCardIDs = localStorage.getItem("closedEventCardIDs") ? JSON.parse(localStorage.getItem("closedEventCardIDs")) : [];

	useEffect(() => {
		if (RSVPcount) {
			if (RSVPcount.invited > 1) {
				setEventProgress({
					...eventProgress,
					invited: true
				})
			}
			else {
				setEventProgress({
					...eventProgress,
					invited: false
				})
			}
		}
	}, [RSVPcount])

	useEffect(() => {

		if (eventProgress.description && eventProgress.invited || (RSVPcount?.invited > 1 && individualEventDetails?.description.length > 1)) {
			setEventProgress({
				...eventProgress,
				progress: "100",
				EventDescription: true,
				invited: true
			})
		}
		else if (eventProgress.description || (individualEventDetails?.description.length > 1)) {
			setEventProgress({
				...eventProgress,
				progress: "50",
				EventDescription: true
			})
		}
		else if (eventProgress.invited || (RSVPcount?.invited > 1)) {
			setEventProgress({
				...eventProgress,
				progress: "50",
				invited: true
			})
		}
		else if (eventProgress.description || eventProgress.invited) {
			setEventProgress({
				...eventProgress,
				progress: "50"
			});
		} else if (!(eventProgress.description || eventProgress.invited)) {
			setEventProgress({
				...eventProgress,
				progress: "15"
			});
		}
	}, [eventProgress.description, eventProgress.invited, RSVPcount, individualEventDetails])


	useEffect(() => {
		if (individualEventDetails) {

			setEventProgress({
				...eventProgress,
				description: individualEventDetails.description.length > 1 ? true : false,
				closedReminderCards: closedEventCardIDs[eventId] ? true : false

			})
		}
	}, [individualEventDetails])

	const stepRenderer = () => {
		if (eventProgress.description && eventProgress.invited) {
			setEventProgress({
				...eventProgress,
				progress: "100"
			});
			return "2 of 2";
		}
		if ((eventProgress.description && !eventProgress.invited) || (!eventProgress.description && eventProgress.invited)) {
			return "1 of 2";
		}
		else {
			return "0 of 2";
		}
	}
	const handleAddDescription = () => {
	}

	const handleReminderCardClose = () => {
		setEventProgress({
			...eventProgress,
			closedReminderCards: true
		})

		if (closedEventCardIDs[eventId]) {

		}
		else {
			localStorage.setItem("closedEventCardIDs", JSON.stringify({
				...closedEventCardIDs,
				[eventId]: eventId
			}))
		}


	}
	return (
		<>
			{(eventProgress.progress !== "100" && !eventProgress.closedReminderCards && (!isLoading.RSVPcount || !isLoading.individualEventDetails)) ? <div className="tw-bg-gray-50 tw-rounded-md tw-py-4 tw-mb-2">
				<div className="tw-flex-row tw-justify-between tw-items-center tw-px-6 tw-flex">
					<div className="tw-m-0 tw-text-base tw-font-medium" type="primary" danger>Successfully created the event. What next?</div>
					<CloseOutlined className='tw-cursor-pointer' onClick={handleReminderCardClose} />
				</div>

				<Progress percent={eventProgress.progress} showInfo={false} strokeColor="#EC5D25" strokeWidth={4} className="tw-px-6" />

				<span className='tw-px-6 tw-mb-8 tw-mt-[-8px] tw-block tw-text-xs tw-font-normal'>{stepRenderer()} steps completed</span>
				{!eventProgress.description ?
					<div
						className='tw-px-6 tw-py-4 tw-flex tw-justify-between tw-bg-[#F1F3F5] tw-cursor-pointer'
						onClick={() => handleDescriptionEdit()}>
						<span className='tw-mt-1 tw-mr-2'>
							<Description color="#6B7280" className='tw-w-8' size="16" />
						</span>
						<div className='tw-w-full'>
							<div className='tw-m-0 tw-text-left tw-text-sm tw-font-medium tw-mt-1'>Add event description</div>
							{/* <p className='tw-m-0 tw-text-left tw-text-xs tw-font-normal'>Add a  description so participants know what to expect and when to travel</p> */}
						</div>
						<CaretRightOutlined className='tw-w-8' />
					</div> : null}
				{!eventProgress.invited ? <div className='tw-px-6 tw-py-4 tw-flex tw-justify-between tw-cursor-pointer' onClick={() => showModal("individual")}>
					<span className='tw-mt-1 tw-mr-2'>
						<Participants color="#6B7280" className='tw-w-8' size="20" />
					</span>
					<div className='tw-w-full'>
						<div className='tw-m-0 tw-text-left tw-text-sm tw-font-medium tw-mt-1'>Invite participants</div>
						{/* <p className='tw-m-0 tw-text-left tw-text-xs tw-font-normal'>Select and send invite to the people </p> */}
					</div>
					<CaretRightOutlined className='tw-w-8' />
				</div> : null}
			</div> : isLoading.RSVPcount || isLoading.individualEventDetails ? <>
				<Skeleton
					width={100}
					paragraph={false}
					active={true}
					height={1}
					size="large"
				/>
			</> : null}
		</>
	);
};



export {
	EventProgress,
	EventDescription,
	EventParticipants,
	CreateLinkTrip,
	UpdateSection,
	EventParticipantsSmall,
	ParticipantListEdit,
	Reports,
	DeleteEvent,
};
