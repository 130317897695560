import { Input, Skeleton } from "components/atoms";
import { DatePicker, AutoComplete } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import LocalStorageService from "services/LocalStorage";
import { getTimeZoneData } from "redux/features/Events/Events.slice";
import { LocationSearch } from "./sections";
import dayjs from "dayjs";
import moment from "moment";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";


const mockVal = (str, repeat = 1) => ({
	value: str.repeat(repeat),
});

const CreateEventModal = (props) => {

	const {
		eventDetails,
		setEventDetails,
		eventDetailsForedit,
		setEventDetailsforEdit,
		handleCreateEvent,
		selectedOption,
		setSelectedOption,
		timezoneData,
		setTimezoneData, type, locationName, setLocationName} = props;

	const dispatch = useDispatch();
	const eventReducer = useSelector(state => state.events);

	const { timezone, isLoading } = eventReducer;
	const [value, setValue] = useState('');
	const [options, setOptions] = useState([]);
	const events = useSelector(state => state);
	const token = LocalStorageService.getAccessToken()
	const [isStartDateNow, setIsStartDateNow] = useState({ create: false, edit: false });
	const [isEndDateNow, setIsEndDateNow] = useState({ create: false, edit: false });

	useEffect(()=>{
		if(eventDetails?.startTime?.filled === false && eventDetails?.startTime?.value === ''){
			let obj = {...eventDetails}
			obj.startTime.filled = true;
			obj.startTime.value = "00:00:00";
			setEventDetails(obj)
		}
		if(eventDetails?.endTime?.filled === false && eventDetails?.endTime?.value === ''){
			let obj = {...eventDetails}
			obj.endTime.filled = true;
			obj.endTime.value = "23:59:59";
			setEventDetails(obj)
		}
	},[eventDetails.startDate?.value, eventDetails.endDate?.value, selectedOption])
	useEffect(() => {

		if (type === "create") {

			if (selectedOption &&
				checkTrue(eventDetails.startDate.value) &&
				checkTrue(eventDetails.startTime.value) &&
				checkTrue(eventDetails.endDate.value) &&
				checkTrue(eventDetails.endTime.value)
			) {

				let _data = {
					"selected_option": selectedOption,
					"start_date_time": `${eventDetails.startDate.value} ${eventDetails.startTime.value}`,
					"end_date_time": `${eventDetails.endDate.value} ${eventDetails.endTime.value}`
				}
				handleFindTimeZone(_data);
			}
		}

	}, [selectedOption, eventDetails.startDate?.value, eventDetails.startTime?.value, eventDetails.endDate?.value, eventDetails.endTime?.value]);

	useEffect(() => {

		if (type === "edit") {

			if (selectedOption &&
				checkTrue(eventDetailsForedit.startDate?.value) &&
				checkTrue(eventDetailsForedit.startTime?.value) &&
				checkTrue(eventDetailsForedit.endDate?.value) &&
				checkTrue(eventDetailsForedit.endTime?.value)
			) {
				let _data;
				if (selectedOption.length !== 0) {
					_data = {
						"selected_option": selectedOption ? selectedOption : "",
						"start_date_time": `${eventDetailsForedit.startDate?.value} ${eventDetailsForedit.startTime?.value}`,
						"end_date_time": `${eventDetailsForedit.endDate.value} ${eventDetailsForedit.endTime.value}`
					}
				}
				else {
					_data = {
						"selected_option": {
							"latitude": eventDetailsForedit.latitude.value,
							"longitude": eventDetailsForedit.longitude.value,
							"category": ""
						},
						"start_date_time": `${eventDetailsForedit.startDate?.value} ${eventDetailsForedit.startTime?.value}`,
						"end_date_time": `${eventDetailsForedit.endDate.value} ${eventDetailsForedit.endTime.value}`
					}
				}

				handleFindTimeZone(_data);
			}
		}

	}, [selectedOption, eventDetailsForedit?.startDate?.value,
		eventDetailsForedit?.startTime?.value,
		eventDetailsForedit?.endDate?.value,
		eventDetailsForedit?.endTime?.value]);

	useEffect(() => {
		if (timezone) {
			setTimezoneData(timezone)
		}
	}, [timezone])


	const checkTrue = (value) => {
		if (value.length === 0)
			return false
		else return true

	}

	const handleFindTimeZone = (data) => {
		dispatch(getTimeZoneData(data));
	}

	const convertYYYYMMDD = (val) =>{
		var date = val.split(" ");
		var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		for(var j=0;j<months.length;j++){
			if(date[1]==months[j]){
				date[1]=months.indexOf(months[j])+1;
			}                      
		} 
		if(date[1]<10){
			date[1]='0'+date[1];
		}                        
		var formattedDate = date[2]+"-"+date[1]+"-"+date[0];
		return formattedDate;
	}
	const handleEventDetailsChange = (e, dataString, name, type) => {
		if(name === "startDate" || name === "endDate"){
			dataString = convertYYYYMMDD(dataString);
		}
		if(name === "startTime" || name === "endTime"){
			if(dataString === '' && name === "startTime") dataString = "00:00:00"
			else if(dataString === '' && name === "endTime") dataString = "23:59:59"
			else dataString = moment(dataString, 'hh:mm A').format('HH:mm') + ":00"
		}
		if (name === "startDate") {
			let dateNow = new Date().toLocaleDateString('fr-CA', { hour12: false });
			if (dataString === dateNow) {
				if (type === "create") {
					let obj = { ...isStartDateNow }
					obj.create = true
					setIsStartDateNow(obj);
				} else {
					let obj = { ...isStartDateNow }
					obj.edit = true
					setIsStartDateNow(obj);
				}

			} else {
				if (type === "create") {
					let obj = { ...isStartDateNow }
					obj.create = false
					setIsStartDateNow(obj);
				} else {
					let obj = { ...isStartDateNow }
					obj.edit = false
					setIsStartDateNow(obj);
				}
			}
		}
		if (name === "endDate") {
			let dateNow = new Date().toLocaleDateString('fr-CA', { hour12: false });
			if (dataString === dateNow) {
				if (type === "create") {
					let obj = { ...isEndDateNow }
					obj.create = true
					setIsEndDateNow(obj);
				} else {
					let obj = { ...isEndDateNow }
					obj.edit = true
					setIsEndDateNow(obj);
				}
			} else {
				if (type === "create") {
					let obj = { ...isEndDateNow }
					obj.create = false
					setIsEndDateNow(obj);
				} else {
					let obj = { ...isEndDateNow }
					obj.edit = false
					setIsEndDateNow(obj);
				}
			}
		}

		// return

		if (type === "edit") {
			switch (name) {
				case "eventName":
					return setEventDetailsforEdit({
						...eventDetailsForedit,
						[name]: {
							filled: true,
							value: e.target.value
						}
					});
				case "startDate":
					return setEventDetailsforEdit({
						...eventDetailsForedit,
						[name]: {
							filled: true,
							value: dataString,
						}
					});
				case "startTime":
					return setEventDetailsforEdit({
						...eventDetailsForedit,
						[name]: {
							filled: true,
							value: dataString
						}
					});
				case "endDate":
					return setEventDetailsforEdit({
						...eventDetailsForedit,
						[name]: {
							filled: true,
							value: dataString
						}
					});
				case "endTime":
					return setEventDetailsforEdit({
						...eventDetailsForedit,
						[name]: {
							filled: true,
							value: dataString
						}
					});
				default:
					return setEventDetailsforEdit({
						...eventDetailsForedit
					});
			}
		}
		else
			switch (name) {


				case "eventName":
					return setEventDetails({
						...eventDetails,
						[name]: {
							filled: true,
							value: e.target.value
						}
					});
				case "startDate":
					return setEventDetails({
						...eventDetails,
						[name]: {
							filled: true,
							value: dataString,
							objVal: e
						}
					});
				case "startTime":
					return setEventDetails({
						...eventDetails,
						[name]: {
							filled: true,
							value: dataString,
							objVal: e
						}
					});
				case "endDate":
					return setEventDetails({
						...eventDetails,
						[name]: {
							filled: true,
							value: dataString,
							objVal: e
						}
					});
				case "endTime":
					return setEventDetails({
						...eventDetails,
						[name]: {
							filled: true,
							value: dataString,
							objVal: e
						}
					});
				default:
					return setEventDetails({
						...eventDetails
					});
			}

	};

	const disabledTime = (type) => {
		const range = (start, end) => {
			const result = [];
			for (let i = start; i < end; i++) {
				result.push(i);
			}
			return result;
		};
		if (type === "create") {
			if (eventDetails.startDate.value !== '' && eventDetails.endDate.value !== '' && (eventDetails.startDate.value === eventDetails.endDate.value)) {
				let time = eventDetails.startTime.value?.split(":");
				return ({
					disabledHours: () => range(0, 24).splice(0, time[0]),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			} else {
				return ({
					disabledHours: () => range(0, 0),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			}
		}
		else {
			if (eventDetailsForedit?.startDate?.value !== '' && eventDetailsForedit?.endDate?.value !== '' && (eventDetailsForedit?.startDate?.value === eventDetailsForedit?.endDate?.value)) {
				let time = eventDetailsForedit?.startTime?.value?.split(":");
				// console.log("Time", time)
				return ({
					disabledHours: () => range(0, 24).splice(0, time[0]),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			} else {
				return ({
					disabledHours: () => range(0, 0),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			}
		}
	}
	const handleTime = (type) => {
		const range = (start, end) => {
			const result = [];
			for (let i = start; i < end; i++) {
				result.push(i);
			}
			return result;
		};
		if (type === "create") {
			let timeNow = new Date().toLocaleTimeString('fr-CA', { hour12: false });
			timeNow = timeNow.split(" ");
			if (isStartDateNow.create) {
				return ({
					disabledHours: () => range(0, 24).splice(0, timeNow[0]),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			} else if (isEndDateNow.create) {
				return ({
					disabledHours: () => range(0, 24).splice(0, timeNow[0]),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			} else {
				return ({
					disabledHours: () => range(0, 0),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			}
		}
		if (type === "edit") {
			let timeNow = new Date().toLocaleTimeString('fr-CA', { hour12: false });
			timeNow = timeNow.split(" ");
			if (isStartDateNow.edit) {
				return ({
					disabledHours: () => range(0, 24).splice(0, timeNow[0]),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			} else if (isEndDateNow.edit) {
				return ({
					disabledHours: () => range(0, 24).splice(0, timeNow[0]),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			} else {
				return ({
					disabledHours: () => range(0, 0),
					disabledMinutes: () => range(0, 0),
					disabledSeconds: () => range(0, 0),
				});
			}
		}
	}

	function handleKeyDown(e) {
		if (e.key === 'Enter') {
			// Trigger the Date Picker to show the calendar
			e.target.click();
		}
	}

	const [date, setDate] = useState();

	useEffect(() => {
		setDate(null);
	}, []);

	const timeZoneDataFn = (val1, val2) =>{
		if(val1 && val2){
			if(val2.split(" ").length == 2)
			return `Time zone in ${val2.split(" ")[0]} (GMT ${val2.split(" ")[1].slice(0,3)}:${val2.split(" ")[1].slice(3)})` 
			else
			return `Time zone in ${val2.split(" ")[0]} ${val2.split(" ")[1]} (GMT ${val2.split(" ")[2].slice(0,3)}:${val2.split(" ")[2].slice(3)})`
		}
		else
		    return "Local time at event location"
	}


	const dateFormat = 'YYYY-MM-DD HH:mm:ss';
	const timeFormat = 'YYYY-MM-DD HH:mm:ss';
	//   dayjs(individualEventDetails.start_date_time, dateFormat)

	return (
		<>
			{type === "edit" ? <div>
				<div className='modal-notification'>
					<div className="notification-icon-left"></div>
					<div className="notification-icon-right"></div>
				</div>
				<div className="tw-flex tw-flex-wrap tw-justify-between tw-pb-4">
					<div className="tw-mt-6 tw-w-full">
						<label className="tw-text-color-gray-500 tw-font-medium">Event name *</label>
						<Input
							className="tw-w-full tw-mt-2"
							value={eventDetailsForedit?.eventName.value}
							onChange={(e) => handleEventDetailsChange(e, "null", "eventName", "edit")}
							name="eventName"
							size="small"
						/>
					</div>
					<div className="tw-mt-6 tw-w-full">
						<>
							<label className="tw-text-color-gray-500 tw-font-medium">Location *</label>
						</>
						<br />
						<div className="tw-mt-2">
						<LocationSearch
							className="tw-w-full tw-mt-2"
							selectedOption={selectedOption}
							setSelectedOption={setSelectedOption}
							eventDetailsForedit={eventDetailsForedit}
							setEventDetailsforEdit={setEventDetailsforEdit}
							value={selectedOption?.value ? selectedOption?.value : eventDetailsForedit.location}
							locationName={locationName}
							setLocationName={setLocationName}
							type="edit"
						/>
						</div>
						{
							<span className="tw-pt-1">{isLoading.locationAutoComplete ? "Locations are loading..." : null}</span>
						}
					</div>
					<div className="tw-mt-6 tw-w-[calc(50%_-_16px)]">
						<label className="tw-text-color-gray-500 tw-font-medium">Start date *</label>
						<DatePicker
							tabIndex={0} // Make the Date Picker focusable
							onKeyDown={handleKeyDown} // Listen for keyboard events
							onChange={(data, dataString) => handleEventDetailsChange(data, dataString, "startDate", "edit")}
							name="startDate"
							className="tw-block tw-mt-2"
							defaultValue={eventDetailsForedit.startDate?.objVal}
							placeholder="DD MMM YYYY"
							format={"DD MMM YYYY"}
							allowClear={false}
							
							// disabledDate={d => !d || !d.isAfter(Date.now() - 24 * 60 * 60 * 1000)}
						/>
					</div>
					<div className="tw-mt-6 tw-w-[calc(50%_-_16px)]">
						<label className="tw-text-color-gray-500 tw-font-medium">Start time </label>
						<DatePicker
							onChange={(data, dataString) => handleEventDetailsChange(data, dataString, "startTime", "edit")}
							picker="time"
							name="startTime"
							className="tw-block tw-mt-2"
							defaultValue={eventDetailsForedit.startTime?.objVal}
							placeholder="hh:mm AM"
							format={"hh:mm A"}
							showNow={false}
							// disabledDate={d => {if(isStartDateNow.edit) return !d || !d.isAfter(Date.now())}} 
							// disabledTime={() => handleTime("edit")}
						/>
						{type === "create" ? <>
							{
								isLoading.timezone ? "fetching timezone..." : <span className="tw-text-color-gray-400">{timeZoneDataFn(timezone, timezone?.start_date_timezone)}</span>
							}
						</> : <>
							{
								isLoading.timezone ? "fetching timezone..." : <span className="tw-text-color-gray-400">{timeZoneDataFn(timezoneData, timezoneData?.start_date_timezone)}</span>
							}
						</>}
					</div>
					<div className="tw-mt-6 tw-w-[calc(50%_-_16px)]">
						<label className="tw-text-color-gray-500 tw-font-medium">End date *</label>
						<DatePicker
							onChange={(data, dataString) => handleEventDetailsChange(data, dataString, "endDate", "edit")}
							name="endDate"
							className="tw-block tw-mt-2"
							defaultValue={eventDetailsForedit?.endDate?.objVal}
							disabledDate={d => !d || !d.isAfter(moment(eventDetailsForedit?.startDate?.value).subtract(0 , 'day').format('DD MMM YYYY')) }
							placeholder="DD MMM YYYY"
							format={"DD MMM YYYY"}
							allowClear={false}
						/>
					</div>
					<div className="tw-mt-6 tw-w-[calc(50%_-_16px)]">
						<label className="tw-text-color-gray-500 tw-font-medium">End time </label>
						<DatePicker
							onChange={(data, dataString) => handleEventDetailsChange(data, dataString, "endTime", "edit")}
							picker="time"
							name="endTime"
							className="tw-block tw-mt-2"
							defaultValue={eventDetailsForedit.endTime?.objVal}
							disabledTime={() => disabledTime("edit")}
							placeholder="hh:mm AM"
							format={"hh:mm A"}
							showNow={false}
						/>
						{type === "create" ? <>
							{
								isLoading.timezone ? "fetching timezone..." : <span className="tw-text-color-gray-400">{timeZoneDataFn(timezone, timezone?.end_date_timezone)}</span>
							}
						</> : <>
							{
								isLoading.timezone ? "fetching timezone..." : <span className="tw-text-color-gray-400">{timeZoneDataFn(timezoneData, timezoneData?.end_date_timezone)}</span>
							}
						</>}
					</div>
				</div>
			</div> : <div className="tw-mt-6">


				<div className='modal-notification'>
					<div className="tw-px-4 tw-pb-3.5  tw-bg-gray-50 tw-border tw-border tw-rounded-md tw-border-solid tw-border-slate-200">
						<div className="tw-flex tw-flex-row tw-items-baseline tw-justify-around">
						<div className="notification-icon-left tw-text-color-gray-700 tw-basis-[5%]"><InfoCircleOutlined className="tw-text-lg"/></div>
						<div className="tw-basis-[95%]">
						<h5 className="tw-text-color-gray-700 tw-font-medium tw-text-sm">  You are just a few clicks away from creating your event on ITILITE. Once you have created your event, you can</h5>
						
						<ul className="tw-typography-body2 tw-text-color-gray-500 tw-mt-[-0.75rem] tw-mb-[8.5px]">
							<li>Invite participants</li>
							<li>Share important event updates</li>
							<li>Download event reports</li>
						</ul>
						</div>
						{/* <div className="notification-icon-left tw-text-color-gray-500 tw-basis-[4%]"><CloseOutlined  className="tw-text-base"/></div> */}
						</div>
					</div>
					<div className="notification-icon-right"></div>
				</div>
				<div className="tw-flex tw-flex-wrap tw-justify-between tw-pb-4">
					<div className="tw-mt-6 tw-w-full">
						<label className="tw-text-color-gray-500 tw-font-medium">Event name *</label>
						<Input
							className="tw-mt-2"
							value={eventDetails?.eventName.value}
							onChange={(e) => handleEventDetailsChange(e, "null", "eventName")}
							name="eventName"
							size="small"
							placeholder="Enter name, e.g Annual team meet"
						/>

					</div>
					<div className="tw-mt-6 tw-w-full">
						<label className="tw-text-color-gray-500 tw-font-medium">Location *</label>
						<br />
						<div className="tw-mt-2">
						<LocationSearch
							className="tw-w-full tw-mt-2"
							selectedOption={selectedOption}
							setSelectedOption={setSelectedOption}
							locationName={locationName}
							setLocationName={setLocationName}
							// value={selectedOption?.value}
							type="create"
						/>
						</div>
						{
							<span className="tw-pt-1">{isLoading.locationAutoComplete ? "Locations are loading..." : null}</span>
						}

					</div>
					<div className="tw-mt-6 tw-w-[calc(50%_-_16px)]">
						<label className="tw-text-color-gray-500 tw-font-medium">Start date *</label>
						<DatePicker
							// disabledDate={d => !d || !d.isAfter(Date.now() - 24 * 60 * 60 * 1000)}
							onChange={(data, dataString) => handleEventDetailsChange(data, dataString, "startDate", "create")}
							name="startDate"
							value={eventDetails?.startDate?.value ? dayjs(eventDetails?.startDate?.value) : null}
							className="tw-block tw-mt-2" 
							placeholder="DD MMM YYYY"
							format={"DD MMM YYYY"}
							allowClear={false}
						/>
					</div>
					<div className="tw-mt-6 tw-w-[calc(50%_-_16px)]">
						<label className="tw-text-color-gray-500 tw-font-medium">Start time </label>
						<DatePicker
							// disabledDate={d => {if(isStartDateNow.create) return !d || !d.isAfter(Date.now())}} 
							// disabledTime={() => handleTime("create")}
							disabled={eventDetails?.startDate.value.length < 10}
							onChange={(data, dataString) => handleEventDetailsChange(data, dataString, "startTime", "create")}
							picker="time"
							name="startTime"
							value={eventDetails.startTime ? eventDetails.startTime.objVal : null}
							className="tw-block tw-mt-2"
							placeholder="hh:mm AM"
							format={"hh:mm A"}
							showNow={false}
						/>
						{
							isLoading.timezone ? "fetching timezone..." : <span className="tw-text-color-gray-400">{timeZoneDataFn(timezone, timezone?.start_date_timezone)}</span>
						}
					</div>
					<div className="tw-mt-6 tw-w-[calc(50%_-_16px)]">
						<label className="tw-text-color-gray-500 tw-font-medium">End date *</label>
						<DatePicker
							disabledDate={d => !d || !d.isAfter(moment(eventDetails.startDate.value).subtract(0, 'day').format('DD MMM YYYY')) }
							onChange={(data, dataString) => handleEventDetailsChange(data, dataString, "endDate", "create")}
							name="endDate"
							value={eventDetails?.endDate?.value ? dayjs(eventDetails?.endDate?.value) : null}
							className="tw-block tw-mt-2" 
							placeholder="DD MMM YYYY"
							format={"DD MMM YYYY"}
							allowClear={false}
						/>
					</div>
					<div className="tw-mt-6 tw-w-[calc(50%_-_16px)]">
						<label className="tw-text-color-gray-500 tw-font-medium">End time </label>
						<DatePicker
							disabledTime={() => disabledTime("create")}
							disabled={eventDetails?.endDate.value.length < 10}
							onChange={(data, dataString) => handleEventDetailsChange(data, dataString, "endTime", "create")}
							picker="time"
							name="endTime"
							className="tw-block tw-mt-2" 
							placeholder="hh:mm AM"
							value={eventDetails.endTime ? eventDetails.endTime.objVal : null}
							format={"hh:mm A"}
							showNow={false}
						/>
						{
							isLoading.timezone ? "fetching timezone..." : <span className="tw-text-color-gray-400">{timeZoneDataFn(timezone, timezone?.end_date_timezone)}</span>
						}
					</div>
				</div>
			</div>}
		</>
	);
};

export default CreateEventModal;