import { Reports, DeleteEvent } from "./sections";

const Manage = (props) => {


	return(
		<>
			<Reports eventId={props.eventId} />
			<DeleteEvent eventId={props.eventId} />
		</>
	);
};

export default Manage;