import instance from 'auth/axiosInstance';
import LocalStorageService from 'services/LocalStorage';
import { apiUrls } from 'utils/urls/apiUrls';

const { createSlice } = require('@reduxjs/toolkit');

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		isLoading: {
			auth: false,
			usmDetails: false,
		},
		userDetails: null,
		usmDetails: null,
	},
	reducers: {
		authenticateUser: (state, action) => {
			state.userDetails = action.payload;
		},
		setUsmUserDetails: (state, action) => {
			state.usmDetails = action.payload;
		},
		// loading reducer
		setLoadingStateAuthenticateUser: (state, action) => {
			state.isLoading.auth = action.payload;
		},
		setLoadingStateUsmDetails: (state, action) => {
			state.isLoading.usmDetails = action.payload;
		},
	},
});

export const getUserDetailsAuth = (data) => async (dispatch) => {
	dispatch(authenticateUser(data));
};

export const getUserUSMDetails = (user_id) => async (dispatch) => {
	const token = LocalStorageService.getAccessToken();
	let url = `${apiUrls.AUTH.USER_USM_DETAILS}/${user_id}`;
	try {
		dispatch(setLoadingStateUsmDetails(true));
		const response = await instance.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		// console.log(response);
		dispatch(setLoadingStateUsmDetails(false));
		dispatch(setUsmUserDetails(response.data));
	} catch (err) {
		dispatch(setLoadingStateUsmDetails(false));
		console.log(err);
	}
};

export const {
	authenticateUser,
	setLoadingStateAuthenticateUser,
	setLoadingStateUsmDetails,
	setUsmUserDetails,
} = authSlice.actions;

export const authReducerData = (state) => state.auth;

export default authSlice.reducer;
