import * as React from 'react';

export const ChevronDown = React.memo(({ size = 16, color = '#6b7280', className, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 16 16"
			className={className}
			{...rest}
		>
			<path
				fill={color}
				fillRule="evenodd"
				d="M3.293 5.29495C3.68352 4.90442 4.31669 4.90442 4.70721 5.29495L8.00011 8.58784L11.293 5.29495C11.6835 4.90442 12.3167 4.90442 12.7072 5.29495C13.0977 5.68547 13.0977 6.31863 12.7072 6.70916L8.70721 10.7092C8.31669 11.0997 7.68352 11.0997 7.293 10.7092L3.293 6.70916C2.90248 6.31863 2.90248 5.68547 3.293 5.29495Z"
			></path>
		</svg>
	);
})

ChevronDown.displayName = "ChevronDown";