export const titleCase = (s) =>
	s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
		c ? c.toUpperCase() : ' ' + d.toUpperCase()
	);
export const titleCaseEveryWord = (str) => {
	return str.replace(/\b\w+/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

export const participantStatusFilter = {
	linked_trips: [
		{
			id: 'linked',
			name: 'linked a trip',
		},
		{
			id: 'not linked',
			name: 'Not linked a trip',
		},
	],

	// 	// Awaiting Selection
	// 	Awaiting Confirmation
	// // Awaiting Approval
	// // Curating Options
	// // Booking Completed
	// // Partially Booked

	trip_status: [
		{
			id: 1,
			name: 'Awaiting Approval',
		},
		{
			id: 2,
			name: 'Curating Options',
		},

		{
			id: 4,
			name: 'Awaiting Selection',
		},
		{
			id: 8,
			name: 'Booking Completed',
		},
		{
			id: 9,
			name: 'Partially Booked',
		},
		{
			id: 12,
			name: 'Awaiting Confirmation',
		},
	],
};
