import { bluePrimary, colorPrimary, styles } from './__variable';

const __globals = {
	token: {
		fontFamily: ["'Inter'", 'sans-serif'].join(','),
		...styles, //Need to revisit this once component colors and hover states are finalised.
	},
	components: {
		Radio: {
			colorPrimary: bluePrimary,
		},
		Checkbox: {
			colorPrimary: colorPrimary,
		},
		Button: {
			colorPrimary: colorPrimary,
		},
	},
};

export default __globals;
