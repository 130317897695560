import { productNavVisibility } from 'auth/productNavVisibilityConfig';
import { BrowserRouter as Router } from 'react-router-dom';
import AppLayout from 'components/templates/Layout';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import './App.css';
import AppRoutes from './routes/Routes';
import './theming/__globals.scss';
// import './utils/i18n/i18nConfig';

function App() {
	const [isloading, setLoading] = useState(false);
	const [showProductNav, setProductNav] = useState(false);
	const getAppRoute = (route) => {
		if (productNavVisibility[route]) {
			setProductNav(productNavVisibility[route]);
		}
	};
	return (
		<Router>
			<AppLayout isloading={isloading} showSecondaryMenu={showProductNav}>
				<AppRoutes getAppRoute={getAppRoute} />
			</AppLayout>
			<ToastContainer />
		</Router>
	);
}

export default App;
