import * as React from 'react';

export const CabIcon = React.memo(({ size = 24, color = '#6B7280', className, ...rest }) => {
    return (
        <svg width={size}
            height={size}   {
            ...rest
            } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16C8 16.5523 7.55228 17 7 17C6.44772 17 6 16.5523 6 16C6 15.4477 6.44772 15 7 15C7.55228 15 8 15.4477 8 16Z" fill="black" />
            <path d="M18 16C18 16.5523 17.5523 17 17 17C16.4477 17 16 16.5523 16 16C16 15.4477 16.4477 15 17 15C17.5523 15 18 15.4477 18 16Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.69288 7.5C7.46964 7.5 7.27344 7.64799 7.21211 7.86264L6.03001 12H17.97L16.7879 7.86264C16.7266 7.64799 16.5304 7.5 16.3071 7.5H7.69288ZM19.6762 12.5117C20.4737 13.05 21 13.963 21 15V20.75C21 21.4404 20.4404 22 19.75 22C19.0596 22 18.5 21.4404 18.5 20.75V20H5.5V20.75C5.5 21.4404 4.94036 22 4.25 22C3.55964 22 3 21.4404 3 20.75V15C3 13.963 3.52626 13.05 4.32378 12.5117L4.46999 12H3.75C3.33579 12 3 11.6642 3 11.25C3 10.8358 3.33579 10.5 3.75 10.5H4.89856L5.76983 7.45056C6.01514 6.59196 6.79992 6 7.69288 6H16.3071C17.2001 6 17.9849 6.59196 18.2302 7.45056L19.1014 10.5H20.25C20.6642 10.5 21 10.8358 21 11.25C21 11.6642 20.6642 12 20.25 12H19.53L19.6762 12.5117ZM4.5 18.5H19.5V15C19.5 14.4218 19.1731 13.9187 18.6904 13.6678C18.4846 13.5608 18.2505 13.5 18 13.5H6C5.7495 13.5 5.51541 13.5608 5.30955 13.6678C4.82695 13.9187 4.5 14.4218 4.5 15V18.5Z" fill={color} />
        </svg>


    );
})

CabIcon.displayName = "CabIcon";


