import clsx from 'clsx';
import { useState } from 'react';
import { IconButton, MenuItem, Popover } from 'components/atoms';
import {
	AdminIcon,
	ExpenseIcon,
	HelpIcon,
	NotificationIcon,
	ProfileIcon,
	TravelIcon,
	HamIcon,
} from './_icons';
import classes from './Navbar.module.scss';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';

function NavbarStrip(props) {
	const { hamMenu, setHamMenu } = props;

	const authReducer = useSelector((state) => state.auth);
	const { usmDetails, isLoading } = authReducer;
	const products = [
		{
			name: 'travel',
			icon: <TravelIcon status="active" />,
			tooltip: true,
			tooltipText: 'Travel',
			active: true,
		},
		// {
		// 	name: 'expense',
		// 	icon: <ExpenseIcon />,
		// 	tooltip: true,
		// 	tooltipText: 'Expense',
		// 	active: false,
		// },
		// {
		// 	name: 'admin',
		// 	icon: <AdminIcon />,
		// 	tooltip: true,
		// 	tooltipText: 'Admin console',
		// 	active: false,
		// },
	];
	const profileMenu = [
		// {
		// 	id: 1,
		// 	label: 'Profile',
		// 	link: '/',
		// },
		// {
		// 	id: 2,
		// 	label: 'Log in and Password',
		// 	link: '/',
		// },
		{
			id: 1,
			label: (
				<>
					<span onClick={() => handleLogout()}>Logout</span>
				</>
			),
			link: '/',
		},
	];

	const handleLogout = () => {
		window.location.href = `${process.env.REACT_APP_ITILITE_BASE_URL}/logout`;
		window.localStorage.clear();
	};
	const getProfileMenus = (
		<div className={clsx(classes['il__navbar--user-menu'])}>
			{profileMenu.map((_m) => (
				<MenuItem size="small" key={uuid()} label={_m.label} />
			))}
		</div>
	);

	const navigateHome = () => {
		window.location.href = process.env.REACT_APP_ITILITE_BASE_URL;
	};

	return (
		<div className={clsx(classes['il__navbar--strip'])}>
			<div className={clsx(classes['il__navbar--menu-stack'])}>
				{hamMenu && (
					<div
						className={clsx(
							'tw-w-full tw-flex tw-justify-center tw-mb-5 tw-mt-2 tw-cursor-pointer'
						)}
						onClick={() => {
							setHamMenu(!hamMenu);
						}}
					>
						<IconButton icon={<HamIcon />} />
					</div>
				)}
				<div
					className={clsx(classes['il__navbar--logo-container'])}
					onClick={() => navigateHome()}
				>
					<img
						src={require('../../../assets/images/logo/itilite_logo.png')}
						alt="Logo"
					/>
				</div>
				<div
					className={clsx(classes['il__navbar--products'], 'tw-mt-2')}
				>
					{products.map((_p, _i) => {
						return (
							<div
								className={clsx(
									classes['il__navbar--product-single'],
									_p.active &&
										classes['il__navbar--product-active'],
									_p.active &&
										'tw-bg-color-surface-default-active',
									'tw-cursor-pointer tw-h-[56px] tw-w-[56px] tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-mb-2 tw-text-center'
								)}
								key={uuid()}
							>
								{_p.icon}
								<span
									className={clsx(
										classes['il__navbar--products_name'],
										_p.active
											? 'tw-text-color-text-default'
											: 'tw-text-color-text-weak',
										'tw-block'
									)}
								>
									{_p.tooltipText}
								</span>
							</div>
						);
					})}
				</div>
			</div>
			<div className={clsx(classes['il__navbar--user-stack'])}>
				{/* <div className={clsx(classes['il__navbar--user-single'])}>
					<IconButton icon={<HelpIcon />} />
				</div>
				<div className={clsx(classes['il__navbar--user-single'])}>
					<IconButton icon={<NotificationIcon />} />
				</div> */}
				<Popover placement="rightBottom" content={getProfileMenus}>
					<div className={clsx(classes['il__navbar--user-single'])}>
						<IconButton
							icon={
								<ProfileIcon
									className="tw-aspect-square tw-rounded-full"
									img={
										usmDetails?.result?.profile_pic
											?.presigned_url
											? usmDetails?.result?.profile_pic
													?.presigned_url
											: require('../../../assets/images/logo/itilite_logo.png')
									}
								/>
							}
						/>
					</div>
				</Popover>
			</div>
		</div>
	);
}

export default NavbarStrip;
