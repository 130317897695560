import * as React from 'react';

export const OutlinedProfileIcon = React.memo(({ size = 24, color = '#FFF7ED', className, ...rest }) => {
    return (
        <svg
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            {
                ...rest
            }
        >
            <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FFF7ED" />
            <path d="M21.3337 22V20.6667C21.3337 19.9594 21.0527 19.2811 20.5526 18.781C20.0525 18.281 19.3742 18 18.667 18H13.3337C12.6264 18 11.9481 18.281 11.448 18.781C10.9479 19.2811 10.667 19.9594 10.667 20.6667V22M18.667 12.6667C18.667 14.1394 17.4731 15.3333 16.0003 15.3333C14.5276 15.3333 13.3337 14.1394 13.3337 12.6667C13.3337 11.1939 14.5276 10 16.0003 10C17.4731 10 18.667 11.1939 18.667 12.6667Z" stroke="#EC5D25" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="white" />
        </svg>

    );
})

OutlinedProfileIcon.displayName = "OutlinedProfileIcon";