import * as React from 'react';

export const Updates = React.memo(({ size = 24, color = '#EC5D25', className, ...rest }) => {
    return (

        <svg width={size} height={size} viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.501953 8H5.50195C5.7781 8 6.00195 8.22386 6.00195 8.5C6.00195 8.74546 5.82508 8.94961 5.59183 8.99194L5.50195 9H0.501953C0.225811 9 0.00195312 8.77614 0.00195312 8.5C0.00195312 8.25454 0.178828 8.05039 0.412077 8.00806L0.501953 8H5.50195H0.501953ZM2.14847 2.14638C2.32207 1.97285 2.59149 1.95361 2.78634 2.08865L2.85557 2.14651L6.85405 6.14651C7.04927 6.34181 7.04921 6.6584 6.85391 6.85362C6.68031 7.02715 6.41088 7.04639 6.21604 6.91136L6.14681 6.85349L2.14833 2.85349C1.95311 2.65819 1.95317 2.3416 2.14847 2.14638ZM8.50195 0C8.74741 0 8.95156 0.176875 8.9939 0.410124L9.00195 0.5V5.5C9.00195 5.77614 8.7781 6 8.50195 6C8.25649 6 8.05234 5.82312 8.01001 5.58988L8.00195 5.5V0.5C8.00195 0.223858 8.22581 0 8.50195 0Z" fill={color}/>
        </svg>


    );
})

Updates.displayName = "Updates";