import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Tabs } from 'components/atoms';

import { EventDescription } from './components/sections';
import CreateEventModal from './components/CreateEventModal';

import { Modal, Skeleton } from 'antd';

import '../../../TeamEvents/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import LocalStorageService from 'services/LocalStorage';
import { apiUrls } from 'utils/urls/apiUrls';
import {
	createEvent,
	getEventListFromAPI,
	createEventStatus as setCreateEventStatus,
	fetchEventServicePermission
} from 'redux/features/Events/Events.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastService from 'services/ToastService';
import { getUserUSMDetails } from 'redux/features/Auth/Auth.slice';

const Events = (props) => {
	const authReducer = useSelector((state) => state.auth);
	const { userDetails, usmDetails } = authReducer
	const eventData = useSelector((state) => state.events);
	const { createEventStatus, isLoading, eventsExist, permission } = eventData;

	const [eventBanner, setEventBanner] = useState(null)
	const [showData, setShowData] = useState(false)

	const [access, setAccess] = useState(true)
	const [type, setType] = useState("upcoming");
	const navigate = useNavigate()

	const dispatch = useDispatch();
	const history = useNavigate();

	let placeHolderEventDetails = {
		eventName: {
			filled: false,
			value: '',
		},
		location: {
			filled: false,
			value: {},
		},
		startDate: {
			filled: false,
			value: '',
		},
		startTime: {
			filled: false,
			value: '',
		},
		endDate: {
			filled: false,
			value: '',
		},
		endTime: {
			filled: false,
			value: '',
		},
	}

	const [eventDetails, setEventDetails] = useState(placeHolderEventDetails);
	const [selectedOption, setSelectedOption] = useState(null);
	const [timezoneData, setTimezoneData] = useState(null);

	const [locationName, setLocationName] = useState()

	const dummyData = {
		name: eventDetails.eventName.value,
		// "description": "Text description",
		location: selectedOption?.value,
		latitude: timezoneData?.latitude,
		longitude: timezoneData?.longitude,
		start_date: `${eventDetails.startDate.value} ${eventDetails.startTime.value}`,
		end_date: `${eventDetails.endDate.value} ${eventDetails.endTime.value}`,
		start_date_timezone: timezoneData?.start_date_timezone,
		end_date_timezone: timezoneData?.end_date_timezone,
		timezone: timezoneData?.start_date_timezone,
	};

	const items = [
		{
			key: 'upcoming',
			label: `Upcoming`,
			children: (
				<EventDescription
					type={type}
					dummy="this is the dummy prop"
					isLoading={isLoading.eventList}
					eventBanner={eventBanner}
					setEventBanner={setEventBanner}
				/>
			),
		},
		{
			key: 'past',
			label: `Past`,
			children: (
				<EventDescription
					type={type}
					dummy="this is the dummy prop"
					isLoading={isLoading.eventList}
					eventBanner={eventBanner}
					setEventBanner={setEventBanner}
				/>
			),
		},
	];

	const [isModalOpen, setIsModalOpen] = useState(false);

	const navigateHome = () => {
		window.location.href = process.env.REACT_APP_ITILITE_BASE_URL;
	};
	useEffect(() => {
		if (permission === null) {
			dispatch(fetchEventServicePermission())
		}
		if (permission?.status) {
			setAccess(true)
		}
		else if (permission?.status === false) {
			setAccess(false)
			ToastService.error(permission?.message)
			setTimeout(() => {
				navigateHome()
			}, [4000])
		}
	}, [permission])

	useEffect(() => {
		// fetchEventList()
		if (userDetails && eventsExist === null && access) {

			dispatch(getEventListFromAPI("null", "checkList", true));
		}
		if (usmDetails === null && userDetails) {
			dispatch(getUserUSMDetails(userDetails?.id));
		}
	}, [userDetails, access])

	useEffect(() => {
		// fetchEventList()
		if (userDetails && access) {

			// dispatch(getEventListFromAPI("null", "upcoming", false));
			dispatch(getEventListFromAPI("null", "checkList", true));
		}
	}, [userDetails, access])

	useEffect(() => {
		if (createEventStatus) {
			if (createEventStatus.status_code === 1) {
				navigate(`/team-events/create-event?id=${createEventStatus.id}`);
				dispatch(setCreateEventStatus(null));
			}
			if (!createEventStatus.status) {
				ToastService.error(createEventStatus.message)
				dispatch(setCreateEventStatus(null));
			}
		}
	}, [createEventStatus]);

	useEffect(() => {
		if (eventsExist) {
			setShowData(true)
			setEventBanner(false)
		}
		else {
			setEventBanner(true)
		}
	}, [eventsExist])

	const showModal = () => {
		setIsModalOpen(true);
	};
	const isDateTimeValid = () => {
		//Time
		let startTime = eventDetails.startTime.value;
		let endTime = eventDetails.endTime.value;

		//Date
		let startDate = eventDetails.startDate.value;
		let endDate = eventDetails.endDate.value;


		let tStart = startTime.split(":");
		let tEnd = endTime.split(":");
		let dStart = startDate.split("-");
		let dEnd = endDate.split("-");

		let startDateTime = new Date(parseInt(dStart[0], 10), (parseInt(dStart[1], 10)) - 1, parseInt(dStart[2], 10), parseInt(tStart[0], 10), parseInt(tStart[1], 10), parseInt(tStart[2], 10));
		let endDateTime = new Date(parseInt(dEnd[0], 10), (parseInt(dEnd[1], 10)) - 1, parseInt(dEnd[2], 10), parseInt(tEnd[0], 10), parseInt(tEnd[1], 10), parseInt(tEnd[2], 10));
		let valueSDT = startDateTime.valueOf();
		let valueEDT = endDateTime.valueOf();

		//Time Validation
		if ((startTime >= endTime) && (startDateTime === endDateTime)) {
			ToastService.error("Please Enter Valid Time to proceed");
			return false;
		}

		//DateTime Validation
		if (valueSDT <= valueEDT) return true;
		else {
			ToastService.error("Please Enter Valid Date to proceed");
			return false;
		}
	}
	const handleCreateEvent = () => {
		// setIsModalOpen(false);
		if (!isDateTimeValid())
			return;

		let _data = {
			name: eventDetails.eventName.value,
			// "description": "Text description",
			location: selectedOption?.value,
			latitude: timezoneData?.latitude,
			longitude: timezoneData?.longitude,
			start_date: `${eventDetails.startDate.value} ${eventDetails.startTime.value}`,
			end_date: `${eventDetails.endDate.value} ${eventDetails.endTime.value}`,
			start_date_timezone: timezoneData?.start_date_timezone,
			end_date_timezone: timezoneData?.end_date_timezone,
			timezone: timezoneData?.start_date_timezone,
		};

		dispatch(createEvent(_data));
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setEventDetails(placeHolderEventDetails)
		setSelectedOption(null)
		setTimezoneData(null)
		setSelectedOption(null)
		setLocationName("")
	};

	let isEvent = false;

	const checkDisabled = () => {
		if (
			checkTrue(eventDetails.startDate.value) &&
			// checkTrue(eventDetails.startTime.value) &&
			checkTrue(eventDetails.endDate.value) &&
			// checkTrue(eventDetails.endTime.value) &&
			checkTrue(eventDetails.eventName.value) &&
			selectedOption !== null && (Object.keys(selectedOption?.value).length > 0)
		)
			return false;
		else return true;
	};

	const onChange = (key) => {
		console.log(key);
		setType(key)
	};

	const checkTrue = (value) => {
		if (value.length === 0) return false;
		else return true;
	};

	return (
		(userDetails && permission ? <div>

			{access || isLoading.eventsExist ? <div className="tw-max-w-[842px] tw-text-center tw-m-auto  sm:tw-pt-[40px] tw-pt-[60px] sm:tw-px-0 tw-px-3">
				{eventBanner && !isLoading.eventsExist && !eventsExist ? (
					<>
						<span className='tw-flex tw-items-center tw-justify-between'>
							<span>

								<h1 className="tw-flex tw-justify-between tw-typography-title1">
									Events{' '}
								</h1>

							</span>
							{!eventBanner ? <Button type="primary" onClick={showModal} className="tw-h-10">
								Create event
							</Button> : null}
						</span>
						<div className='tw-flex tw-flex-col tw-items-center'>
							<img
								className="tw-block tw-max-w-[467px] tw-mb-20 tw-m-auto"
								src="/images/teamevents/banner.svg"
								alt=""
							/>
							<h3 className="tw-typography-title2">
								In a few steps, you can organize and manage an event.
							</h3>
							<p className="tw-typography-body2 tw-max-w-[667px] tw-m-auto">
								Organize and manage events never easy as this before.
								Click on 'create event' and get started. To know more
								about the event, <a href='https://www.itilite.com/faq/how-can-i-manage-my-events-on-the-itilite-platform/' className='tw-text-blue-600 tw-no-underline' target={'_blank'} rel="noreferrer" >Learn more</a>
							</p>
							<Button type="primary" onClick={showModal} className="tw-h-10 tw-mt-4">
								Create event
							</Button>
						</div>
					</>
				) : (
					<>
						<div className='tw-flex tw-items-center tw-justify-between'>
							<span>
								<h1 className="tw-flex tw-justify-between tw-mb-1">
									Events{' '}
								</h1>
								<p className='tw-text-gray-500'>Create, organise and manage event travel.
									<a href='https://www.itilite.com/faq/how-can-i-manage-my-events-on-the-itilite-platform/' className='tw-text-blue-500 tw-no-underline' target="_blank" rel="noreferrer"> Read more</a></p>
							</span>
							<Button type="primary" onClick={showModal} className="tw-rounded-lg tw-h-10">
								Create event
							</Button>
						</div>
						{/* <Button type='primary' onClick={showModal}>Create and event</Button> */}

						{!eventBanner && !isLoading.eventsExist && showData ? <Tabs
							defaultActiveKey="1"
							items={items}
							onChange={onChange}
							className=""
						/> : isLoading.eventsExist ? <Skeleton active={true}/> : null}
					</>
				)}

				<Modal
					title={<span className='tw-text-lg tw-font-medium tw-text-[#111827]'>Create an event</span>}
					open={isModalOpen}
					onOk={handleCreateEvent}
					onCancel={handleCancel}
					okText="Create"
					width={640}
					okButtonProps={{
						disabled: isLoading.createEvent || isLoading.timezone || isLoading.locationAutoComplete || checkDisabled(),
						style: {
							backgroundColor: "#EC5D25",
							opacity: (isLoading.createEvent || isLoading.timezone || isLoading.locationAutoComplete || checkDisabled()) ? 0.5 : 1,
							color: "white"
						}
					}}
				>
					<CreateEventModal
						eventDetails={eventDetails}
						setEventDetails={setEventDetails}
						handleCreateEvent={handleCreateEvent}
						selectedOption={selectedOption}
						setSelectedOption={setSelectedOption}
						timezoneData={timezoneData}
						setTimezoneData={setTimezoneData}
						locationName={locationName}
						setLocationName={setLocationName}
						type={'create'}
					/>
				</Modal>

			</div> : <p>You are not authorized.</p>}
		</div> : <div className="tw-max-w-[842px] tw-text-center tw-m-auto tw-pt-[40px]">
			<Skeleton active={true} />
		</div>
		)
	);
};

export default Events;
