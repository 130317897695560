import * as React from 'react';

export const FourDoticonForTabs = React.memo(({ size = 24, color = '#EC5D25', className, ...rest }) => {
    return (

        <svg
            width={size}
            height={size}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {
                ...rest
            }
        >
            <path d="M6.00008 3.33415C6.73646 3.33415 7.33341 2.73719 7.33341 2.00081C7.33341 1.26443 6.73646 0.66748 6.00008 0.66748C5.2637 0.66748 4.66675 1.26443 4.66675 2.00081C4.66675 2.73719 5.2637 3.33415 6.00008 3.33415Z" fill={color} />
            <path d="M2.00008 7.33415C2.73646 7.33415 3.33341 6.73719 3.33341 6.00081C3.33341 5.26443 2.73646 4.66748 2.00008 4.66748C1.2637 4.66748 0.666748 5.26443 0.666748 6.00081C0.666748 6.73719 1.2637 7.33415 2.00008 7.33415Z" fill={color} />
            <path d="M7.33341 10.0008C7.33341 10.7372 6.73646 11.3341 6.00008 11.3341C5.2637 11.3341 4.66675 10.7372 4.66675 10.0008C4.66675 9.26443 5.2637 8.66748 6.00008 8.66748C6.73646 8.66748 7.33341 9.26443 7.33341 10.0008Z" fill={color} />
            <path d="M10.0001 7.33415C10.7365 7.33415 11.3334 6.73719 11.3334 6.00081C11.3334 5.26443 10.7365 4.66748 10.0001 4.66748C9.2637 4.66748 8.66675 5.26443 8.66675 6.00081C8.66675 6.73719 9.2637 7.33415 10.0001 7.33415Z" fill={color} />
        </svg>


    );
})

FourDoticonForTabs.displayName = "FourDoticonForTabs";