import * as React from 'react';

export const AttendingIcon = React.memo(({ size = 24, color = '#EC5D25', className, ...rest }) => {
    return (
        <span className={className}>
        <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1ZM5.24953 7.04242L8.12028 4.16398C8.31528 3.96846 8.63186 3.96803 8.82738 4.16304C9.00118 4.33637 9.02083 4.60577 8.88609 4.80082L8.82833 4.87014L5.60403 8.10308C5.43053 8.27704 5.16082 8.29654 4.96576 8.16146L4.89645 8.10355L3.14645 6.35355C2.95118 6.15829 2.95118 5.84171 3.14645 5.64645C3.32001 5.47288 3.58944 5.4536 3.78431 5.58859L3.85355 5.64645L5.24953 7.04242L8.12028 4.16398L5.24953 7.04242Z" fill={color}/>
        </svg>
        </span>
    );
})

AttendingIcon.displayName = "AttendingIcon";