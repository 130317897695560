import PropTypes from 'prop-types';
import { CalendarOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import * as React from 'react';

const IconContainer = (props) => {
	const {
		className = '',
		size = 'default',
		element
	} = props;

	return (
		<Space>
			{element ? element : <CalendarOutlined className={className} size={size} />}
		</Space>
	);
};

IconContainer.propTypes = {
	className: PropTypes.string,
	size: PropTypes.string
};

export const Icon = React.memo(IconContainer);