import { Table, Input, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { BusIcon } from 'icons/Bus';
import { CabIcon } from 'icons/Cab';
import { TrainIcon } from 'icons/Train';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTripsForTheUser } from 'redux/features/Events/Events.slice';

const airplaneIcon = () => <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.5114 5L11.5052 5C12.0427 5 12.5662 5.17336 12.9976 5.49405C13.3318 5.74256 13.3342 6.24257 13.002 6.49388C12.5679 6.82229 12.0385 7 11.4941 7L8.5114 7C8.39995 7 8.29587 7.0557 8.23405 7.14843L5.09863 11.8516C5.03681 11.9443 4.93273 12 4.82128 12H4.12007C3.89255 12 3.7319 11.7771 3.80385 11.5613L5.32426 7L1.99093 6.65792L1.09137 8.48084C1.03522 8.59463 0.919341 8.66667 0.792455 8.66667H0.666341C0.482246 8.66667 0.333008 8.51743 0.333008 8.33333L0.333008 3.66667C0.333008 3.48257 0.482247 3.33333 0.666341 3.33333H0.793259C0.919726 3.33333 1.0353 3.4049 1.09166 3.51812L1.99093 5.32459L5.32426 5L3.80385 0.438743C3.7319 0.222899 3.89255 -9.94519e-09 4.12007 0L4.82128 3.06507e-08C4.93273 3.55224e-08 5.03681 0.0557 5.09863 0.148433L8.23405 4.85157C8.29587 4.9443 8.39995 5 8.5114 5Z" fill="#6B7280" />
</svg>

const hotel = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M0.666992 12.667V2.91699H1.66699V9.48366H7.55033V4.33366H12.917C13.5837 4.33366 14.1531 4.56977 14.6253 5.04199C15.0975 5.51421 15.3337 6.08366 15.3337 6.75033V12.667H14.3337V10.4837H1.66699V12.667H0.666992ZM4.50033 8.51699C4.00033 8.51699 3.58088 8.34755 3.24199 8.00866C2.9031 7.66977 2.73366 7.25033 2.73366 6.75033C2.73366 6.25033 2.9031 5.83088 3.24199 5.49199C3.58088 5.1531 4.00033 4.98366 4.50033 4.98366C5.00033 4.98366 5.41977 5.1531 5.75866 5.49199C6.09755 5.83088 6.26699 6.25033 6.26699 6.75033C6.26699 7.25033 6.09755 7.66977 5.75866 8.00866C5.41977 8.34755 5.00033 8.51699 4.50033 8.51699ZM8.55033 9.48366H14.3337V6.75033C14.3337 6.36144 14.1948 6.0281 13.917 5.75033C13.6392 5.47255 13.3059 5.33366 12.917 5.33366H8.55033V9.48366ZM4.50033 7.51699C4.71144 7.51699 4.89199 7.44199 5.04199 7.29199C5.19199 7.14199 5.26699 6.96144 5.26699 6.75033C5.26699 6.53921 5.19199 6.35866 5.04199 6.20866C4.89199 6.05866 4.71144 5.98366 4.50033 5.98366C4.28921 5.98366 4.10866 6.05866 3.95866 6.20866C3.80866 6.35866 3.73366 6.53921 3.73366 6.75033C3.73366 6.96144 3.80866 7.14199 3.95866 7.29199C4.10866 7.44199 4.28921 7.51699 4.50033 7.51699Z" fill="black" />
</svg>

const iconsGenerator = (type) => {
	switch (type) {
		case "flight":
			return airplaneIcon();
		case "train":
			return <TrainIcon size={20} />;
		case "bus":
			return <BusIcon size={20} />;
		case "cab":
			return <CabIcon size={20} className="tw-mt-1" />;
		case "accomodation":
			return hotel();
		default:
			return "";
	}
}




const LinkTripTable = (props) => {

	const columns = [
		{
			title: ()=>(<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Trip ID</span>),
			dataIndex: 'tripId',
			width: 120
		},
		{
			title: ()=>(<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Travel details</span>),
			dataIndex: 'travelDetails',
			render: (text, record) => (
				<span type="primary">
					{
						Array.isArray(record.icon) ?
							record.icon?.map((_icon) => {
								return iconsGenerator(_icon)
							}) : record.icon === "accomodation" ? hotel() : ""
					}
					{" "}{text}
					{/* {text} {record.icon === "flight" ? airplaneIcon() : hotel()} */}
				</span>
			),
		},
		{
			title: ()=>(<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Travel Date</span>),
			dataIndex: 'travelDate',
			width: 130
		},
		{
			title: ()=>(<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Status</span>),
			dataIndex: 'status',
		},
		{
			title: ()=>(<span className='tw-text-sm tw-font-medium tw-text-[#111827]'>Vouchers</span>),
			dataIndex: 'voucher',
			render: (text, record) => {
				// console.log(record.voucher);

				return text ? <span type="primary" className='tw-text-blue-600 tw-cursor-pointer' onClick={() => downloadVoucher(record.voucher)}>
					Download
				</span> : "-"
			}
		}
	];


	const downloadFile = (url) => {
		const link = document.createElement('a');
		link.href = "https://stage.iltech.in/mercury/app" + url;
		// link.href = process.env.REACT_APP_ITILITE_BASE_URL + url;
		link.download = true;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const downloadVoucher = (linkArr) => {

		linkArr.forEach((url) => {
			const link = document.createElement("a");
			link.href = "https://stage.iltech.in/mercury/app" + url.slice(1);
			link.download = true;
			link.target = "_blank"
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
		// window.open(process.env.REACT_APP_ITILITE_BASE_URL + downloadLink)
	}	

	const { eventId, localActiveTripsForUser,
		setLocalActiveTripsForUser, selectedRowKeys,
		setSelectedRowKeys,
		setSelectedTrips, selectedTrips } = props;
	const dispatch = useDispatch()
	const eventReducer = useSelector(state => state.events)

	const { isLoading, activeTripsForTheUser } = eventReducer;
	const [localActiveTripsForUserOG, setLocalActiveTripsForUserOG] = useState([])

	useEffect(() => {
		dispatch(getActiveTripsForTheUser(eventId))
	}, [])

	const addIcons = (travelData, acc=[]) => {
		let iconsArray = travelData.map((_data) => {

			if (_data.mode?.toLowerCase())
				return _data.mode?.toLowerCase()
			else return ""
		})

		for(let i=0; i<acc.length; i++){
			iconsArray.push('accomodation')
		}

		return iconsArray
	}

	const travelDetailsFormatter = (mode, data) => {
		// if (mode.toLowerCase() === "cab") {
		// 	return `${data?.source} - ${data.destination}`
		// }
		// else {
			return `${data?.travel_city_from} - ${data?.travel_city_to}`
		// }
	}

	function formatDate(dateString) {
		const date = dayjs(dateString);
		return date.format('DD MMM YYYY');
	}


	const travelDateFormatter = (mode, data) => {
		// if (mode.toLowerCase() === "cab") {
		// 	return `${formatDate(data.start_date_time)} - ${formatDate(data.end_date_time)}`
		// }
		// else {
			return data?.from_date && data?.to_date ?
				`${data.from_date} - ${data?.to_date}` :
				`${data.from_date}`
		// }
	}
	useEffect(() => {

		if (activeTripsForTheUser && activeTripsForTheUser.trips) {
			let tempArr = activeTripsForTheUser.trips;

			let newArr = tempArr.map((data, index) => {

				if (data.travel.length > 0) {
					return {
						key: index + 1,
						tripId: data.trip_id,
						status: data.trip_status,
						travelDetails: travelDetailsFormatter(data.travel[0]?.mode, data),
						travelDate: travelDateFormatter(data.travel[0]?.mode, data),
						travellerInfo: data.travellers_info,
						voucher: data?.voucher?.length ? data?.voucher : null,
						icon: addIcons(data.travel, data?.accommodation)
					}
				}

				else if (data.accommodation?.length) {
					return {
						key: index + 1,
						tripId: data.trip_id,
						status: data.trip_status,
						travelDetails: `${data.accomodation_location}`,
						travelDate: data.from_date && data.to_date ?
							`${data.from_date} - ${data.to_date}` :
							`${data.from_date}`,
						travellerInfo: data.travellers_info,
						voucher: data.voucher?.length ? data.voucher : null,
						icon: "accomodation"
					}
				}

				else {
					return {
						key: index + 1,
						tripId: data.trip_id,
						status: data.trip_status,
						travelDetails: `-`,
						travelDate: `-`,
						travellerInfo: data.travellers_info,
						voucher: data.voucher[0] ? data.voucher[0] : null,
					}
				}
			})

			setLocalActiveTripsForUser(newArr)
			setLocalActiveTripsForUserOG(newArr)
		}
	}, [activeTripsForTheUser])

	const onSelectChange = (newSelectedRowKeys, _selectedData) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedTrips(_selectedData)
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const hasSelected = selectedRowKeys.length > 0;

	const handleTripSearch = (e) => {
		let val = e.target.value.toLowerCase();
		let tempArr = localActiveTripsForUserOG.filter((_data) => _data.tripId.includes(val) || _data.travelDetails.toLowerCase().includes(val) || _data.travellerInfo[0].name?.toLowerCase().includes(val))
		setLocalActiveTripsForUser(tempArr)
	}

	return (
		<div className='tw-mt-6'>
			<Input
				// value=''
				placeholder='Search with trip ID, travelers or location'
				prefix={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8 2.00098C4.68629 2.00098 2 4.68727 2 8.00098C2 11.3147 4.68629 14.001 8 14.001C11.3137 14.001 14 11.3147 14 8.00098C14 4.68727 11.3137 2.00098 8 2.00098ZM0 8.00098C0 3.5827 3.58172 0.000976562 8 0.000976562C12.4183 0.000976562 16 3.5827 16 8.00098C16 9.84969 15.3729 11.5519 14.3199 12.9066L19.7071 18.2939C20.0976 18.6844 20.0976 19.3176 19.7071 19.7081C19.3166 20.0986 18.6834 20.0986 18.2929 19.7081L12.9056 14.3208C11.551 15.3739 9.84871 16.001 8 16.001C3.58172 16.001 0 12.4193 0 8.00098Z" fill="#6B7280" />
				</svg>
				}
				name="Trip search"
				className="tw-mb-5"
				onChange={handleTripSearch}
			// disabled = {false}
			/>
			{/* <Table columns={columns} dataSource={localActiveTripsForUser} /> */}


			{isLoading.activeTripsForTheUser ?
				<Skeleton
					width={100}
					paragraph={true}
					active={true}
					height={1}
					size="large"
				/> : <Table rowSelection={rowSelection} columns={columns} dataSource={localActiveTripsForUser} />
			}
		</div>
	)
};
export default LinkTripTable;

