import axios from 'axios';
import LocalStorageService from 'services/LocalStorage';
import ToastService from 'services/ToastService';
import { servicesUrl } from 'utils/urls/apiUrls';

// Create a new Axios instance
const instance = axios.create({
	baseURL: `${servicesUrl}`,
});

// Add a response interceptor
instance.interceptors.response.use(
	(response) => response,
	(error) => {
		// If the response status code is 401 (Unauthorized),
		// log the user out and redirect to the login page.
		if (error.response.status === 401) {
			// Log the user out
			if (error.response?.data?.code === 'token_not_valid') {
				ToastService.error('Please login again');
				logout();
			}
		}
		// If the error is not due to an unauthorized request,
		// throw the error to be caught by the calling code.
		throw error;
	}
);

function logout() {
	LocalStorageService.removeAllToken();
	// Redirect to the login page
	window.location.href = process.env.REACT_APP_LOGIN_URL;
}

// Export the instance for use in your application
export default instance;
