import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';

const isProduction = process.env.NODE_ENV === 'production';

let locale = localStorage.getItem('locale')
	? localStorage.getItem('locale')
	: 'en';
let lang = localStorage.getItem('language')
	? localStorage.getItem('language')
	: 'en';
let currency = localStorage.getItem('currency')
	? localStorage.getItem('currency')
	: 'en';
let date_format = localStorage.getItem('date-format')
	? localStorage.getItem('date-format')
	: 'en';

const locizeOptions = {
	projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
	apiKey: process.env.REACT_APP_LOCIZE_APIKEY, // YOU should not expose your apps API key to production!!!
	referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
	version: process.env.REACT_APP_LOCIZE_VERSION,
};

i18n.use(Locize)
	.use(initReactI18next)
	.init({
		lng: lang, // if you're using a language detector, do not define the lng option
		fallbackLng: 'en', //if i18next didn't find the translation, show the fallback language text

		interpolation: {
			escapeValue: false,
			format: (value, format, lng) => {
				if (format === 'number') {
					return new Intl.NumberFormat(locale).format(value);
				}
				if (format === 'date') {
					return new Intl.DateTimeFormat(date_format).format(value);
				}
				if (format === 'currency') {
					return new Intl.NumberFormat(lng, {
						style: 'currency',
						currency: 'USD',
					}).format(value);
				}
			},
		},
		backend: locizeOptions,
		saveMissing: !isProduction, // you should not use saveMissing in production
	});

export default i18n;
