import PropTypes from 'prop-types';
import { Button } from 'antd';
import classes from './IconButton.module.scss';
import clsx from 'clsx';
import { Tooltip } from '../Tooltip/Tooltip';
import * as React from 'react';

const IconButtonComp = (props) => {
	const {
		icon = null,
		size = 'default',
		type = 'default',
		active = false,
		disabled = false,
		onClick = () => {},
		tooltipText = '',
		tooltipPlacement = 'right',
	} = props;
	return (
		<Tooltip title={tooltipText} placement={tooltipPlacement}>
			<Button
				className={clsx(
					classes.iconButton,
					active && classes.active,
					disabled && classes.disabled
				)}
				onClick={onClick}
				type={type}
				icon={icon}
				size={size}
			/>
		</Tooltip>
	);
}

IconButtonComp.propTypes = {
	icon: PropTypes.element,
	disabled: PropTypes.bool,
	active: PropTypes.bool,
	onClick: PropTypes.func,
	tooltipText: PropTypes.string,
	tooltipPlacement: PropTypes.string,
	size: PropTypes.oneOf(['large', 'default', 'small']),
	type: PropTypes.oneOf(['primary', 'default', 'ghost', 'text']),
};

export const IconButton = React.memo(IconButtonComp)