import * as React from 'react';

export const EditIcon = React.memo(({ size = 24, color = '#EC5D25', className, ...rest }) => {
    return (

        <span className={className}>
        <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4403 0.56066C10.6927 -0.186863 9.48077 -0.18689 8.73321 0.560598L1.33829 7.95484C1.15725 8.13586 1.02085 8.35655 0.939895 8.59943L0.0256719 11.3421C-0.0342172 11.5218 0.0125441 11.7198 0.14646 11.8538C0.280376 11.9877 0.47846 12.0344 0.658127 11.9746L3.40087 11.0603C3.64368 10.9794 3.86432 10.843 4.04531 10.662L11.4402 3.26783C12.1878 2.52029 12.1878 1.30823 11.4403 0.56066ZM9.44029 1.26774C9.79731 0.910743 10.3761 0.910756 10.7331 1.26777C11.0902 1.6248 11.0902 2.20367 10.7331 2.56069L9.99937 3.29437L8.70648 2.00148L9.44029 1.26774ZM7.99934 2.70855L9.29223 4.00145L3.33823 9.9549C3.26701 10.0261 3.18019 10.0798 3.08464 10.1116L1.29058 10.7096L1.88858 8.91566C1.92044 8.82007 1.97412 8.73322 2.04536 8.66198L7.99934 2.70855Z" fill="#212121"/>
        </svg>
        </span>

    );
})

EditIcon.displayName = "EditIcon";